
/*
*
*
*
*
*/

import { show_debug } from './forge';

// ==================================================================
// kanban organizer, drag n drop
// ==================================================================

//
//
export const kanban_organizer = function(section,pipelines,loop_data){

    //
    //
    let board_column = kanban_set_columns(pipelines)

    //
    //
    let board_data = kanban_populate_column(section,board_column,loop_data)

    //
    //
    return board_data

}

//
//
export const kanban_set_columns = function(pipeline_stages){

    //
    //
    let r = []

    //
    //
    for (let i = 0; i < pipeline_stages.length; i++) {

        //
        //
        r.push({
            'id': pipeline_stages[i]['id'],
            'name': pipeline_stages[i]['name'],
            'loop': []
        })
        
    }

    //
    //
    return r

}

//
//
export const kanban_populate_column = function(section,board_columns,loop_data){

    //
    //
    let bc = board_columns
    let ld = loop_data
    let r = board_columns

    //
    //
    for (let i = 0; i < bc.length; i++) {

        //
        //
        for (let i2 = 0; i2 < ld.length; i2++) {
            
            //
            //
            if( bc[i]['id'] == ld[i2]['pipeline__id'] ){

                //
                //
                bc[i]['loop'].push(
                    kanban_card_item(section,ld[i2]) 
                )

            }

        }

    }

    //
    //
    return r

}

//
//
export const kanban_card_item = function(section,_data){

    //
    //
    let r = {}

    //
    //
    if( section == 'assignment' ){
        r = {
            'id': _data['id'],
            'title': _data['title'],
            'subtitle': _data['product__name'],
            'datetime': _data['datetime'],
        }
    }
    else if( section == 'client' ){
        r = {
            'id': _data['id'],
            'title': _data['first_name'] + ' ' + _data['last_name'],
            'subtitle': _data['country'],
            'datetime': _data['datetime'],
        }
    }
    else if( section == 'personnel' ){
        r = {
            'id': _data['id'],
            'title': _data['first_name'] + ' ' + _data['last_name'],
            'subtitle': _data['country'],
            'datetime': _data['datetime'],
        }
    }

    //
    //
    return r

}

//
//
export const kanban_column_add = function(section, board_data, col_index, prev_col_index, dragged_index){

    //
    //
    board_data[col_index]['loop'].unshift( board_data[prev_col_index]['loop'][dragged_index] )

    //
    //
    return board_data

}

//
//
export const kanban_column_remove = function(section, board_data, prev_col_index, dragged_index){
    
    //
    //
    let prev_col = board_data[prev_col_index]
    show_debug(prev_col)
    prev_col['loop'].splice(dragged_index,1)
    board_data[prev_col_index] = prev_col

    //
    //
    return board_data

}

//
// evt - $event
export const kanban_drag = function(evt, card_item, card_index, prev_col_id, prev_col_index){

    //
    //
    show_debug('--------- drag ----------')
    show_debug('item id - ' + card_item.id)
    show_debug('item index - ' + card_index) 
    show_debug('prev item id - ' + prev_col_id)
    show_debug('prev item index - ' + prev_col_index)
    show_debug('--------- end drag ----------')

    //
    //
    evt.dataTransfer.dropEffect = 'move'
    evt.dataTransfer.effectAllowed = 'move'
    evt.dataTransfer.setData('dragged_item', card_item)
    evt.dataTransfer.setData('dragged_id', card_item.id)
    evt.dataTransfer.setData('dragged_index', card_index)
    evt.dataTransfer.setData('prev_col_id', prev_col_id)
    evt.dataTransfer.setData('prev_col_index', prev_col_index)

}

//
// evt - $event
export const kanban_drop = function(evt, section, board_data, col_loop, col_id, col_index){

    //
    //
    let dragged_item        = evt.dataTransfer.getData('dragged_item')
    let dragged_id          = evt.dataTransfer.getData('dragged_id')
    let dragged_index       = evt.dataTransfer.getData('dragged_index') // returns "[Object Object]" (str)
    let prev_col_id         = evt.dataTransfer.getData('prev_col_id')
    let prev_col_index      = evt.dataTransfer.getData('prev_col_index')
    //let dragged_item        = this.get_item(dragged_id,prev_board_id)
    //let r                   = board_data

    //
    //
    show_debug('--------- drop ----------')
    show_debug('drop stage id - ' + col_id)
    show_debug('prev board index - ' + prev_col_index)
    show_debug('drop stage index - ' + col_index)
    show_debug('dragged_item - ' + dragged_item)
    show_debug('dragged_index - ' + dragged_index)
    show_debug('--------- end drop ----------')

    //
    //
    if(prev_col_index != col_index){
    
        //
        // add dragged item to new stage
        board_data = kanban_column_add(section, board_data, col_index, prev_col_index, dragged_index)
        
        //
        // remove item to old stage
        board_data = kanban_column_remove(section, board_data, prev_col_index, dragged_index)

    }

    //
    // return dragged_item new value
    return {
        'new_board_data'    : board_data,
        'item_id'           : Number(dragged_id),
        'item_index'        : Number(dragged_index),
        'new_column_id'     : Number(board_data[col_index]['id']),
        'new_column_index'  : Number(col_index),
        'prev_column_id'    : Number(prev_col_id),
        'prev_column_idex'  : Number(prev_col_index),
    }

}

// ==================================================================
// formbuilder organizer, drag n drop
// ==================================================================

//
//
export const formb_drag = function(evt,field_loop,field_data,field_index){

    //
    //
    show_debug('--------- drag ----------')
    show_debug('field_loop - ' + field_loop)
    show_debug('field_data - ' + field_data)
    show_debug('field_index - ' + field_index) 
    show_debug('--------- end drag ----------')

    //
    //
    evt.dataTransfer.dropEffect = 'move'
    evt.dataTransfer.effectAllowed = 'move'
    evt.dataTransfer.setData('dragged_loop', field_loop)
    evt.dataTransfer.setData('dragged_item', field_data)
    evt.dataTransfer.setData('dragged_index', field_index)

    //
    //
    //let dragged_el =  evt.target
    //let ghost_dragged_el =  dragged_el.cloneNode(true)

    
    // dragged_el.style.top = "150px"
    // dragged_el.style.width = '300px'
    // dragged_el.style.background_color = '#000000'

    //
    // hide translucent drag ghost image
    //evt.dataTransfer.setDragImage(new Image(), 0, 0)
    // evt.dataTransfer.setDragImage(
    //     dragged_el,
    //     dragged_el.getBoundingClientRect().x,
    //     dragged_el.getBoundingClientRect().y
    // )

    

    //
    //
    // document.addEventListener('mousemove', formb_dragmove(
    //     evt,
    //     dragged_el
    // ))

    // dragged_el.style.top = (dragged_el.clientY - cOffY).toString() + 'px';
    // dragged_el.style.left = (dragged_el.clientX - cOffX).toString() + 'px';

    // let dx = evt.clientX - dragged_el.getBoundingClientRect().x;
    // let dy = evt.clientY - dragged_el.getBoundingClientRect().y;
    // //let dw = dragged_el.offsetWidth
    // let dh = dragged_el.offsetHeight
    // dragged_el.style.position = 'absolute';

    // //
    // //
    // dragged_el.style.left = evt.ClientX - dx;
    // dragged_el.style.top = evt.ClientY - dy;
    // //dragged_el.style.width = dw
    // dragged_el.style.height = dh


}

//
//
export const formb_dragmove = function(evt,dragged_el){
    
    console.log('formb_dragmove')

    dragged_el.style.position = 'absolute';

    let cOffX = dragged_el.clientX - dragged_el.offsetLeft;
    let cOffY = dragged_el.clientY - dragged_el.offsetTop;

    dragged_el.style.top = (dragged_el.clientY - cOffY).toString() + 'px';
    dragged_el.style.left = (dragged_el.clientX - cOffX).toString() + 'px';
    dragged_el.style.width = '300px'

    let dx = evt.clientX - dragged_el.getBoundingClientRect().x;
    let dy = evt.clientY - dragged_el.getBoundingClientRect().y;

    dragged_el.style.left = evt.ClientX - dx;
    dragged_el.style.top = evt.ClientY - dy;

}

//
// only used for animation - no datatransfer
export const formb_dragover = function(evt){

    //
    //
    evt.preventDefault();

    //
    //
    let target = getLI( evt.target );
    let bounding = target.getBoundingClientRect(); // get size of an element and its position relative to the viewport
    //let offset = bounding.y + (bounding.height/2 );
    let offset = bounding.y + (bounding.height/2 );

    //
    //
    if ( evt.clientY - offset > 0 ) {

        //
        //
        target.style['border-bottom'] = 'solid 8px #cccccc';
        target.style['border-top'] = '';

    } else {
        
        //
        //
        target.style['border-top'] = 'solid 8px #cccccc';
        target.style['border-bottom'] = '';

    }

}

//
// only used for animation - no datatransfer
export const formb_dragexit = function(evt){

    //
    //
    evt.preventDefault()

    //
    //
    var target = getLI( evt.target );
    target.style['border-bottom'] = '0px';
    target.style['border-top'] = '0px';

}

//
//
export const formb_drop = function(evt,field_loop,new_field_index){

    //
    //
    evt.preventDefault()

    //
    //
    var target = getLI( evt.target );
    target.style['border-bottom'] = '0px';
    target.style['border-top'] = '0px';

    //
    //
    let dragged_index = evt.dataTransfer.getData('dragged_index')

    //
    //
    show_debug('--------- drop ----------')
    show_debug(evt)
    show_debug('field_loop - ' + field_loop)
    show_debug('old_index - ' + dragged_index)
    show_debug('new_field_index - ' + new_field_index) 
    show_debug('--------- end drop ----------')

    //
    //
    //document.removeEventListener("mousemove", formb_dragmove)

    //
    //
    return {
        'old': dragged_index,
        'new': new_field_index,
    }
}

//
//
export const formb_sort = function(arr, old_index, new_index) {

    //
    // how to use
    // console.log(array_move([1, 2, 3], 0, 1));  
    // moves 1 to second position
    //

    //
    //
    if (new_index >= arr.length) {

        //
        //
        var k = new_index - arr.length + 1;
        
        ///
        //
        while (k--) {
            arr.push(undefined);
        }

    }

    //
    //
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);

    //
    //
    return arr; // for testing
};

//
//
export const getLI = function( target ) {

    //
    //
    while ( target.nodeName.toLowerCase() != 'li' && target.nodeName.toLowerCase() != 'body' ) {

        //
        //
        target = target.parentNode;
    }

    //
    //
    if ( target.nodeName.toLowerCase() == 'body' ) {

        //
        //
        return false;

    } else {

        //
        //
        return target;
    }
}

// ==================================================================
// workflow builder pan, move, zoom, add objects, connect objects
// ==================================================================

//
//
export const init_workflow = function(){

}

//
//
export const move_canvas = function(){

}

//
//
export const zoomin_canvas = function(){

}

//
//
export const zoomout_canvas = function(){

}

//
//
export const create_module = function(){

}

//
//
export const delete_module = function(){

}

//
//
export const move_module = function(){
    
}

//
//
export const detect_hover = function(){

}

//
//
export const detect_click = function(){

}

//
//
export const draw_line = function(){

}










