import { createApp } from 'vue'
import app from './app.vue'
import router from './router'
import store from './store'

//
//import  VueGoogleMaps from '@fawmi/vue-google-maps'

//window.$ = require( "jquery" );

//createApp(app).use(store).use(router).use(VueGoogleMaps, {load: {key: 'AIzaSyBcPV-Dn2h1MAkeryWfPcCMqe1poeVi_kA',},}).mount('#app')



createApp(app).use(store).use(router).mount('#app')

