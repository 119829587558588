<template>
    <div class="page-wrapper null compact-wrapper" id="id_app">
        
        <router-view />

    </div>
</template>
<script>

module.exports = {
    data(){
        return {

        }
    },
    created(){
        this.check_darkmode()
    },
    methods:{

        //
        //
        check_darkmode(){

            //
            //
            if(this.$store.state.get_darkmode() == 1){
                //document.body.classList.add("dark-only")
                this.$store.state.set_darkmode()
            }
            else {
                //document.body.classList.remove("dark-only")
                this.$store.state.set_lightmode()
            }

        },

    },
    components:{

    }
}

</script>
<style>
</style>