

export const COUNTRY_SELECTION = [
    'Afghanistan',
    'Albania',
    'Algeria',
    'Andorra',
    'Angola',
    'Antigua and Barbuda',
    'Argentina',
    'Armenia',
    'Australia',
    'Austria',
    'Azerbaijan',
    'Bahamas',
    'Bahrain',
    'Bangladesh',
    'Barbados',
    'Belarus',
    'Belgium',
    'Belize',
    'Benin',
    'Bhutan',
    'Bolivia',
    'Bosnia and Herzegovina',
    'Botswana',
    'Brazil',
    'Brunei',
    'Bulgaria',
    'Burkina Faso',
    'Burundi',
    "Côte d'Ivoire",
    'Cabo Verde',
    'Cambodia',
    'Cameroon',
    'Canada',
    'Central African Republic',
    'Chad',
    'Chile',
    'China',
    'Colombia',
    'Comoros',
    'Congo (Congo-Brazzaville)',
    'Costa Rica',
    'Croatia',
    'Cuba',
    'Cyprus',
    'Czechia (Czech Republic)',
    'Democratic Republic of the Congo',
    'Denmark',
    'Djibouti',
    'Dominica',
    'Dominican Republic',
    'Ecuador',
    'Egypt',
    'El Salvador',
    'Equatorial Guinea',
    'Eritrea',
    'Estonia',
    'Estawini (fmr. Swaziland)',
    'Ethiopia',
    'Fiji',
    'Finland',
    'France',
    'Gabon',
    'Gambia',
    'Georgia',
    'Germany',
    'Ghana',
    'Greece',
    'Grenada',
    'Guatemala',
    'Guinea',
    'Guinea-Bissau',
    'Guyana',
    'Haiti',
    'Holy See',
    'Honduras',
    'Hungary',
    'Iceland',
    'India',
    'Indonesia',
    'Iran',
    'Iraq',
    'Ireland',
    'Israel',
    'Italy',
    'Jamaica',
    'Japan',
    'Jordan',
    'Kazakhstan',
    'Kenya',
    'Kiribati',
    'Kuwait',
    'Kyrgyzstan',
    'Laos',
    'Latvia',
    'Lebanon',
    'Lesotho',
    'Liberia',
    'Libya',
    'Liechtenstein',
    'Lithuania',
    'Luxembourg',
    'Madagascar',
    'Malawi',
    'Malaysia',
    'Maldives',
    'Mali',
    'Malta',
    'Marshall Islands',
    'Mauritania',
    'Mauritius',
    'Mexico',
    'Micronesia',
    'Moldova',
    'Monaco',
    'Mongolia',
    'Montenegro',
    'Morocco',
    'Mozambique',
    'Myanmar (formerly Burma)',
    'Namibia',
    'Nauru',
    'Nepal',
    'Netherlands',
    'New Zealand',
    'Nicaragua',
    'Niger',
    'North Korea',
    'North Macedonia',
    'Norway',
    'Oman',
    'Pakistan',
    'Palau',
    'Palestine State',
    'Panama',
    'Papua New Guinea',
    'Paraguay',
    'Peru',
    'Philippines',
    'Poland',
    'Portugal',
    'Qatar',
    'Romania',
    'Russia',
    'Rwanda',
    'Saint Kitts and Nevis',
    'Saint Lucia',
    'Saint Vincent and the Grenadines',
    'Samoa',
    'San Marino',
    'Sao Tome and Principe',
    'Saudi Arabia',
    'Senegal',
    'Serbia',
    'Seychelles',
    'Sierra Leone',
    'Singapore',
    'Slovakia',
    'Slovenia',
    'Solomon Islands',
    'Somalia',
    'South Africa',
    'South Korea',
    'South Sudan',
    'Spain',
    'Sri Lanka',
    'Sudan',
    'Suriname',
    'Sweden',
    'Switzerland',
    'Syria',
    'Tajikistan',
    'Tanzania',
    'Thailand',
    'Timor-Leste',
    'Togo',
    'Tonga',
    'Trinidad and Tobago',
    'Tunisia',
    'Turkey',
    'Turkmenistan',
    'Tuvalu',
    'Uganda',
    'Ukraine',
    'United Arab Emirates',
    'United Kingdom',
    'United States of America',
    'Uruguay',
    'Uzbekistan',
    'Vanuatu',
    'Venezuela',
    'Vietnam',
    'Yemen',
    'Zambia',
    'Zimbabwe',
]

export const CURRENCY_SELECTION = [
    {
        "name": "US Dollar",
        "symbol": "$",
        "symbolNative": "$",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "USD",
        "namePlural": "US dollars"
    },
    {
        "name": "Canadian Dollar",
        "symbol": "C$",
        "symbolNative": "$",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "CAD",
        "namePlural": "Canadian dollars"
    },
    {
        "name": "Euro",
        "symbol": "€",
        "symbolNative": "€",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "EUR",
        "namePlural": "euros"
    },
    {
        "name": "United Arab Emirates Dirham",
        "symbol": "د.إ.‏",
        "symbolNative": "د.إ.‏",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "AED",
        "namePlural": "UAE dirhams"
    },
    {
        "name": "Afghan Afghani",
        "symbol": "؋",
        "symbolNative": "؋",
        "decimalDigits": 0,
        "rounding": 0,
        "code": "AFN",
        "namePlural": "Afghan Afghanis"
    },
    {
        "name": "Albanian Lek",
        "symbol": "Lek",
        "symbolNative": "Lek",
        "decimalDigits": 0,
        "rounding": 0,
        "code": "ALL",
        "namePlural": "Albanian lekë"
    },
    {
        "name": "Armenian Dram",
        "symbol": "֏",
        "symbolNative": "֏",
        "decimalDigits": 0,
        "rounding": 0,
        "code": "AMD",
        "namePlural": "Armenian drams"
    },
    {
        "name": "Argentine Peso",
        "symbol": "AR$",
        "symbolNative": "$",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "ARS",
        "namePlural": "Argentine pesos"
    },
    {
        "name": "Australian Dollar",
        "symbol": "AU$",
        "symbolNative": "$",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "AUD",
        "namePlural": "Australian dollars"
    },
    {
        "name": "Azerbaijani Manat",
        "symbol": "₼",
        "symbolNative": "₼",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "AZN",
        "namePlural": "Azerbaijani manats"
    },
    {
        "name": "Bosnia-Herzegovina Convertible Mark",
        "symbol": "KM",
        "symbolNative": "KM",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "BAM",
        "namePlural": "Bosnia-Herzegovina convertible marks"
    },
    {
        "name": "Bangladeshi Taka",
        "symbol": "Tk",
        "symbolNative": "৳",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "BDT",
        "namePlural": "Bangladeshi takas"
    },
    {
        "name": "Bulgarian Lev",
        "symbol": "лв",
        "symbolNative": "лв",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "BGN",
        "namePlural": "Bulgarian leva"
    },
    {
        "name": "Bahraini Dinar",
        "symbol": "BD",
        "symbolNative": "د.ب.‏",
        "decimalDigits": 3,
        "rounding": 0,
        "code": "BHD",
        "namePlural": "Bahraini dinars"
    },
    {
        "name": "Burundian Franc",
        "symbol": "FBu",
        "symbolNative": "FBu",
        "decimalDigits": 0,
        "rounding": 0,
        "code": "BIF",
        "namePlural": "Burundian francs"
    },
    {
        "name": "Brunei Dollar",
        "symbol": "B$",
        "symbolNative": "$",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "BND",
        "namePlural": "Brunei dollars"
    },
    {
        "name": "Bolivian Boliviano",
        "symbol": "Bs",
        "symbolNative": "$b",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "BOB",
        "namePlural": "Bolivian bolivianos"
    },
    {
        "name": "Brazilian Real",
        "symbol": "R$",
        "symbolNative": "R$",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "BRL",
        "namePlural": "Brazilian reals"
    },
    {
        "name": "Botswanan Pula",
        "symbol": "BWP",
        "symbolNative": "P",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "BWP",
        "namePlural": "Botswanan pulas"
    },
    {
        "name": "Belarusian Ruble",
        "symbol": "Br",
        "symbolNative": "руб.",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "BYN",
        "namePlural": "Belarusian rubles"
    },
    {
        "name": "Belize Dollar",
        "symbol": "BZ$",
        "symbolNative": "$",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "BZD",
        "namePlural": "Belize dollars"
    },
    {
        "name": "Congolese Franc",
        "symbol": "CDF",
        "symbolNative": "FrCD",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "CDF",
        "namePlural": "Congolese francs"
    },
    {
        "name": "Swiss Franc",
        "symbol": "CHF",
        "symbolNative": "CHF",
        "decimalDigits": 2,
        "rounding": 0.05,
        "code": "CHF",
        "namePlural": "Swiss francs"
    },
    {
        "name": "Chilean Peso",
        "symbol": "CL$",
        "symbolNative": "$",
        "decimalDigits": 0,
        "rounding": 0,
        "code": "CLP",
        "namePlural": "Chilean pesos"
    },
    {
        "name": "Chinese Yuan",
        "symbol": "CN¥",
        "symbolNative": "CN¥",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "CNY",
        "namePlural": "Chinese yuan"
    },
    {
        "name": "Colombian Peso",
        "symbol": "CO$",
        "symbolNative": "$",
        "decimalDigits": 0,
        "rounding": 0,
        "code": "COP",
        "namePlural": "Colombian pesos"
    },
    {
        "name": "Costa Rican Colón",
        "symbol": "₡",
        "symbolNative": "₡",
        "decimalDigits": 0,
        "rounding": 0,
        "code": "CRC",
        "namePlural": "Costa Rican colóns"
    },
    {
        "name": "Cape Verdean Escudo",
        "symbol": "CV$",
        "symbolNative": "CV$",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "CVE",
        "namePlural": "Cape Verdean escudos"
    },
    {
        "name": "Czech Republic Koruna",
        "symbol": "Kč",
        "symbolNative": "Kč",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "CZK",
        "namePlural": "Czech Republic korunas"
    },
    {
        "name": "Djiboutian Franc",
        "symbol": "Fdj",
        "symbolNative": "Fdj",
        "decimalDigits": 0,
        "rounding": 0,
        "code": "DJF",
        "namePlural": "Djiboutian francs"
    },
    {
        "name": "Danish Krone",
        "symbol": "Dkr",
        "symbolNative": "kr",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "DKK",
        "namePlural": "Danish kroner"
    },
    {
        "name": "Dominican Peso",
        "symbol": "RD$",
        "symbolNative": "RD$",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "DOP",
        "namePlural": "Dominican pesos"
    },
    {
        "name": "Algerian Dinar",
        "symbol": "DA",
        "symbolNative": "د.ج.‏",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "DZD",
        "namePlural": "Algerian dinars"
    },
    {
        "name": "Estonian Kroon",
        "symbol": "Ekr",
        "symbolNative": "kr",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "EEK",
        "namePlural": "Estonian kroons"
    },
    {
        "name": "Egyptian Pound",
        "symbol": "EGP",
        "symbolNative": "ج.م.‏",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "EGP",
        "namePlural": "Egyptian pounds"
    },
    {
        "name": "Eritrean Nakfa",
        "symbol": "Nfk",
        "symbolNative": "Nfk",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "ERN",
        "namePlural": "Eritrean nakfas"
    },
    {
        "name": "Ethiopian Birr",
        "symbol": "Br",
        "symbolNative": "Br",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "ETB",
        "namePlural": "Ethiopian birrs"
    },
    {
        "name": "British Pound Sterling",
        "symbol": "£",
        "symbolNative": "£",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "GBP",
        "namePlural": "British pounds sterling"
    },
    {
        "name": "Georgian Lari",
        "symbol": "GEL",
        "symbolNative": "GEL",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "GEL",
        "namePlural": "Georgian laris"
    },
    {
        "name": "Ghanaian Cedi",
        "symbol": "GH₵",
        "symbolNative": "GH₵",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "GHS",
        "namePlural": "Ghanaian cedis"
    },
    {
        "name": "Guinean Franc",
        "symbol": "FG",
        "symbolNative": "FG",
        "decimalDigits": 0,
        "rounding": 0,
        "code": "GNF",
        "namePlural": "Guinean francs"
    },
    {
        "name": "Guatemalan Quetzal",
        "symbol": "GTQ",
        "symbolNative": "Q",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "GTQ",
        "namePlural": "Guatemalan quetzals"
    },
    {
        "name": "Hong Kong Dollar",
        "symbol": "HK$",
        "symbolNative": "$",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "HKD",
        "namePlural": "Hong Kong dollars"
    },
    {
        "name": "Honduran Lempira",
        "symbol": "HNL",
        "symbolNative": "L",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "HNL",
        "namePlural": "Honduran lempiras"
    },
    {
        "name": "Croatian Kuna",
        "symbol": "kn",
        "symbolNative": "kn",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "HRK",
        "namePlural": "Croatian kunas"
    },
    {
        "name": "Hungarian Forint",
        "symbol": "Ft",
        "symbolNative": "Ft",
        "decimalDigits": 0,
        "rounding": 0,
        "code": "HUF",
        "namePlural": "Hungarian forints"
    },
    {
        "name": "Indonesian Rupiah",
        "symbol": "Rp",
        "symbolNative": "Rp",
        "decimalDigits": 0,
        "rounding": 0,
        "code": "IDR",
        "namePlural": "Indonesian rupiahs"
    },
    {
        "name": "Israeli New Sheqel",
        "symbol": "₪",
        "symbolNative": "₪",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "ILS",
        "namePlural": "Israeli new sheqels"
    },
    {
        "name": "Indian Rupee",
        "symbol": "Rs",
        "symbolNative": "টকা",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "INR",
        "namePlural": "Indian rupees"
    },
    {
        "name": "Iraqi Dinar",
        "symbol": "IQD",
        "symbolNative": "د.ع.‏",
        "decimalDigits": 0,
        "rounding": 0,
        "code": "IQD",
        "namePlural": "Iraqi dinars"
    },
    {
        "name": "Iranian Rial",
        "symbol": "IRR",
        "symbolNative": "﷼",
        "decimalDigits": 0,
        "rounding": 0,
        "code": "IRR",
        "namePlural": "Iranian rials"
    },
    {
        "name": "Icelandic Króna",
        "symbol": "Ikr",
        "symbolNative": "kr",
        "decimalDigits": 0,
        "rounding": 0,
        "code": "ISK",
        "namePlural": "Icelandic krónur"
    },
    {
        "name": "Jamaican Dollar",
        "symbol": "J$",
        "symbolNative": "$",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "JMD",
        "namePlural": "Jamaican dollars"
    },
    {
        "name": "Jordanian Dinar",
        "symbol": "JD",
        "symbolNative": "د.أ.‏",
        "decimalDigits": 3,
        "rounding": 0,
        "code": "JOD",
        "namePlural": "Jordanian dinars"
    },
    {
        "name": "Japanese Yen",
        "symbol": "¥",
        "symbolNative": "￥",
        "decimalDigits": 0,
        "rounding": 0,
        "code": "JPY",
        "namePlural": "Japanese yen"
    },
    {
        "name": "Kenyan Shilling",
        "symbol": "Ksh",
        "symbolNative": "Ksh",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "KES",
        "namePlural": "Kenyan shillings"
    },
    {
        "name": "Cambodian Riel",
        "symbol": "KHR",
        "symbolNative": "៛",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "KHR",
        "namePlural": "Cambodian riels"
    },
    {
        "name": "Comorian Franc",
        "symbol": "CF",
        "symbolNative": "FC",
        "decimalDigits": 0,
        "rounding": 0,
        "code": "KMF",
        "namePlural": "Comorian francs"
    },
    {
        "name": "South Korean Won",
        "symbol": "₩",
        "symbolNative": "₩",
        "decimalDigits": 0,
        "rounding": 0,
        "code": "KRW",
        "namePlural": "South Korean won"
    },
    {
        "name": "Kuwaiti Dinar",
        "symbol": "KD",
        "symbolNative": "د.ك.‏",
        "decimalDigits": 3,
        "rounding": 0,
        "code": "KWD",
        "namePlural": "Kuwaiti dinars"
    },
    {
        "name": "Kazakhstani Tenge",
        "symbol": "KZT",
        "symbolNative": "тңг.",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "KZT",
        "namePlural": "Kazakhstani tenges"
    },
    {
        "name": "Lebanese Pound",
        "symbol": "LB£",
        "symbolNative": "ل.ل.‏",
        "decimalDigits": 0,
        "rounding": 0,
        "code": "LBP",
        "namePlural": "Lebanese pounds"
    },
    {
        "name": "Sri Lankan Rupee",
        "symbol": "SLRs",
        "symbolNative": "SL Re",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "LKR",
        "namePlural": "Sri Lankan rupees"
    },
    {
        "name": "Lithuanian Litas",
        "symbol": "Lt",
        "symbolNative": "Lt",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "LTL",
        "namePlural": "Lithuanian litai"
    },
    {
        "name": "Latvian Lats",
        "symbol": "Ls",
        "symbolNative": "Ls",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "LVL",
        "namePlural": "Latvian lati"
    },
    {
        "name": "Libyan Dinar",
        "symbol": "LD",
        "symbolNative": "د.ل.‏",
        "decimalDigits": 3,
        "rounding": 0,
        "code": "LYD",
        "namePlural": "Libyan dinars"
    },
    {
        "name": "Moroccan Dirham",
        "symbol": "MAD",
        "symbolNative": "د.م.‏",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "MAD",
        "namePlural": "Moroccan dirhams"
    },
    {
        "name": "Moldovan Leu",
        "symbol": "MDL",
        "symbolNative": "MDL",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "MDL",
        "namePlural": "Moldovan lei"
    },
    {
        "name": "Malagasy Ariary",
        "symbol": "MGA",
        "symbolNative": "MGA",
        "decimalDigits": 0,
        "rounding": 0,
        "code": "MGA",
        "namePlural": "Malagasy Ariaries"
    },
    {
        "name": "Macedonian Denar",
        "symbol": "MKD",
        "symbolNative": "MKD",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "MKD",
        "namePlural": "Macedonian denari"
    },
    {
        "name": "Myanma Kyat",
        "symbol": "MMK",
        "symbolNative": "K",
        "decimalDigits": 0,
        "rounding": 0,
        "code": "MMK",
        "namePlural": "Myanma kyats"
    },
    {
        "name": "Macanese Pataca",
        "symbol": "MOP$",
        "symbolNative": "MOP$",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "MOP",
        "namePlural": "Macanese patacas"
    },
    {
        "name": "Mauritian Rupee",
        "symbol": "MURs",
        "symbolNative": "MURs",
        "decimalDigits": 0,
        "rounding": 0,
        "code": "MUR",
        "namePlural": "Mauritian rupees"
    },
    {
        "name": "Mexican Peso",
        "symbol": "MX$",
        "symbolNative": "$",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "MXN",
        "namePlural": "Mexican pesos"
    },
    {
        "name": "Malaysian Ringgit",
        "symbol": "RM",
        "symbolNative": "RM",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "MYR",
        "namePlural": "Malaysian ringgits"
    },
    {
        "name": "Mozambican Metical",
        "symbol": "MTn",
        "symbolNative": "MTn",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "MZN",
        "namePlural": "Mozambican meticals"
    },
    {
        "name": "Namibian Dollar",
        "symbol": "N$",
        "symbolNative": "N$",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "NAD",
        "namePlural": "Namibian dollars"
    },
    {
        "name": "Nigerian Naira",
        "symbol": "₦",
        "symbolNative": "₦",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "NGN",
        "namePlural": "Nigerian nairas"
    },
    {
        "name": "Nicaraguan Córdoba",
        "symbol": "C$",
        "symbolNative": "C$",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "NIO",
        "namePlural": "Nicaraguan córdobas"
    },
    {
        "name": "Norwegian Krone",
        "symbol": "Nkr",
        "symbolNative": "kr",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "NOK",
        "namePlural": "Norwegian kroner"
    },
    {
        "name": "Nepalese Rupee",
        "symbol": "NPRs",
        "symbolNative": "नेरू",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "NPR",
        "namePlural": "Nepalese rupees"
    },
    {
        "name": "New Zealand Dollar",
        "symbol": "NZ$",
        "symbolNative": "$",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "NZD",
        "namePlural": "New Zealand dollars"
    },
    {
        "name": "Omani Rial",
        "symbol": "OMR",
        "symbolNative": "ر.ع.‏",
        "decimalDigits": 3,
        "rounding": 0,
        "code": "OMR",
        "namePlural": "Omani rials"
    },
    {
        "name": "Panamanian Balboa",
        "symbol": "B/.",
        "symbolNative": "B/.",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "PAB",
        "namePlural": "Panamanian balboas"
    },
    {
        "name": "Peruvian Nuevo Sol",
        "symbol": "S/.",
        "symbolNative": "S/.",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "PEN",
        "namePlural": "Peruvian nuevos soles"
    },
    {
        "name": "Philippine Peso",
        "symbol": "₱",
        "symbolNative": "₱",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "PHP",
        "namePlural": "Philippine pesos"
    },
    {
        "name": "Pakistani Rupee",
        "symbol": "PKRs",
        "symbolNative": "₨",
        "decimalDigits": 0,
        "rounding": 0,
        "code": "PKR",
        "namePlural": "Pakistani rupees"
    },
    {
        "name": "Polish Zloty",
        "symbol": "zł",
        "symbolNative": "zł",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "PLN",
        "namePlural": "Polish zlotys"
    },
    {
        "name": "Paraguayan Guarani",
        "symbol": "₲",
        "symbolNative": "₲",
        "decimalDigits": 0,
        "rounding": 0,
        "code": "PYG",
        "namePlural": "Paraguayan guaranis"
    },
    {
        "name": "Qatari Rial",
        "symbol": "QR",
        "symbolNative": "ر.ق.‏",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "QAR",
        "namePlural": "Qatari rials"
    },
    {
        "name": "Romanian Leu",
        "symbol": "RON",
        "symbolNative": "RON",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "RON",
        "namePlural": "Romanian lei"
    },
    {
        "name": "Serbian Dinar",
        "symbol": "din.",
        "symbolNative": "дин.",
        "decimalDigits": 0,
        "rounding": 0,
        "code": "RSD",
        "namePlural": "Serbian dinars"
    },
    {
        "name": "Russian Ruble",
        "symbol": "RUB",
        "symbolNative": "₽.",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "RUB",
        "namePlural": "Russian rubles"
    },
    {
        "name": "Rwandan Franc",
        "symbol": "RWF",
        "symbolNative": "FR",
        "decimalDigits": 0,
        "rounding": 0,
        "code": "RWF",
        "namePlural": "Rwandan francs"
    },
    {
        "name": "Saudi Riyal",
        "symbol": "SR",
        "symbolNative": "ر.س.‏",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "SAR",
        "namePlural": "Saudi riyals"
    },
    {
        "name": "Sudanese Pound",
        "symbol": "SDG",
        "symbolNative": "SDG",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "SDG",
        "namePlural": "Sudanese pounds"
    },
    {
        "name": "Swedish Krona",
        "symbol": "Skr",
        "symbolNative": "kr",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "SEK",
        "namePlural": "Swedish kronor"
    },
    {
        "name": "Singapore Dollar",
        "symbol": "S$",
        "symbolNative": "$",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "SGD",
        "namePlural": "Singapore dollars"
    },
    {
        "name": "Somali Shilling",
        "symbol": "Ssh",
        "symbolNative": "Ssh",
        "decimalDigits": 0,
        "rounding": 0,
        "code": "SOS",
        "namePlural": "Somali shillings"
    },
    {
        "name": "Syrian Pound",
        "symbol": "SY£",
        "symbolNative": "ل.س.‏",
        "decimalDigits": 0,
        "rounding": 0,
        "code": "SYP",
        "namePlural": "Syrian pounds"
    },
    {
        "name": "Thai Baht",
        "symbol": "฿",
        "symbolNative": "฿",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "THB",
        "namePlural": "Thai baht"
    },
    {
        "name": "Tunisian Dinar",
        "symbol": "DT",
        "symbolNative": "د.ت.‏",
        "decimalDigits": 3,
        "rounding": 0,
        "code": "TND",
        "namePlural": "Tunisian dinars"
    },
    {
        "name": "Tongan Paʻanga",
        "symbol": "T$",
        "symbolNative": "T$",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "TOP",
        "namePlural": "Tongan paʻanga"
    },
    {
        "name": "Turkish Lira",
        "symbol": "TL",
        "symbolNative": "TL",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "TRY",
        "namePlural": "Turkish Lira"
    },
    {
        "name": "Trinidad and Tobago Dollar",
        "symbol": "TT$",
        "symbolNative": "$",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "TTD",
        "namePlural": "Trinidad and Tobago dollars"
    },
    {
        "name": "New Taiwan Dollar",
        "symbol": "NT$",
        "symbolNative": "NT$",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "TWD",
        "namePlural": "New Taiwan dollars"
    },
    {
        "name": "Tanzanian Shilling",
        "symbol": "TSh",
        "symbolNative": "TSh",
        "decimalDigits": 0,
        "rounding": 0,
        "code": "TZS",
        "namePlural": "Tanzanian shillings"
    },
    {
        "name": "Ukrainian Hryvnia",
        "symbol": "₴",
        "symbolNative": "₴",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "UAH",
        "namePlural": "Ukrainian hryvnias"
    },
    {
        "name": "Ugandan Shilling",
        "symbol": "USh",
        "symbolNative": "USh",
        "decimalDigits": 0,
        "rounding": 0,
        "code": "UGX",
        "namePlural": "Ugandan shillings"
    },
    {
        "name": "Uruguayan Peso",
        "symbol": "$U",
        "symbolNative": "$",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "UYU",
        "namePlural": "Uruguayan pesos"
    },
    {
        "name": "Uzbekistan Som",
        "symbol": "UZS",
        "symbolNative": "UZS",
        "decimalDigits": 0,
        "rounding": 0,
        "code": "UZS",
        "namePlural": "Uzbekistan som"
    },
    {
        "name": "Venezuelan Bolívar",
        "symbol": "Bs.F.",
        "symbolNative": "Bs.F.",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "VEF",
        "namePlural": "Venezuelan bolívars"
    },
    {
        "name": "Vietnamese Dong",
        "symbol": "₫",
        "symbolNative": "₫",
        "decimalDigits": 0,
        "rounding": 0,
        "code": "VND",
        "namePlural": "Vietnamese dong"
    },
    {
        "name": "CFA Franc BEAC",
        "symbol": "FCFA",
        "symbolNative": "FCFA",
        "decimalDigits": 0,
        "rounding": 0,
        "code": "XAF",
        "namePlural": "CFA francs BEAC"
    },
    {
        "name": "CFA Franc BCEAO",
        "symbol": "CFA",
        "symbolNative": "CFA",
        "decimalDigits": 0,
        "rounding": 0,
        "code": "XOF",
        "namePlural": "CFA francs BCEAO"
    },
    {
        "name": "Yemeni Rial",
        "symbol": "YR",
        "symbolNative": "ر.ي.‏",
        "decimalDigits": 0,
        "rounding": 0,
        "code": "YER",
        "namePlural": "Yemeni rials"
    },
    {
        "name": "South African Rand",
        "symbol": "R",
        "symbolNative": "R",
        "decimalDigits": 2,
        "rounding": 0,
        "code": "ZAR",
        "namePlural": "South African rand"
    },
    {
        "name": "Zambian Kwacha",
        "symbol": "ZK",
        "symbolNative": "ZK",
        "decimalDigits": 0,
        "rounding": 0,
        "code": "ZMK",
        "namePlural": "Zambian kwachas"
    },
    {
        "name": "Zimbabwean Dollar",
        "symbol": "ZWL$",
        "symbolNative": "ZWL$",
        "decimalDigits": 0,
        "rounding": 0,
        "code": "ZWL",
        "namePlural": "Zimbabwean Dollar"
    }
]

export const INDUSTRY_SELECTION = [
    {
        'sector' : 'Construction',
        'industry': [
            'Apartment & Condominium Construction',
            'Bridge & Elevated Highway Construction',
            'Building Finishing Contractors',
            'Carpenters',
            'Commercial Building Construction',
            'Concrete Contractors',
            'Construction',
            'Demolition & Wrecking',
            'Drywall & Insulation Installers',
            'Electricians',
            'Elevator Installation & Service',
            'Excavation Contractors',
            'Fence Construction',
            'Flooring Installers',
            'Glass & Glazing Contractors',
            'Heating & Air-Conditioning Contractors',
            'Heavy Engineering Construction',
            'Home Builders',
            'Housing Developers',
            'Industrial Building Construction',
            'Land Development',
            'Masonry',
            'Municipal Building Construction',
            'Oil & Gas Pipeline Construction',
            'Painters',
            'Paving Contractors',
            'Plumbers',
            'Remodeling',
            'Road & Highway Construction',
            'Roofing Contractors',
            'Steel Framing',
            'Sustainable Building Material Manufacturing',
            'Swimming Pool Construction',
            'Tile Installers',
            'Transmission Line Construction',
            'Water & Sewer Line Construction',
            'Wood Framing',
        ],
    },
    {
        'sector': 'Mining',
        'industry': [
            'Coal Mining',
            'Copper, Nickel, Lead & Zinc Mining',
            'Gold & Silver Ore Mining',
            'Iron Ore Mining',
            'Mineral & Phosphate Mining',
            'Mining',
            'Molybdenum & Metal Ore Mining',
            'Oil & Gas Field Services',
            'Oil Drilling & Gas Extraction',
            'Sand & Gravel Mining',
            'Stone Mining',
        ],
    },
    {
        'sector': 'Real Estate and Rental and Leasing',
        'industry': [
            'Apartment Rental',
            'Car Rental',
            'Commercial Leasing',
            'Commercial Real Estate',
            'Computer & Printer Leasing',
            'Consumer Electronics & Appliances Rental',
            'DVD, Game & Video Rental',
            'Formal Wear & Costume Rental',
            'Heavy Equipment Rental',
            'Industrial Equipment Rental & Leasing',
            'Intellectual Property Licensing',
            'Land Leasing',
            'Property Management',
            'Real Estate and Rental and Leasing',
            'Real Estate Appraisal',
            'Real Estate Asset Management & Consulting',
            'Real Estate Sales & Brokerage',
            'Storage & Warehouse Leasing',
            'Tool & Equipment Rental',
            'Truck Rental',
        ],
    },
    {
        'sector': 'Educational Services',
        'industry':[
            'Alternative Learning System',
            'Business Certification & IT Schools',
            'Business Coaching',
            'Colleges & Universities',
            'Community Colleges',
            'Educational Services',
            'Fine Arts Schools',
            'For-Profit Universities',
            'Language Instruction',
            'Non-Profit Schools',
            'Private Schools',
            'Public Schools',
            'Sports Coaching',
            'Testing & Educational Support',
            'Trade & Technical Schools',
            'Tutoring & Driving Schools',
            'Vocational Schools',

        ],
    },
    {
        'sector': 'Utilities',
        'industry':[
            'Coal & Natural Gas Power',
            'Electric Power Transmission',
            'Hydroelectric Power',
            'Natural Gas Distribution',
            'Nuclear Power',
            'Sewage Treatment Facilities',
            'Solar Power',
            'Steam & Air-Conditioning Supply',
            'Utilities',
            'Water Supply & Irrigation Systems',
            'Wind Power',

        ],
    },
    {
        'sector':'Arts, Entertainment and Recreation',
        'industry':[
            'Amusement Parks',
            'Arcade, Food & Entertainment Complexes',
            'Arts, Entertainment and Recreation',
            'Bowling Centers',
            'Celebrity & Sports Agents',
            'Concert & Event Promotion',
            'Golf Courses & Country Clubs',
            'Golf Driving Ranges & Family Fun Centers',
            'Gym, Health & Fitness Clubs',
            'Historic Sites',
            'Lotteries & Native American Casinos',
            'Marinas',
            'Museums',
            'Musical Groups & Artists',
            'National Parks & Other Nature Institutions',
            'Non-Hotel Casinos',
            'Performers & Creative Artists',
            'Racing & Individual Sports',
            'Ski & Snowboard Resorts',
            'Sports Franchises',
        ],
    },
    {
        'sector': 'Accommodation and Food Services',
        'industry':[
            'Accommodation and Food Services',
            'Bars & Nightclubs',
            'Bed & Breakfast & Hostel Accommodations',
            'Campgrounds & RV Parks',
            'Casino Hotels',
            'Caterers',
            'Chain Restaurants',
            'Coffee & Snack Shops',
            'Extended Stay Hotels',
            'Fast Food Restaurants',
            'Food Service Contractors',
            'Hotels & Motels',
            'Single Location Full-Service Restaurants',
            'Street Vendors',
            'Tourism',

        ],
    },
    {
        'sector': 'Agriculture, Forestry, Fishing and Hunting',
        'industry':[
            'Agribusines',
            'Agriculture, Forestry, Fishing and Hunting',
            'Beef Cattle Production',
            'Beekeeping',
            'Chicken & Turkey Meat Production',
            'Chicken Egg Production',
            'Corn Farming',
            'Cotton Farming',
            'Crop Services',
            'Dairy Farms',
            'Fish & Seafood Aquaculture',
            'Fishing',
            'Forest Support Services',
            'Fruit & Nut Farming',
            'Hay & Crop Farming',
            'Hog & Pig Farming',
            'Horse & Other Equine Production',
            'Hunting & Trapping',
            'Livestock Production Support Services',
            'Logging, Managed Forestry',
            'Oilseed Farming',
            'Orange & Citrus Groves',
            'Plant & Flower Growing',
            'Sheep Farming',
            'Soybean Farming',
            'Sugarcane Harvesting',
            'Timber Services',
            'Tobacco Growing',
            'Vegetable Farming',
            'Wheat, Barley & Sorghum Farming',

        ],
    },
    {
        'sector':'Administration, Business Support and Waste Management Services',
        'industry':[
            'Business Service Centers',
            'Carpet Cleaning',
            'Convention & Visitor Bureaus',
            'Correctional Facilities',
            'Credit Bureaus & Rating Agencies',
            'Debt Collection Agencies',
            'Document Preparation Services',
            'Employment & Recruiting Agencies',
            'Human Resources & Benefits Administration',
            'Janitorial Services',
            'Landscaping Services',
            'Office Staffing & Temp Agencies',
            'Packaging & Labeling Services',
            'Pest Control',
            'Portable Toilet Rental & Septic Tank Cleaning',
            'Professional Employer Organizations',
            'Recycling Facilities',
            'Remediation & Environmental Cleanup Services',
            'Security Alarm Services',
            'Security Services',
            'Telemarketing & Call Centers',
            'Tour Operators',
            'Trade Show and Conference Planning',
            'Travel Agencies',
            'Waste Collection Services',
            'Waste Treatment & Disposal Services',
        ],
    },
    {
        'sector':'Finance and Insurance',
        'industry':[
            'Auto Leasing, Loans & Sales Financing',
            'Commercial Banking',
            'Commodity Dealing and Brokerage',
            'Credit Card Issuing',
            'Credit Card Processing & Money Transferring',
            'Credit Unions',
            'Custody, Asset & Securities Services',
            'Finance and Insurance',
            'Financial Planning & Advice',
            'Health & Medical Insurance',
            'Health & Welfare Funds',
            'Industrial Banks',
            'Insurance Brokers & Agencies',
            'Investment Banking & Securities Dealing',
            'Life Insurance & Annuities',
            'Loan Administration, Check Cashing & Other Services',
            'Loan Brokers',
            'Open-End Investment Funds',
            'Portfolio Management',
            'Private Equity, Hedge Funds & Investment Vehicles',
            'Property, Casualty and Direct Insurance',
            'Real Estate Investment Trusts',
            'Real Estate Loans & Collateralized Debt',
            'Reinsurance Carriers',
            'Retirement & Pension Plans',
            'Savings Banks & Thrifts',
            'Securities Brokering',
            'Stock & Commodity Exchanges',
            'Third-Party Administrators & Insurance Claims Adjusters',
            'Trusts & Estates',
            'Venture Capital & Principal Trading',
            "Workers' Compensation & Other Insurance Funds",
        ],
    },
    {
        'sector':'Other Services (except Public Administration)',
        'industry':[
            'Appliance Repair',
            'Auto Mechanics',
            'Car Body Shops',
            'Car Wash & Auto Detailing',
            'Cemetery Services',
            'Civic, Social & Youth Organizations',
            'Conservation & Human Rights Organizations',
            'Dating Services',
            'Donations, Grants & Endowment',
            'Dry Cleaners',
            'Electronic & Computer Repair Services',
            'Funeral Homes',
            'Furniture Repair & Reupholstery',
            'Hair & Nail Salons',
            'Hair Loss Treatment & Removal',
            "Homeowners' Associations",
            'Industrial Laundry & Linen Supply',
            'Laundromats',
            'Machinery Maintenance & Heavy Equipment Repair Services',
            'Maids, Nannies & Gardeners',
            'Oil Change Services',
            'Parking Lots & Garages',
            'Pet Grooming & Boarding',
            'Photofinishing',
            'Religious Organizations',
            'Shoe Repair',
            'Tanning Salons',
            'Wedding Services',
            'Weight Loss Services',
        ],
    },
    {
        'sector':'Retail Trade',
        'industry':[
            'Art Dealers',
            'Auto Parts Stores',
            'Beauty, Cosmetics & Fragrance Stores',
            'Beer, Wine & Liquor Stores',
            'Bicycle Dealership and Repair',
            'Boat Dealership and Repair',
            'Book Stores',
            'Camera Stores',
            "Children's & Infants' Clothing Stores",
            'Computer Stores',
            'Consumer Electronics Stores',
            'Convenience Stores',
            'Department Stores',
            'Direct Selling Companies',
            'Dollar & Variety Stores',
            'E-Commerce & Online Auctions',
            'Eyeglasses & Contact Lens Stores',
            'Fabric, Craft & Sewing Supplies Stores',
            'Family Clothing Stores',
            'Fish & Seafood Markets',
            'Floor Covering Stores',
            'Florists',
            'Fruit & Vegetable Markets',
            'Fuel Dealers',
            'Furniture Stores',
            'Gas Stations',
            'Gas Stations with Convenience Stores',
            'Gift Shops & Card Stores',
            'Handbag, Luggage & Accessory Stores',
            'Hardware Stores',
            'Health Stores',
            'Hobby & Toy Stores',
            'Home Furnishings Stores',
            'Home Improvement Stores',
            'Jewelry Stores',
            'Lawn & Outdoor Equipment Stores',
            'Lingerie, Swimwear & Bridal Stores',
            'Lumber & Building Material Stores',
            'Mail Order',
            'Manufactured Home Dealers',
            'Meat Markets',
            "Men's Clothing Stores",
            'Motorcycle Dealership and Repair',
            'Musical Instrument & Supplies Stores',
            'New Car Dealers',
            'Nursery & Garden Stores',
            'Office Supply Stores',
            'Paint Stores',
            'Pet Stores',
            'Pharmacies & Drug Stores',
            'Record Stores',
            'Recreational Vehicle Dealers',
            'Retail Trade',
            'Shoe Stores',
            'Small Specialty Retail Stores',
            'Specialty Food Stores',
            'Sporting Goods Stores',
            'Supermarkets & Grocery Stores',
            'Tire Dealers',
            'Used Car Dealers',
            'Used Goods Stores',
            'Vending Machine Operators',
            'Warehouse Clubs & Supercenters',
            "Women's Clothing Stores",
        ],
    },
    {
        'sector':'Professional, Scientific and Technical Services',
        'industry':[
            'Accounting Services',
            'Advertising Agencies',
            'Architects',
            'Billboard & Outdoor Advertising',
            'Building Inspectors',
            'Conveyancing Services',
            'Credit Counselors, Surveyors & Appraisers',
            'Direct Mail Advertising',
            'Engineering Services',
            'Environmental Consulting',
            'Fashion Designers',
            'Geophysical Services',
            'Graphic Designers',
            'HR Consulting',
            'Industrial Designers',
            'Interior Designers',
            'IT Consulting',
            'Laboratory Testing Services',
            'Landscape Design',
            'Law Firms',
            'Management Consulting',
            'Market Research',
            'Media Buying Agencies',
            'Media Representative Firms',
            'Payroll & Bookkeeping Services',
            'Photography',
            'Print Advertising Distribution',
            'Professional, Scientific and Technical Services',
            'Promotional Products',
            'Public Relations Firms',
            'Scientific & Economic Consulting',
            'Scientific Research & Development',
            'Surveying & Mapping Services',
            'Tax Preparation Services',
            'Translation Services',
            'Veterinary Services',
        ],
    },
    {
        'sector':'Information & Technology',
        'industry':[
            'Audio Production Studios',
            'Book Publishing',
            'Business Analytics & Enterprise Software Publishing',
            'Cable Networks',
            'Cable Providers',
            'Data Processing & Hosting Services',
            'Database & Directory Publishing',
            'Database, Storage & Backup Software Publishing',
            'Design, Editing & Rendering Software Publishing',
            'Greeting Cards & Other Publishing',
            'Independent Label Music Production',
            'Information',
            'Internet Publishing and Broadcasting',
            'Internet Service Providers',
            'Magazine & Periodical Publishing',
            'Major Label Music Production',
            'Movie & Video Distribution',
            'Movie & Video Production',
            'Movie Theaters',
            'Music Publishing',
            'News Syndicates',
            'Newspaper Publishing',
            'Operating Systems & Productivity Software Publishing',
            'Radar & Satellite Operations',
            'Radio Broadcasting',
            'Satellite Telecommunications Providers',
            'Satellite TV Providers',
            'Search Engines',
            'Security Software Publishing',
            'Software Publishing',
            'Telecommunications Resellers',
            'Television Broadcasting',
            'Television Production',
            'Video Game Software Publishing',
            'Video Games',
            'Video Postproduction Services',
            'VoIP',
            'Wired Telecommunications Carriers',
            'Wireless Telecommunications Carriers',
        ],
    },
    {
        'sector':'Healthcare and Social Assistance',
        'industry':[
            'Adoption & Child Welfare Services',
            'Alternative Healthcare Providers',
            'Ambulance Services',
            'Biotechnology',
            'Blood & Organ Banks',
            'Chiropractors',
            'Community Food Services',
            'Community Housing & Homeless Shelters',
            'Day Care',
            'Dentists',
            'Diagnostic & Medical Laboratories',
            'Elderly & Disabled Services',
            'Emergency & Other Outpatient Care Centers',
            'Family Counseling & Crisis Intervention Services',
            'Family Planning & Abortion Clinics',
            'Healthcare and Social Assistance',
            'Home Care Providers',
            'Hospitals',
            'Job Training & Career Counseling',
            'Mental Health & Substance Abuse Centers',
            'Mental Health & Substance Abuse Clinics',
            'Natural Disaster & Emergency Relief Services',
            'Nursing Care Facilities',
            'Optometrists',
            'Orphanages & Group Homes',
            'Physical Therapists',
            'Podiatrists',
            'Primary Care Doctors',
            'Psychiatric Hospitals',
            'Psychologists, Social Workers & Marriage Counselors',
            'Residential Intellectual Disability Facilities',
            'Retirement Communities',
            'Specialist Doctors',
            'Specialty Hospitals',
        ],
    },
    {
        'sector':'Transportation and Warehousing',
        'industry':[
            'Aircraft Maintenance, Repair & Overhaul',
            'Airport Operations',
            'Automobile Towing',
            'Charter Flights',
            'Couriers & Local Delivery Services',
            'Domestic Airlines',
            'Dry Docks & Cargo Inspection Services',
            'Farm Product Storage & Warehousing',
            'Freight Forwarding Brokerages & Agencies',
            'Freight Packing & Logistics Services',
            'Gas Pipeline Transportation',
            'Inland Water Transportation',
            'International Airlines',
            'Local Freight Trucking',
            'Local Specialized Freight Trucking',
            'Long-Distance Freight Trucking',
            'Moving Services',
            'Ocean & Coastal Transportation',
            'Oil Pipeline Transportation',
            'Organic Chemical Pipeline Transportation',
            'Port & Harbor Operations',
            'Postal Service',
            'Public School Bus Services',
            'Public Storage & Warehousing',
            'Public Transportation',
            'Rail Maintenance Services',
            'Rail Transportation',
            'Refined Petroleum Pipeline Transportation',
            'Refrigerated Storage',
            'Scheduled and Charter Bus Services',
            'Sightseeing Transportation',
            'Specialized Storage & Warehousing',
            'Stevedoring & Marine Cargo Handling',
            'Tank & Refrigeration Trucking',
            'Taxi & Limousine Services',
            'Toll Roads & Weigh Stations',
            'Transportation and Warehousing',
            'Tugboat & Shipping Navigational Services',
        ],
    },
    {
        'sector':'Wholesale Trade',
        'industry':[
            'Aircraft, Marine & Railroad Transportation Equipment Wholesaling',
            'Auto Parts Wholesaling',
            'Automobile Wholesaling',
            'Beef & Pork Wholesaling',
            'Beer Wholesaling',
            'Book, Magazine & Newspaper Wholesaling',
            'Camera & Film Wholesaling',
            'Cattle & Hog Wholesaling',
            'Chemical Wholesaling',
            'Cigarette & Tobacco Products Wholesaling',
            'Coal & Ore Wholesaling',
            'Computer & Packaged Software Wholesaling',
            'Confectionery Wholesaling',
            'Construction & Mining Equipment Wholesaling',
            'Copier & Office Equipment Wholesaling',
            'Corn, Wheat & Soybean Wholesaling',
            'Dairy Wholesaling',
            'Drug, Cosmetic & Toiletry Wholesaling',
            'Egg & Poultry Wholesaling',
            'Electrical Equipment Wholesaling',
            'Electronic Part & Equipment Wholesaling',
            'Farm Supplies Wholesaling',
            'Farm, Lawn & Garden Equipment Wholesaling',
            'Fish & Seafood Wholesaling',
            'Flower & Nursery Stock Wholesaling',
            'Footwear Wholesaling',
            'Frozen Food Wholesaling',
            'Fruit & Vegetable Wholesaling',
            'Furniture Wholesaling',
            'Gasoline & Petroleum Bulk Stations',
            'Gasoline & Petroleum Wholesaling',
            'Glasses & Contacts Wholesaling',
            'Grocery Wholesaling',
            'Heating & Air Conditioning Wholesaling',
            'Home Furnishing Wholesaling',
            'Industrial Machinery & Equipment Wholesaling',
            'Industrial Supplies Wholesaling',
            'Janitorial Equipment Supply Wholesaling',
            'Jewelry & Watch Wholesaling',
            'Laboratory Supply Wholesaling',
            'Lumber Wholesaling',
            'Manufactured Home Wholesaling',
            'Medical Supplies Wholesaling',
            "Men's & Boys' Apparel Wholesaling",
            'Metal Wholesaling',
            'Office Stationery Wholesaling',
            'Paint Wholesaling',
            'Paper Bag & Disposable Plastic Product Wholesaling',
            'Paper Wholesaling',
            'Piece Goods, Notions & Other Apparel Wholesaling',
            'Plastics Wholesaling',
            'Plumbing & Heating Supplies Wholesaling',
            'Recyclable Material Wholesaling',
            'Refrigeration Equipment Wholesaling',
            'Restaurant & Hotel Equipment Wholesaling',
            'Roofing, Siding & Insulation Wholesaling',
            'Soft Drink, Baked Goods & Other Grocery Wholesaling',
            'Sporting Goods Wholesaling',
            'Stone, Concrete & Clay Wholesaling',
            'Tire Wholesaling',
            'Tool & Hardware Wholesaling',
            'Toy & Craft Supplies Wholesaling',
            'TV & Appliance Wholesaling',
            'Used Car Parts Wholesaling',
            'Wholesale Trade Agents and Brokers',
            'Wholesale Trade',
            'Wine & Spirits Wholesaling',
            "Women's & Children's Apparel Wholesaling",
        ],
    },
    {
        'sector':'Manufacturing',
        'industry':[
            'Abrasive & Sandpaper Manufacturing',
            'Adhesive Manufacturing',
            'Aircraft, Engine & Parts Manufacturing',
            'Alarm, Horn & Traffic Control Equipment Manufacturing',
            'Aluminum Manufacturing',
            'Animal Food Production',
            'Apparel Knitting Mills',
            'Art & Office Supply Manufacturing',
            'Asphalt Manufacturing',
            'Athletic & Sporting Goods Manufacturing',
            'ATV, Golf Cart & Snowmobile Manufacturing',
            'Audio & Video Equipment Manufacturing',
            'Auto Parts Manufacturing',
            'Automobile Brakes Manufacturing',
            'Automobile Electronics Manufacturing',
            'Automobile Engine & Parts Manufacturing',
            'Automobile Interior Manufacturing',
            'Automobile Metal Stamping',
            'Automobile Steering & Suspension Manufacturing',
            'Automobile Transmission Manufacturing',
            'Baking Mix & Prepared Food Production',
            'Ball Bearing Manufacturing',
            'Battery Manufacturing',
            'Billboard & Sign Manufacturing',
            'Blind & Shade Manufacturing',
            'Boat Building',
            'Boiler & Heat Exchanger Manufacturing',
            'Bottled Water Production',
            'Brand Name Pharmaceutical Manufacturing',
            'Bread Production',
            'Breweries',
            'Cabinet & Vanity Manufacturing',
            'Candy Production',
            'Canned Fruit & Vegetable Processing',
            'Car & Automobile Manufacturing',
            'Cardboard Box & Container Manufacturing',
            'Carpet Mills',
            'Cement Manufacturing',
            'Ceramics Manufacturing',
            'Cereal Production',
            'Chemical Product Manufacturing',
            'Chocolate Production',
            'Cigarette & Tobacco Manufacturing',
            'Circuit Board & Electronic Component Manufacturing',
            'Clay Brick & Product Manufacturing',
            'Coated & Laminated Paper Manufacturing',
            'Coffee Production',
            'Communication Equipment Manufacturing',
            'Computer Manufacturing',
            'Computer Peripheral Manufacturing',
            'Concrete Pipe & Block Manufacturing',
            'Construction Machinery Manufacturing',
            'Cookie, Cracker & Pasta Production',
            'Copier & Optical Machinery Manufacturing',
            'Copper Rolling, Drawing & Extruding',
            'Cosmetic & Beauty Products Manufacturing',
            'Costume & Team Uniform Manufacturing',
            'Cut and Sew Manufacturers',
            'Dairy Product Production',
            'Distilleries',
            'Dye & Pigment Manufacturing',
            'Electrical Equipment Manufacturing',
            'Engine & Turbine Manufacturing',
            'Explosives Manufacturing',
            'Ferrous Metal Foundry Products',
            'Fertilizer Manufacturing',
            'Flour Milling',
            'Forklift & Conveyor Manufacturing',
            'Frozen Food Production',
            'Generic Pharmaceutical Manufacturing',
            'Glass Product Manufacturing',
            'Glasses & Contact Lens Manufacturing',
            'Guns & Ammunition Manufacturing',
            'Gym & Exercise Equipment Manufacturing',
            'Gypsum Product Manufacturing',
            'Hand Tool & Cutlery Manufacturing',
            'Hardware Manufacturing',
            'Heating & Air Conditioning Equipment Manufacturing',
            'Hose & Belt Manufacturing',
            'Hosiery Mills',
            'Household Furniture Manufacturing',
            'Ice Cream Production',
            'Ink Manufacturing',
            'Inorganic Chemical Manufacturing',
            'Iron & Steel Manufacturing',
            'Jewelry Manufacturing',
            'Juice Production',
            'Laminated Plastics Manufacturing',
            'Leather Good & Luggage Manufacturing',
            'Leather Tanning & Finishing',
            'Lighting & Bulb Manufacturing',
            'Lighting Fixtures Manufacturing',
            'Lime Manufacturing',
            'Lubricant Oil Manufacturing',
            'Machine Shop Services',
            'Major Household Appliance Manufacturing',
            'Manufacturing',
            'Margarine & Cooking Oil Processing',
            'Mattress Manufacturing',
            'Meat, Beef & Poultry Processing',
            'Medical Device Manufacturing',
            'Medical Instrument & Supply Manufacturing',
            "Men's & Boys' Apparel Manufacturing",
            'Metal Can & Container Manufacturing',
            'Metal Pipe & Tube Manufacturing',
            'Metal Plating & Treating',
            'Metal Stamping & Forging',
            'Metal Tank Manufacturing',
            'Metalworking Machinery Manufacturing',
            'Millwork',
            'Mineral Product Manufacturing',
            'Mining, Oil & Gas Machinery Manufacturing',
            'Motorcycle, Bike & Parts Manufacturing',
            'Navigational Instrument Manufacturing',
            'Nonferrous Metal Foundry Products Manufacturing',
            'Nonferrous Metal Refining',
            'Nonferrous Metal Rolling & Alloying',
            'Office Furniture Manufacturing',
            'Office Stationery Manufacturing',
            'Organic Chemical Manufacturing',
            'Oxygen & Hydrogen Gas Manufacturing',
            'Paint Manufacturing',
            'Paper Mills',
            'Paper Product Manufacturing',
            'Paperboard Mills',
            'Pesticide Manufacturing',
            'Petrochemical Manufacturing',
            'Petroleum Refining',
            'Plastic & Resin Manufacturing',
            'Plastic Bottle Manufacturing',
            'Plastic Film, Sheet & Bag Manufacturing',
            'Plastic Pipe & Parts Manufacturing',
            'Plastic Products Miscellaneous Manufacturing',
            'Plastics & Rubber Machinery Manufacturing',
            'Polystyrene Foam Manufacturing',
            'Power Conversion Equipment Manufacturing',
            'Power Tools & Other General Purpose Machinery Manufacturing',
            'Precast Concrete Manufacturing',
            'Prefabricated Home Manufacturing',
            'Printing',
            'Printing Services',
            'Printing, Paper, Food, Textile & Other Machinery Manufacturing',
            'Pump & Compressor Manufacturing',
            'Quick Printing',
            'Ready-Mix Concrete Manufacturing',
            'Recordable Media Manufacturing',
            'Rubber Product Manufacturing',
            'Sanitary Paper Product Manufacturing',
            'Sawmills & Wood Production',
            'Screw, Nut & Bolt Manufacturing',
            'Seafood Preparation',
            'Seasoning, Sauce and Condiment Production',
            'Semiconductor & Circuit Manufacturing',
            'Semiconductor Machinery Manufacturing',
            'Sheet Metal, Window & Door Manufacturing',
            'Ship Building',
            'Shoe & Footwear Manufacturing',
            'Snack Food Production',
            'Soap & Cleaning Compound Manufacturing',
            'Soda Production',
            'Solar Panel Manufacturing',
            'Space Vehicle & Missile Manufacturing',
            'Steel Rolling & Drawing',
            'Structural Metal Product Manufacturing',
            'Sugar Processing',
            'SUV & Light Truck Manufacturing',
            'Synthetic Fiber Manufacturing',
            'Syrup & Flavoring Production',
            'Tank & Armored Vehicle Manufacturing',
            'Tea Production',
            'Telecommunication Networking Equipment Manufacturing',
            'Textile Mills',
            'Tire Manufacturing',
            'Tortilla Production',
            'Toy, Doll & Game Manufacturingv',
            'Tractors & Agricultural Machinery Manufacturing',
            'Train, Subway & Transit Car Manufacturing',
            'Truck & Bus Manufacturing',
            'Truck, Trailer & Motor Home Manufacturing',
            'Urethane Foam Manufacturing',
            'Vacuum, Fan & Small Household Appliance Manufacturing',
            'Valve Manufacturing',
            'Vitamin & Supplement Manufacturing',
            'Wind Turbine Manufacturing',
            'Wineries',
            'Wire & Cable Manufacturing',
            'Wire & Spring Manufacturing',
            'Wiring Device Manufacturing',
            "Women’s, Girls’ and Infants’ Apparel Manufacturing",
            'Wood Pallets & Skids Production',
            'Wood Paneling Manufacturing',
            'Wood Product Manufacturing',
            'Wood Pulp Mills',
            'Woodworking Machinery Manufacturing',
        ],
    },
    {
        'sector':'Technology',
        'industry':[
            '3D Printer Manufacturing',
            '3D Printing & Rapid Prototyping Services',
            'Activated Carbon Manufacturing',
            'Adult & Pornographic Websites',
            'Air Purification Equipment Manufacturing',
            'ATM Manufacturing',
            'Audio and Visual Equipment Repair Services',
            'Augmented Reality Software',
            'Bare Printed Circuit Board Manufacturing',
            'Battery Recycling',
            'Binoculars Manufacturing',
            'Biomass Power',
            'Biometrics Scan Software',
            'Bioplastics Manufacturing',
            'Building Lighting Control System Manufacturing',
            'Business Intelligence Software',
            'CAD Software Developers',
            'Capacitor Manufacturing',
            'Cell Phone Recycling',
            'Chatbot Software',
            'Cloud Security Software',
            'Collaboration & Project Management Software',
            'Colocation Facilities',
            'Computer-Aided Design Software Developers',
            'Conferencing Service Providers',
            'Consumer Drone Manufacturing',
            'Contact Tracing Systems',
            'Contactless Payment Systems',
            'Cooking Oil Recycling',
            'Court Reporting Services',
            'CRM System Providers',
            'Cryotherapy',
            'Dark Fiber Network Operators',
            'Data Backup Software',
            'Data Loss Prevention Software',
            'Data Recovery Services',
            'Digital Advertising Agencies',
            'Digital Forensic Services',
            'Document Digitization Services',
            'E-Book Publishing',
            'E-cigarette Manufacturing',
            'Educational Software',
            'Electric Vehicle Charging Stations',
            'Electricity & Signal Testing Instrument Manufacturing',
            'Electronic Access Control System Manufacturing',
            'Electronic Article Surveillance Product Manufacturing',
            'Electronic Design Automation Software Developers',
            'Electronic Goods Recycling',
            'Endpoint Security Software',
            'Enterprise Resource Planning Software Developers',
            'Ethanol Fuel Production',
            'ETL & ELT Data Management Software',
            'Facial Recognition Software',
            'Fantasy Sports Services',
            'Fiber-Optic Cable Manufacturing',
            'Field Service Management Software',
            'Film Animation Services',
            'Fire & Smoke Alarm Manufacturing',
            'Fleet Telematics Systems',
            'Flyboarding Equipment Manufacturing',
            'Fraud Detection Software Developers',
            'Gas Detection Device Manufacturing',
            'Geothermal Electricity Plant Operation',
            'Headphone Manufacturing',
            'Home Automation Services',
            'HR & Payroll Software',
            'Hybrid & Electric Vehicle Manufacturing',
            'Identity Management Software',
            'Identity Theft Protection Services',
            'Internet Hosting Services',
            'Internet Radio Broadcasting',
            'IT Security Consulting',
            'Language Learning Software Developers',
            'Learning Management Software',
            'LED Manufacturing',
            'Lithium Battery Manufacturing',
            'Metal Detector Manufacturing',
            'Microprocessor Chip Manufacturing',
            'Microscope Manufacturing',
            'Music Streaming Services',
            'Night Vision Equipment Manufacturing',
            'Nonferrous Metal Recycling',
            'Online Payment Processing Software Developers',
            'Online Recruitment Sites',
            'Online Survey Software',
            'Optical Character Recognition Software',
            'Optical Character-Recognition Software Developers',
            'Ordnance Manufacturing',
            'Oscilloscope Manufacturing',
            'Point of Sale Software Developers',
            'Power Circuit Breaker Manufacturing',
            'Precision Agriculture Systems & Services',
            'Pressure Sensor Manufacturing',
            'Real-Time Traffic Information Providers',
            'Respiratory Protection Equipment Manufacturing',
            'Safe & Vault Manufacturing',
            'Scale & Balance Manufacturing',
            'Scrap Metal Recycling',
            'Seismic-Monitoring Equipment Manufacturing',
            'SEO & Internet Marketing Consultants',
            'Smart Lighting Manufacturing',
            'Smart Meter Manufacturing',
            'Smart Speaker Manufacturing',
            'Smart Thermostat Manufacturing',
            'Smartphone App Developers',
            'Social Network Game Development',
            'Social Networking Sites',
            'Softwre as a Service',
            'Software Development Services',
            'Software Testing Services',
            'Solar Farm Developers',
            'Solar Panel Installation',
            'Sonar Device Manufacturing',
            'Speech & Voice Recognition Software Developers',
            'Storage Area Network Software Development',
            'Tax Preparation Software Developers',
            'Thermostat Manufacturing',
            'Tire & Rubber Recycling',
            'Traffic Light Manufacturing',
            'Ultrasonic Cleaning Equipment Manufacturing',
            'Urinal Manufacturing',
            'Variable Message Signage Manufacturing',
            'Video Conferencing Software Developers',
            'Video Games Software Developers',
            'Video Games Software Publishing',
            'Video Management Platform Software',
            'Virtual Data Rooms',
            'Virtual Reality Software',
            'Waste-to-Energy Plant Operation',
            'Wearable Device Manufacturing',
            'Web Design Services',
            'Web Domain Name Sales',
            'Website Creation Software Developers',
            'Wind Turbine Installation',
            'Wire Connector Manufacturing',
            'Wireless Internet Service Providers',
        ]
    },
    {
        'sector':'Business Franchises',
        'industry':[
            'Acai Bowl Shops',
            'Auto Glass Repair & Replacement Franchises',
            'Auto Maintenance & Repair Franchises',
            'Axe Throwing Centers',
            'Barbecue Restaurants',
            'Battery Store Franchises',
            'Blow Dry Bars',
            'Bounce House Rental',
            'Brazilian Jiu-Jitsu Studios',
            'Bubble Tea Shops',
            'Budget Hotel Franchises',
            'Cabaret Clubs',
            'Candy Stores',
            'Car Wash & Auto Detailing Franchises',
            'Cash for Gold Stores',
            'Child Education & Developmental Center Franchises',
            'Child Recreation Center Franchises',
            'Childproofing Services',
            "Children's Fitness Center Franchises",
            'Circuit Training Gyms',
            'Climbing Gyms',
            'Coffee Store Franchises',
            'Commercial Cleaning Service Franchises',
            'Cruise & Travel Agency Franchises',
            'Direct Selling of Jewelry & Accessories',
            'Dry-Cleaning Service Franchises',
            'Fast Food Burger Franchises',
            'Fast Food Chicken Franchises',
            'Furniture Rental Franchises',
            'Furniture Store Franchises',
            'Gym & Fitness Franchises',
            'Hairdressing & Barber Franchises',
            'Handyman Service Franchises',
            'Hardware Store Franchises',
            'Health Food & Supplement Store Franchises',
            'HVAC Service Franchises',
            'Ice Cream & Gelato Store Franchises',
            'In-Home Senior Care Franchises',
            'Inkjet & Toner Replacement Store Franchises',
            'Landscaping & Gardening Franchises',
            'Massage Franchises',
            'Mobile Home Repair',
            'Motorcycle Gear Stores',
            'Packing & Shipping Service Franchises',
            'Pharmacy & Drug Store Franchises',
            'Photography Classes',
            'Pizza Restaurant Franchises',
            'Plumbing Service Franchises',
            'Pool & Hot Tub Services',
            'Pop-Up Shops',
            'Pottery Stores',
            'Product Design Services',
            'Real Estate Agency Franchises',
            'Real Estate Photography',
            'Residential Cleaning Service Franchises',
            'Residential Senior Care Franchises',
            'Restaurant Supply Stores',
            'Same-Day Delivery Services',
            'Sandwich & Sub Store Franchises',
            'Security Guard & Patrol Service Franchises',
            'Sign & Banner Manufacturing Franchises',
            'Sign & Banner Shops',
            'Snowmobile Rental Services',
            'Tutoring & Test Preparation Franchises',
            'Weight Loss Service Franchises',
            'Window & Door Stores',
            'Window Blind Installation Franchises',
        ],
    },
    {
        'sector':'Advisory and Financial Services',
        'industry':[
            'AD&D Insurance',
            'Agricultural Banks',
            'Agricultural Insurance',
            'Aircraft Rental Services',
            'Airline Catering Services',
            'Armored Transportation Services',
            'Audio & Visual Equipment Rental',
            'Audit Services',
            'Auto Extended Warranty Providers',
            'Automobile Insurance',
            'Background Check Services',
            'Bankruptcy Lawyers & Attorneys',
            'Batting Cages',
            'Benefit Administration Services',
            'Boat Rentals',
            'Bond Management',
            'Business Brokers',
            'Business Concierge Services',
            'Business Financing',
            'Business Information Resellers',
            'Business Insurance',
            'Business Lawyers & Attorneys',
            'Business Process Outsourcing Services',
            'Business Valuation Firms',
            'Card Reward Program Services',
            'Casting Agencies',
            'Check Cashing & Payday Loan Services',
            'Child Adoption Agencies',
            'Claims Adjusting',
            'Commercial Aircraft Leasing',
            'Commercial Auto Insurance',
            'Commercial Construction Insurance',
            'Company Research Services',
            'Construction Machinery Rentals',
            'Consumer Lawyers & Attorneys',
            "Contractors' Insurance",
            'Corporate Law Firms',
            'Credit Repair Services',
            'Criminal Lawyers & Attorneys',
            'Cryptocurrency Exchanges',
            'Customer Care Centers',
            'Customs Brokers',
            'Cyber Liability Insurance',
            'Data Center Colocation Services',
            'Debt Relief Services',
            'Dental Insurance',
            'Disability Insurance',
            'Distribution and Logistics Consulting Services',
            'Document Management Services',
            'e-Discovery Consulting Services',
            'e-Discovery Software Publishing',
            'e-Trading Software Developers',
            'Education Consultants',
            'Employee Assistance Program Services',
            'Employee Relocation Services',
            'Employment Law Firms',
            'Energy & Utility Consulting Services',
            'Entertainment Insurance',
            'Entertainment Lawyers & Attorneys',
            'Estate Lawyers & Attorneys',
            'Estate Liquidation Services',
            'Executive Search Recruiters',
            'Expert Networks',
            'Expert Witness Consulting Services',
            'Family Law & Divorce Lawyers & Attorneys',
            'Financial Data Service Providers',
            'Fleet Car Leasing',
            'Foreclosure Lawyers & Attorneys',
            'Foreign Currency Exchange Services',
            'Forensic Accounting Services',
            'Franchise Resale Brokers & Consultants',
            'Group Purchasing Organizations',
            'Health & Medical Reinsurance',
            'Healthcare Consultants',
            'Healthcare Lawyers & Attorneys',
            'Hedge Funds',
            'High Frequency Trading',
            'HMO Providers',
            'Home Furniture Rental',
            'Home Medical Equipment Rentals',
            'Home Warranty Providers',
            "Homeowners' Insurance",
            'Identity Theft Insurance',
            'Immigration Lawyers & Attorneys',
            'Installment Lenders',
            'Insurance Claims Processing Software',
            'Insurance Lawyers & Attorneys',
            'Intermodal Container Leasing',
            'International Law Firms',
            'International Trade Financing',
            'Invoice Factoring',
            'Jet Ski Rental',
            'Kayak Rental',
            'Labor Relations Lawyers & Attorneys',
            'Libraries & Archives',
            'Long Term Care Insurance',
            'Marketing Consultants',
            'Mediation Services',
            'Medical Billing Services',
            'Medical Claims Processing Services',
            'Medical Equipment Rental',
            'Medical Group Practice Management',
            'Medical Malpractice Insurance',
            'Medical Patient Financing',
            'Merchant Banking Services',
            'Model Agencies',
            'Money Transferring Services',
            'Motion Picture & Theatrical Equipment Rental',
            'Mutual Fund Management',
            'Nondestructive Testing Services',
            'Office Furniture Rental',
            'Oil, Gas & Mining Consultants',
            'Online Insurance Brokers',
            'Online Legal Services',
            'Online Mortgage Brokers',
            'Online Payment Processors',
            'Online Stock Brokerages',
            'Pallet & Skid Rental',
            'Party Bus Rentals',
            'Pawn Shops',
            'Peer-to-Peer Lending Platforms',
            'Pension Fund Management',
            'Personal Finance & Money Management Software Developers',
            'Personal Injury Lawyers & Attorneys',
            'Pharmaceutical Contract Management Consulting',
            'Pharmaceuticals Packing & Labeling Services',
            'Portable Toilet Rental',
            'Prepaid Credit & Debit Card Providers',
            'Private Banking Services',
            'Product Returns Management Services',
            'Product Warranty Insurance',
            'Professional Organizations',
            'Property & Casualty Reinsurance',
            'Public Opinion Research',
            'Railroad Car Rental & Leasing',
            'Real Estate Law Firms',
            'Regional Banks',
            "Renters' Insurance",
            'Repossession Services',
            'Reverse Mortgages Providers',
            'Risk Management, Insurance Advisory & Consulting',
            'RV & Camper Van Rental',
            'Serviced Office Leasing',
            'Settlement Funding Companies',
            'Shared Office Spaces',
            'Stenographic Services',
            'Stock Price & Rating Research',
            'Subprime Auto Loans',
            'Supply Chain Management Services',
            'Tax Lawyers and Attorneys',
            'Third-Party Data Entry Services',
            'Third-Party Logistics',
            'Title Insurance',
            'Trademark & Patent Lawyers & Attorneys',
            'Transcription Services',
            'Travel Insurance',
            'Trial Consultants',
            'Typing Services',
            'Vision Insurance',
            'Weather Forecasting Services',
            "Workers' Compensation Insurance",
        ],
    },
    {
        'sector':'Industrial Machinery, Gas and Chemicals',
        'industry':[
            'Acetylene Production',
            'Aerial Work Platform Manufacturing',
            'Agricultural Machinery Wholesaling',
            'Air & Gas Compressor Manufacturing',
            'Aircraft Parts Distributors',
            'Ambulance Manufacturing',
            'Architectural, Flat & Plate Glass Distributors',
            'Argon & Hydrogen Manufacturing',
            'Armored Vehicle Manufacturing',
            'Audio & Video Equipment Distributors',
            'Auto Air Conditioning Manufacturing',
            'Auto Exhaust System Manufacturing',
            'Auto Lighting Equipment Manufacturing',
            'Auto Parts Remanufacturing',
            'Auto Transmission Manufacturing',
            'Automated Guided Vehicle Manufacturing',
            'Automatic Fire Sprinkler System Manufacturing',
            'Automotive Coatings Manufacturing',
            'Automotive Glass Manufacturing',
            'Autonomous Underwater Vehicle Manufacturing',
            'Axle & Transaxle Manufacturing',
            'Blow Molding Machinery Manufacturing',
            'Brewery Equipment Wholesalers',
            'Car Airbag Manufacturing',
            'Car Wheel Manufacturing',
            'Carbon Dioxide Production',
            'Carbon Fiber & Graphene Manufacturing',
            'Carburetor Manufacturing',
            'Carpet & Floor Covering Distributors',
            'Catalog & Directory Printing',
            'Catalytic Converter Manufacturing',
            'Chipping & Grinding Equipment Manufacturing',
            'Chlorine Manufacturing',
            'Cleaning & Maintenance Supplies Distributors',
            'Combine Harvester Manufacturing',
            'Commercial Cooking Equipment Manufacturing',
            'Commercial Dishwasher Manufacturing',
            'Commercial Oven Manufacturing',
            'Commercial Refrigerator Manufacturing',
            'Commercial Washing Machine Manufacturing',
            'Contract Injection Molding Manufacturing',
            'Conveying Equipment Manufacturing',
            'Crane Rental Services',
            'Cutting Tool & Machine Tool Accessory Manufacturing',
            'Diesel Engine & Parts Wholesaling',
            'Digital Printing',
            'Disinfectant Manufacturing',
            'Electric Submersible Pump Manufacturing',
            'Engine Filter Manufacturing',
            'Engine Radiator Manufacturing',
            'Engine Rebuilding & Remanufacturing',
            'Essential Oil Manufacturing',
            'Fabricated Structural Metal Manufacturing',
            'Fire Extinguisher Manufacturing',
            'Fire Truck Manufacturing',
            'Fluid Power Equipment Distributors',
            'Food Processing Equipment Distributors',
            'Forklift Manufacturing',
            'Gasket & Seal Manufacturing',
            'Gear & Speed Changer Manufacturing',
            'Hand Tool Manufacturing',
            'Heavy Duty Truck Parts Dealers',
            'Heavy-Duty Diesel Engine Manufacturing',
            'Helicopter Manufacturing',
            'Helium Production',
            'Hydraulic & Pneumatic Jack Manufacturing',
            'Hydraulic Motor Manufacturing',
            'Ice Making Machine Manufacturing',
            'Industrial & Commercial Fan & Blower Manufacturing',
            'Industrial & Fluid Power Valve Manufacturing',
            'Industrial Spraying Equipment Manufacturing',
            'Lawn and Garden Equipment Manufacturing',
            'Lead Smelting & Refining',
            'Machine Tool Distributors',
            'Material Handling Equipment Distributors',
            'Metal Barrel & Drum Manufacturing',
            'Methanol Production',
            'Mixer & Paver Manufacturing',
            'Motor Grader Manufacturing',
            'Motor Oil Manufacturing',
            'Newsprint Manufacturing',
            'Office Fixture and Storage Manufacturing',
            'Packaging Machinery Manufacturing',
            'Paint Remover Manufacturing',
            'Personal Watercraft Manufacturing',
            'Photographic & Photocopying Equipment Manufacturing',
            'Piano Manufacturing',
            'Power Tool Manufacturing',
            'Precious Metal Dealers',
            'Prime Mover Generator Sets Manufacturing',
            'Printing Equipment Distributors',
            'Propane Tank Exchange Services',
            'PTFE (Teflon) Coating Manufacturing',
            'Railcar Manufacturing',
            'Recreational Vehicle Manufacturing',
            'Safety Equipment & Supplies Distributors',
            'School Supplies Wholesaling',
            'Shipping Container Manufacturing',
            'Silicone Manufacturing',
            'Snow Mobile Manufacturing',
            'Snowplow Manufacturing',
            'Spark Plug Manufacturing',
            'Stair Contractors',
            'Stall Installation Services',
            'Steel Wire Drawing',
            'Submarine Manufacturing',
            'Sulfur & Sulfuric Acid Manufacturing',
            'Synthetic Organic Alcohol Manufacturing',
            'Titanium Dioxide Manufacturing',
            'Titanium Manufacturing',
            'Tractor Shovel-Loader Manufacturing',
            'Trailer & Camper Manufacturing',
            'Transformer Oil Production',
            'Truck Repair',
            'Truck Trailer Dealers',
            'Truck Trailer Manufacturing',
            'Truck-Mounted Cement Mixer Manufacturing',
            'Truck-Mounted Crane Manufacturing',
            'Turbocharger Manufacturing',
            'Unmanned Aerial Vehicle (UAV) Manufacturing',
            'Used Aircraft & Equipment Dealers',
            'Used Truck Dealers',
            "Veterinarians' Equipment & Supplies Wholesaling",
            'Walk-in & Cabinet Cooler Manufacturing',
            'Washer & Dryer Repair Services',
            'Water Heater Manufacturing',
            'Welding Equipment Distributors',
        ],
    },
    {
        'sector':'Specialist Engineering, Infrastructure and Contractors',
        'industry':[
            'Aerospace Engineering Services',
            'Agricultural Engineering Services',
            'Airport Management Services',
            'Airport Terminal Construction',
            'Artificial Grass Turf Installation',
            'Bridge & Structure Painting Contractors',
            'Building Exterior Cleaners',
            'Cell Site Operation and Leasing',
            'Cementing Oil & Gas Well Services',
            'Commercial Property Remodeling',
            'Commercial Shelving Manufacturing',
            'Communication Antenna Construction',
            'Computer Facilities Management Services',
            'Concrete Pipe Manufacturing',
            'Concrete Reinforcing Bar Manufacturing',
            'Construction Project Management Services',
            'Corrosion Protection Engineering Services',
            'Damage Restoration Services',
            'Deck & Patio Construction',
            'Demolition Equipment Manufacturing',
            'Dog Park Design Services',
            'Dredging Services',
            'Dump Truck Services',
            'Dumpster Rental',
            'Editorial Services',
            'Electric Transmission Line & Tower Installation',
            'Elevator Manufacturing',
            'Engineered Wood Product Manufacturing',
            'Escalator Installation & Maintenance',
            'Escalator Manufacturing',
            'Fence & Scaffolding Manufacturing',
            'Fence & Scaffolding Rental',
            'General Freight Trucking (Less Than Truckload)',
            'General Freight Trucking (Truckload)',
            'Hazardous Waste Collection',
            'Heavy-Duty OTR Tire Manufacturing',
            'Hoist & Winch Manufacturing',
            'Hospital Construction',
            'Hotel Construction',
            'House Painting & Decorating Contractors',
            'Hydraulic Fracturing Services',
            'Hydroelectric Power Plant Construction',
            'Indoor Sports Facilities Management',
            'Industrial Design Services',
            'Industrial Engineering Services',
            'Insulation Contractors',
            'Laboratory Design & Build Contractors',
            'Local Refrigerated Trucking',
            'Long-Distance Refrigerated Trucking',
            'Lumber Yards',
            'Marine Salvage Services',
            'Metal Door Manufacturing',
            'Monument Makers',
            'Natural Gas Liquid Processing',
            'Nonresidential Property Managers',
            'Nuclear Power Plant Construction',
            'Offshore Drilling Extraction',
            'Offshore Oil Rig & Platform Construction',
            'Oil and Gas Drilling Equipment Manufacturing',
            'Oil Field Drilling Services',
            'Ornamental & Architectural Metalwork Manufacturing',
            'Overhead Crane Manufacturing',
            'Playground Design Services',
            'Potash Mining',
            'Powder Coating Services',
            'Railroad Track Construction',
            'Reconstituted Wood Product Manufacturing',
            'Residential Property Managers',
            'Restaurant Construction',
            'Retail Store Fixture Dealers',
            'Sandblasting Services',
            'Scaffolding Contractors',
            'Septic, Drain & Sewer Cleaning Services',
            'Sewer & Pipeline Rehabilitation',
            'Sheet Metal Work Manufacturing',
            'Sheet-Metal Roofing Manufacturing',
            'Shopping Mall Management',
            'Shortline Railroad Operation',
            'Snowplowing Services',
            'Soil Testing Services',
            'Solar Water Heater Installation',
            'Sports & Athletic Field Construction',
            'Sports Stadium Construction',
            'Street Cleaning Services',
            'Stringed Instrument Manufacturing',
            'Toll Road Operation',
            'Tunnel Construction',
            'Urban Planning Software',
            'Wallpapering Services',
            'Water & Air Quality Testing Services',
            'Water Well Drilling Services',
            'Wind Farm Construction',
            'Window Coverings Wholesaling',
            'Window Installation',
            'Window Washing',
            'Wireless Tower Construction',
            'Wood Chip Manufacturing',
            'Wood Door & Window Manufacturing',
            'Wood Flooring Manufacturing',
        ],
    },
    {
        'sector':'Online Retail',
        'industry':[
            'Daily Deals Sites',
            'Digital Music Downloads',
            'Online Air Travel Bookings',
            'Online Antiques & Collectibles Sales',
            'Online Apartment Rental Services',
            'Online Art Sales',
            'Online Audio Equipment Sales',
            'Online Automotive Parts & Accessories Sales',
            'Online Baby & Infant Apparel Sales',
            'Online Baby Product Sales',
            'Online Beer, Wine & Liquor Sales',
            'Online Book Sales',
            'Online Camera & Camcorder Sales',
            'Online Car Dealers',
            'Online Car Rental',
            'Online CD, DVD & DVR Sales',
            "Online Children's Toy Sales",
            'Online Computer & Tablet Sales',
            'Online Computer Software Sales',
            'Online Designer Clothing Sales',
            'Online Electrical Office Equipment Sales',
            'Online Event Ticket Sales',
            'Online Eyeglasses & Contact Lens Sales',
            'Online Flower Shops',
            'Online Gift Vouchers',
            'Online Greeting Card Sales',
            'Online Grocery Sales',
            'Online Gun & Ammunition Sales',
            'Online Handbag & Luggage Sales',
            'Online Hardware & Tool Sales',
            'Online Hobby & Craft Supplies Sales',
            'Online Home Furnishing Sales',
            'Online Hotel Booking',
            'Online Household Furniture Sales',
            'Online Industrial Machinery & Equipment Sales',
            'Online Industrial Supplies Sales',
            'Online Jewelry & Watch Sales',
            'Online Kitchenware Sales',
            'Online Large Kitchen Appliance Sales',
            'Online Medical Supplies Sales',
            "Online Men's Clothing Sales",
            'Online Music Instrument Sales',
            'Online Office & School Supply Sales',
            'Online Office Furniture Sales',
            'Online Original Design T-Shirt Sales',
            'Online Paint Sales',
            'Online Perfume & Cosmetic Sales',
            'Online Pet Food & Pet Supply Sales',
            'Online Pharmaceutical Sales',
            'Online Photo Printing',
            'Online Plumbing & Electrical Supply Sales',
            'Online Residential Home Sale Listings',
            'Online Scenic & Sightseeing Tour Bookings',
            'Online Sea Travel Bookings',
            'Online Shoe Sales',
            'Online Small Electrical Appliance Sales',
            'Online Sporting Apparel Sales',
            'Online Sporting Goods Sales',
            'Online Sunglasses Sales',
            'Online Television Sales',
            'Online Tour Arrangement Bookings',
            'Online Tutoring Services',
            'Online Video Downloads',
            'Online Video Game Console Sales',
            'Online Video Game Sales',
            'Online Vitamin & Supplement Sales',
            'Online Weight Loss Services',
            "Online Women's Clothing Sales",
            'Video Streaming Services',
        ],
    },
    {
        'sector':'Consumer Goods and Services',
        'industry':[
            'Acoustic & Electric Guitar Manufacturing',
            'Acupuncture Schools',
            'Adhesive Tape Manufacturing',
            'Adjustable Bed & Mattress Manufacturing',
            'Adult Stores',
            'Aerial Yoga Classes',
            'Aestheticians',
            'After-School Program Providers',
            'Air Duct Cleaning Services',
            'Air Gun & Airsoft Gun Manufacturing',
            'Airport Shuttle Operators',
            'Aluminum Foil Manufacturing',
            'Anesthesiologists',
            'Animal Control Services',
            'Animal Physical Therapists',
            'Animal Rescue Shelters',
            'Animal-Assisted Therapy',
            'Antique Stores',
            'Archery Ranges',
            'Art Classes',
            'Art Consulting',
            'Art Galleries',
            'Art Installation Services',
            'Art Restoration Services',
            'Art Space Rental',
            'Art Supply Stores',
            'Athleisure Clothing Manufacturing',
            'Athletic Event Organizers',
            'Athletic Shoe Stores',
            'ATV Manufacturing',
            'ATV Rentals & Tour Services',
            'Auction Houses',
            'Audiobook Publishing',
            'Auto Electrical Services',
            'Auto Windshield Repair Services',
            'Backpack & Courier Bag Manufacturing',
            'Bacon Production',
            'Bagel Stores',
            'Bail Bond Services',
            'Bakery Cafes',
            'Ballet Schools',
            'Barbecue & Grill Manufacturing',
            'Barber Shops',
            'Barre Classes',
            'Bartending Schools',
            'BBQ & Outdoor Cooking Stores',
            'BBQ Charcoal Manufacturing',
            'BBQ Sauce Production',
            'Beach Equipment Rental',
            'Beauty Supply Stores',
            'Bed & Mattress Stores',
            'Bicycle Manufacturing',
            'Bike Rental',
            'Bike Tours',
            'Bird Shops',
            'Bleach Manufacturing',
            'Board Game Manufacturing',
            'Boat Part Suppliers',
            'Body Armor Manufacturing',
            'Body Contouring',
            'Body Piercing Studios',
            'Boutique Hotels',
            'Boxing Gyms & Clubs',
            'Boxing Promoters',
            'Breakfast Restaurants & Diners',
            'Brewery Tours',
            'Brewpubs',
            'Bridal Stores',
            'Budget Airlines',
            'Buffet Restaurants',
            'Bungee Jumping Tours',
            'Burger Restaurants',
            'Bus Rental',
            'Cabinet Makers',
            'Cable Shopping Networks',
            'Calligraphy Services',
            'Candle Manufacturing',
            'Cannabis Equipment & Accessory Stores',
            'Car Sharing Providers',
            'Cardio Classes',
            'Carpet Dyeing Services',
            'Cell Phone Repair',
            'Charcoal Grilling Equipment Stores',
            'Charter Schools',
            'Cheese Production',
            "Children's Book Publishing",
            'Chinese Restaurants',
            'Chocolate Stores',
            'Cider Production',
            'Cideries',
            'Cigar Lounges',
            'Classic Car Dealers',
            'Clock Repair Services',
            'Clothing & Apparel Rental',
            'Clothing Alteration Services',
            'Clothing Boutiques',
            'Cocktail Bars',
            'Cocoa & Drinking Chocolate Production',
            'Coffee Creamer Production',
            'Comedy Clubs',
            'Comic Book Publishing',
            'Commercial Cleaning & Sanitation Services',
            'Commercial Embroidery Services',
            'Commercial Window Cleaning Services',
            'Computer Rental',
            'Cookie & Wafer Manufacturing',
            'Cookie Shops',
            'Cooking Classes',
            'Cosmetology & Beauty Schools',
            'Cotton Personal-Care Product Manufacturing',
            'Countertop Manufacturing',
            'Cracker & Biscuits Manufacturing',
            'Craft Beer Production',
            'Craft Spirits Production',
            'Cruise Line Operators',
            'Culinary Arts Schools',
            'Cupcake Stores',
            'Cured Meat Production',
            'Curtain & Drape Manufacturing',
            'Custom Cake Services',
            'Custom Screen Printing',
            'Dance Schools',
            'Dance Studios',
            'Dancewear Stores',
            'Day Camps',
            'Delicatessens',
            'Dental Schools',
            'Deodorant Manufacturing',
            'Dessert Stores',
            'Diaper Manufacturing',
            'Discount Department Stores',
            'Dishwasher Manufacturing',
            'Do-It-Yourself Auto Shops',
            'Dog & Pet Breeders',
            'Dog Training Services',
            'Dog Walking Services',
            'Door Installation & Repair Services',
            'Door Lock & Lockset Manufacturing',
            'Doughnut Stores',
            'Dried Fruit & Vegetable Snack Production',
            'Driving Schools',
            'Dry Pasta Production',
            'Dry Pet Food Production',
            'Durable Baby Goods Stores',
            'Duty-Free Shops',
            'Early Childhood Learning Centers',
            'Electric Scooter Rental',
            'Electronic Cigarette & Vape Shops',
            'Engraving Services',
            'Escape Rooms',
            'Escort Services',
            'Ethnic Supermarkets',
            'Event Photography',
            'Exercise Equipment Stores',
            'External Sliding Door Manufacturing',
            'Eyebrow Services',
            'Eyeglasses Frames Manufacturing',
            'Farm Equipment Repair',
            'Farm Supply Stores',
            'Farmers Markets',
            'Faucet Manufacturing',
            'Fine Dining Restaurants',
            'Fire Protection and Security System Installation Contractors',
            'Fire Protection Consultants',
            'Firearm Training',
            'Fireplace Services',
            'Fireworks Retailers',
            'Fishing Boat Charter',
            'Flag Manufacturing',
            'Flea Markets',
            'Flight Instructors',
            'Flying Schools',
            'Food Courts & Halls',
            'Food Delivery Services',
            'Food Processor & Blender Manufacturing',
            'Food Safety Training',
            'Food Trucks',
            'French Restaurants',
            'Fresh Bread & Bakery Goods Wholesaling',
            'Frozen Cake & Pastry Manufacturing',
            'Frozen Dough & Batter Production',
            'Frozen Fruit & Juice Production',
            'Frozen Pizza Production',
            'Frozen Potato Product Production',
            'Frozen Vegetable Production',
            'Frozen Yogurt Stores',
            'Furniture Restoration Services',
            'Garage Door Installation',
            'Garage Door Manufacturing',
            'Generator Rental',
            'Ghost Kitchens',
            'Glass Stores',
            'Go-Kart Racing Tracks',
            'Golf Cart Dealers',
            'Golf Cart Manufacturing',
            'Golf Cart Rentals',
            'Golf Equipment Stores',
            'Golf Instructors',
            'Golf Resorts',
            'Greek Restaurants',
            'Grocery Delivery Services',
            'Grouting Contractors',
            'Guitar Stores',
            'Gum Production',
            'Gun & Ammunition Stores',
            'Gutter Services',
            'Gymnastics Classes',
            'Hair Cleaning Product Manufacturing',
            'Hair Color Product Manufacturing',
            'Hair Extension Services',
            'Hair Removal Services',
            'Hair Remover Product Manufacturing',
            'Hair Salons',
            'Hand Sanitizer Manufacturing',
            'Hang Glider Sightseeing',
            'Hard Seltzer Production',
            'Hat & Cap Stores',
            'Health Retreats',
            'Hearing Aid Stores',
            'High Fructose Corn Syrup Production',
            'Hiking & Outdoor Equipment Stores',
            'Hookah Lounges',
            'Hops Farming',
            'Horse Racing Tracks',
            'Horse Rentals',
            'Horse Training Services',
            'Horseback Tours',
            'Hospitality Management Schools',
            'Hostels',
            'Hot Dog & Hamburger Bun Production',
            'Hot Dog & Sausage Production',
            'Hot Sauce Production',
            'Hot Tub Manufacturing',
            'Household Appliance Stores',
            'Hunting & Fishing Supplies Stores',
            'Hydroponic Growing Equipment Stores',
            'Ice Cream Stores',
            'Ice Delivery Services',
            'Ice Manufacturing',
            'Ice Rinks',
            'Indian Restaurants',
            'Indoor Climbing Walls',
            'Indoor Cycling Workout Classes',
            'Indoor Marble Contractors',
            'Indoor Play Centers',
            'Industrial Chocolate Production',
            'Infant Formula Manufacturing',
            'Interval Training Gyms',
            'Investigation Services',
            'Italian Restaurants',
            'Japanese Restaurants',
            'Jazz Clubs & Lounges',
            'Jewelry Designers',
            'Jewelry Wholesaling',
            'Juice & Smoothie Bars',
            'Karaoke Bars',
            'Karaoke Machine Rental',
            'Karate Studios',
            'Key Duplication Services',
            'Kickboxing Studios',
            'Kitchen & Cookware Stores',
            'Kombucha Production',
            'Korean Restaurants',
            'Lamp & Lighting Stores',
            'LAN Gaming Centers',
            'Language Schools',
            'Laser Tag Arenas',
            'Lawn Mower Rentals',
            'Lawn Sprinkler Installation Contractors',
            'Leather Boot Manufacturing',
            'Leather Cleaning & Repair Services',
            'Licensed Sports Apparel Stores',
            'Limousine & Town Car Services',
            'Lingerie Manufacturing',
            'Lingerie Stores',
            'Lip Care Product Manufacturing',
            'Lipstick Manufacturing',
            'Live Performance Theaters',
            'Locksmiths',
            'Luggage & Leather Goods Stores',
            'Magazine & Newspaper Stands',
            'Magicians',
            'Major Smart Appliance Manufacturing',
            'Makerspace Design Services',
            'Makeup Artists',
            'Mall Carts & Kiosks',
            'Marine Engineering Services',
            'Martial Arts Studios',
            'Massage Schools',
            'Massage Services',
            'Maternity Wear Stores',
            'Mayonnaise Production',
            'Meal Kit Delivery Services',
            'Meal Replacement Product Manufacturing',
            'Meat Alternatives Production',
            'Meat Jerky Production',
            'Meditation Studios',
            'Mediterranean Restaurants',
            'Mexican Restaurants',
            'Microwave Oven Manufacturing',
            'Middle Eastern Restaurants',
            'Miniature Golf Courses',
            'Mixed Martial Arts Studios',
            'Mobile Home Manufacturing',
            'Mobile Storage Services',
            'Motorcycle Rental',
            'Mouthwash Manufacturing',
            'Movie Rental Kiosks',
            'Musical Instrument Rental Services',
            'Musical Instrument Repair Services',
            'Nail Polish Manufacturing',
            'Newswire Services',
            'Nightclubs',
            'Noodle Restaurants',
            'Nursery Schools',
            'Nursing Schools',
            'Office Cleaning Services',
            'Office Equipment Repair Services',
            'Office Equipment Stores',
            'Office Furniture Stores',
            'Office Plant Services',
            'Officiant Services',
            'Olive Oil Production',
            'Online Gambling Services',
            'Online Used Book Sales',
            'Organic Grocery Stores',
            'Organic Snack Production',
            'Outdoor Furniture Manufacturing',
            'Outdoor Marble Contractors',
            'Outdoor Movie Operators',
            'Outlet Stores',
            'Oven & Stove Manufacturing',
            'Paddleboarding Lessons',
            'Paint & Sip Studios',
            'Paint-Your-Own Pottery Studios',
            'Paintball Fields',
            'Pancake Restaurants',
            'Paper Cup Manufacturing',
            'Paper Towel Manufacturing',
            'Paperboard Drinking Cup Manufacturing',
            'Parachute Manufacturing',
            'Parasail Tours',
            'Party & Event Planners',
            'Party Supply Rental',
            'Pasta Sauce Production',
            'Patio Coverings',
            'Patisseries and Cake Shops',
            'Peanut Butter Production',
            'Perfume & Fragrance Manufacturing',
            'Perfume & Fragrance Stores',
            'Personal Chef Services',
            'Personal Protective Equipment Manufacturing',
            'Personal Waxing & Nail Salons',
            'Pet Adoption Services',
            'Pet Cremation Services',
            'Pet Food & Supplies Wholesaling',
            'Pet Food Production',
            'Pet Hospice',
            'Pet Insurance',
            'Pet Photography Services',
            'Pet Sitting Services',
            'Pet Training Services',
            'Pet Transportation Services',
            'Pet Waste Products',
            'Petting Zoos & Tourist Farms',
            'Petting Zoos',
            'Photo Booth Rental',
            'Photo Restoration Services',
            'Photography Schools',
            'Piano Repair & Tuning Services',
            'Piano Stores',
            'Picture Framing Stores',
            'Pizza Restaurants',
            'Playground & Park Equipment Manufacturing',
            "Plus-Size Men's Clothing Stores",
            "Plus-Size Women's Clothing Stores",
            'Poke Shops',
            'Pool & Billiard Halls',
            'Popcorn Production',
            'Potato Chip Production',
            'Potato Farming',
            'Pre-Made Salsa Production',
            'Premium Pet Food Production',
            'Premium Steak Restaurants',
            'Prepared Frozen Fish Production',
            'Prepared Soups Production',
            'Pressure Washing Services',
            'Pretzel Stores',
            'Private Boat Charters',
            'Private Detective Services',
            'Private Jet Charters',
            'Private Music Classes',
            'Protective Sports Equipment Manufacturing',
            'Psychic Services',
            'Ramen Restaurants',
            'Real Estate Schools',
            'Refrigerator & Freezer Manufacturing',
            'Religious Goods Stores',
            'Rendering & Meat Byproduct Processing',
            'Residential Cleaning Services',
            'Residential RV & Trailer Park Operators',
            'Resume Writing and Editing Services',
            'Ride-Sharing Services',
            'Roofing & Siding Contractors',
            'RTD Coffee Production',
            'RTD Mixed Spirit Production',
            'RTD Tea Production',
            'Rug Stores',
            'Rum Production',
            'Salad Dressing Production',
            'Saw & Knife Sharpening Services',
            'Screen Repair Services',
            'Scuba Diving & Underwater Equipment Stores',
            'Seasonal Pop-Up Stores',
            'Shaving Razor Manufacturing',
            'Sheet Music Publishers',
            'Shooting Ranges',
            'Skate Shops',
            'Ski & Snowboard Rental',
            'Ski & Snowboard Shops',
            'Sommelier Services',
            'Souvenir Shops',
            'Soy & Almond Milk Production',
            'Soy Sauce Production',
            'Soybean Processing',
            'Spa Resorts',
            'Sparkling Wine Production',
            'Special Needs Transportation',
            'Specialty Pet Food Production',
            'Sports Bars',
            'Sports Equipment Rental Services',
            'Sports Gambling',
            'Sports Memorabilia & Collectibles Stores',
            'Stock & Broth Production',
            'Strip Clubs',
            'Summer Camps',
            'Sunglasses Stores',
            'Sunscreen Manufacturing',
            'Surf Shops',
            'Surface-Coated Paperboard Manufacturing',
            'Sushi Restaurants',
            'Swimming Pool Cleaning Services',
            'Swimming Pool Equipment Stores',
            'Swimming Pools',
            'Table & Chair Rental Services',
            'Table Salt Production',
            'Tackle Shops',
            'Tactical & Service Clothing Manufacturing',
            'Tattoo Artists',
            'Tattoo Removal Services',
            'Taxidermists',
            'Telephone Wholesaling',
            'Tent, Awning & Canvas Manufacturing',
            'Thai Restaurants',
            'Thrift Stores',
            'Tile & Marble Stores',
            'Tire Retreading & Recapping',
            'Tobacconists',
            'Tofu Production',
            'Toilet Paper Manufacturing',
            'Toothpaste Manufacturing',
            'Tourist Farms',
            'Trailer & RV Repair Services',
            'Trampoline Parks',
            'Treadmill Manufacturing',
            'Tree & Shrub Farming',
            'Tree Trimming Services',
            'Trophy & Engraving Shops',
            'Truck Dealers',
            'Truck Driving Schools',
            'Upholstery Cleaning & Repair Services',
            'Used Book Stores',
            'Used Furniture Stores',
            'Valet Services',
            'Vegan Food Production',
            'Vegetarian & Vegan Restaurants',
            'Vehicle Shipping Services',
            'Video Editing Services',
            'Videographers',
            'Virtual Private Network (VPN) Providers',
            'Virtual Reality Centers',
            'Visual Arts Schools',
            'Vodka Distilleries',
            'Washer & Dryer Manufacturing',
            'Watch & Jewelry Repair',
            'Watch Manufacturing',
            'Watch Stores',
            'Water Delivery Services',
            'Water Parks',
            'Watersport Equipment Rental Services',
            'Wedding Dress Rental Services',
            'Wedding Planners',
            'Wheelchair Rental Services',
            'Whiskey & Bourbon Distilleries',
            'Wig & Hairpiece Manufacturing',
            'Wig & Hairpiece Stores',
            'Wildlife Hunting Ranges',
            'Window Tinting Services',
            'Window Treatment Stores',
            'Wine Bars',
            'Wine Tasting Rooms',
            'Wood Dining Room & Kitchen Furniture Manufacturing',
            'Yogurt Production',
            'Zipline Operators',
            'Zoos & Aquariums',
        ],
    },
]

export const COUNTRY_CODES_SELECTION = [
    {
        name: 'United States',
        code: 'US',
        timezone: 'Pacific Standard Time',
        utc: 'UTC-08:00',
        mobileCode: '+1',
    },
    {
        name: 'Canada',
        code: 'CA',
        timezone: 'Eastern Standard Time',
        utc: 'UTC-05:00',
        mobileCode: '+1',
    },
    {
        name: 'United Kingdom',
        code: 'GB',
        timezone: 'GMT Standard Time',
        utc: 'UTC',
        mobileCode: '+44',
    },
    {
        name: 'Australia',
        code: 'AU',
        timezone: 'AUS Eastern Standard Time',
        utc: 'UTC+10:00',
        mobileCode: '+61',
    },
    {
      name: 'Afghanistan',
      code: 'AF',
      timezone: 'Afghanistan Standard Time',
      utc: 'UTC+04:30',
      mobileCode: '+93',
    },
    {
      name: 'Åland Islands',
      code: 'AX',
      timezone: 'FLE Standard Time',
      utc: 'UTC+02:00',
      mobileCode: '+358-18',
    },
    {
      name: 'Albania',
      code: 'AL',
      timezone: 'Central Europe Standard Time',
      utc: 'UTC+01:00',
      mobileCode: '+355',
    },
    {
      name: 'Algeria',
      code: 'DZ',
      timezone: 'W. Central Africa Standard Time',
      utc: 'UTC+01:00',
      mobileCode: '+213',
    },
    {
      name: 'American Samoa',
      code: 'AS',
      timezone: 'UTC-11',
      utc: 'UTC-11:00',
      mobileCode: '+1-684',
    },
    {
      name: 'Andorra',
      code: 'AD',
      timezone: 'W. Europe Standard Time',
      utc: 'UTC+01:00',
      mobileCode: '+376',
    },
    {
      name: 'Angola',
      code: 'AO',
      timezone: 'W. Central Africa Standard Time',
      utc: 'UTC+01:00',
      mobileCode: '+244',
    },
    {
      name: 'Anguilla',
      code: 'AI',
      timezone: 'SA Western Standard Time',
      utc: 'UTC-04:00',
      mobileCode: '+1-264',
    },
    {
      name: 'Antarctica',
      code: 'AQ',
      timezone: 'Pacific SA Standard Time',
      utc: 'UTC-03:00',
      mobileCode: '+',
    },
    {
      name: 'Antigua and Barbuda',
      code: 'AG',
      timezone: 'SA Western Standard Time',
      utc: 'UTC-04:00',
      mobileCode: '+1-268',
    },
    {
      name: 'Argentina',
      code: 'AR',
      timezone: 'Argentina Standard Time',
      utc: 'UTC-03:00',
      mobileCode: '+54',
    },
    {
      name: 'Armenia',
      code: 'AM',
      timezone: 'Caucasus Standard Time',
      utc: 'UTC+04:00',
      mobileCode: '+374',
    },
    {
      name: 'Aruba',
      code: 'AW',
      timezone: 'SA Western Standard Time',
      utc: 'UTC-04:00',
      mobileCode: '+297',
    },
    {
      name: 'Austria',
      code: 'AT',
      timezone: 'W. Europe Standard Time',
      utc: 'UTC+01:00',
      mobileCode: '+43',
    },
    {
      name: 'Azerbaijan',
      code: 'AZ',
      timezone: 'Azerbaijan Standard Time',
      utc: 'UTC+04:00',
      mobileCode: '+994',
    },
    {
      name: 'Bahamas, The',
      code: 'BS',
      timezone: 'Eastern Standard Time',
      utc: 'UTC-05:00',
      mobileCode: '+1-242',
    },
    {
      name: 'Bahrain',
      code: 'BH',
      timezone: 'Arab Standard Time',
      utc: 'UTC+03:00',
      mobileCode: '+973',
    },
    {
      name: 'Bangladesh',
      code: 'BD',
      timezone: 'Bangladesh Standard Time',
      utc: 'UTC+06:00',
      mobileCode: '+880',
    },
    {
      name: 'Barbados',
      code: 'BB',
      timezone: 'SA Western Standard Time',
      utc: 'UTC-04:00',
      mobileCode: '+1-246',
    },
    {
      name: 'Belarus',
      code: 'BY',
      timezone: 'Belarus Standard Time',
      utc: 'UTC+03:00',
      mobileCode: '+375',
    },
    {
      name: 'Belgium',
      code: 'BE',
      timezone: 'Romance Standard Time',
      utc: 'UTC+01:00',
      mobileCode: '+32',
    },
    {
      name: 'Belize',
      code: 'BZ',
      timezone: 'Central America Standard Time',
      utc: 'UTC-06:00',
      mobileCode: '+501',
    },
    {
      name: 'Benin',
      code: 'BJ',
      timezone: 'W. Central Africa Standard Time',
      utc: 'UTC+01:00',
      mobileCode: '+229',
    },
    {
      name: 'Bermuda',
      code: 'BM',
      timezone: 'Atlantic Standard Time',
      utc: 'UTC-04:00',
      mobileCode: '+1-441',
    },
    {
      name: 'Bhutan',
      code: 'BT',
      timezone: 'Bangladesh Standard Time',
      utc: 'UTC+06:00',
      mobileCode: '+975',
    },
    {
      name: 'Bolivarian Republic of Venezuela',
      code: 'VE',
      timezone: 'Venezuela Standard Time',
      utc: 'UTC-04:30',
      mobileCode: '+58',
    },
    {
      name: 'Bolivia',
      code: 'BO',
      timezone: 'SA Western Standard Time',
      utc: 'UTC-04:00',
      mobileCode: '+591',
    },
    {
      name: 'Bonaire, Sint Eustatius and Saba',
      code: 'BQ',
      timezone: 'SA Western Standard Time',
      utc: 'UTC-04:00',
      mobileCode: '+599',
    },
    {
      name: 'Bosnia and Herzegovina',
      code: 'BA',
      timezone: 'Central European Standard Time',
      utc: 'UTC+01:00',
      mobileCode: '+387',
    },
    {
      name: 'Botswana',
      code: 'BW',
      timezone: 'South Africa Standard Time',
      utc: 'UTC+02:00',
      mobileCode: '+267',
    },
    {
      name: 'Bouvet Island',
      code: 'BV',
      timezone: 'UTC',
      utc: 'UTC',
      mobileCode: '+',
    },
    {
      name: 'Brazil',
      code: 'BR',
      timezone: 'E. South America Standard Time',
      utc: 'UTC-03:00',
      mobileCode: '+55',
    },
    {
      name: 'British Indian Ocean Territory',
      code: 'IO',
      timezone: 'Central Asia Standard Time',
      utc: 'UTC+06:00',
      mobileCode: '+246',
    },
    {
      name: 'Brunei',
      code: 'BN',
      timezone: 'Singapore Standard Time',
      utc: 'UTC+08:00',
      mobileCode: '+673',
    },
    {
      name: 'Bulgaria',
      code: 'BG',
      timezone: 'FLE Standard Time',
      utc: 'UTC+02:00',
      mobileCode: '+359',
    },
    {
      name: 'Burkina Faso',
      code: 'BF',
      timezone: 'Greenwich Standard Time',
      utc: 'UTC',
      mobileCode: '+226',
    },
    {
      name: 'Burundi',
      code: 'BI',
      timezone: 'South Africa Standard Time',
      utc: 'UTC+02:00',
      mobileCode: '+257',
    },
    {
      name: 'Cabo Verde',
      code: 'CV',
      timezone: 'Cape Verde Standard Time',
      utc: 'UTC-01:00',
      mobileCode: '+238',
    },
    {
      name: 'Cambodia',
      code: 'KH',
      timezone: 'SE Asia Standard Time',
      utc: 'UTC+07:00',
      mobileCode: '+855',
    },
    {
      name: 'Cameroon',
      code: 'CM',
      timezone: 'W. Central Africa Standard Time',
      utc: 'UTC+01:00',
      mobileCode: '+237',
    },
    {
      name: 'Cayman Islands',
      code: 'KY',
      timezone: 'SA Pacific Standard Time',
      utc: 'UTC-05:00',
      mobileCode: '+1-345',
    },
    {
      name: 'Central African Republic',
      code: 'CF',
      timezone: 'W. Central Africa Standard Time',
      utc: 'UTC+01:00',
      mobileCode: '+236',
    },
    {
      name: 'Chad',
      code: 'TD',
      timezone: 'W. Central Africa Standard Time',
      utc: 'UTC+01:00',
      mobileCode: '+235',
    },
    {
      name: 'Chile',
      code: 'CL',
      timezone: 'Pacific SA Standard Time',
      utc: 'UTC-03:00',
      mobileCode: '+56',
    },
    {
      name: 'China',
      code: 'CN',
      timezone: 'China Standard Time',
      utc: 'UTC+08:00',
      mobileCode: '+86',
    },
    {
      name: 'Christmas Island',
      code: 'CX',
      timezone: 'SE Asia Standard Time',
      utc: 'UTC+07:00',
      mobileCode: '+61',
    },
    {
      name: 'Cocos (Keeling) Islands',
      code: 'CC',
      timezone: 'Myanmar Standard Time',
      utc: 'UTC+06:30',
      mobileCode: '+61',
    },
    {
      name: 'Colombia',
      code: 'CO',
      timezone: 'SA Pacific Standard Time',
      utc: 'UTC-05:00',
      mobileCode: '+57',
    },
    {
      name: 'Comoros',
      code: 'KM',
      timezone: 'E. Africa Standard Time',
      utc: 'UTC+03:00',
      mobileCode: '+269',
    },
    {
      name: 'Congo',
      code: 'CG',
      timezone: 'W. Central Africa Standard Time',
      utc: 'UTC+01:00',
      mobileCode: '+242',
    },
    {
      name: 'Congo (DRC)',
      code: 'CD',
      timezone: 'W. Central Africa Standard Time',
      utc: 'UTC+01:00',
      mobileCode: '+243',
    },
    {
      name: 'Cook Islands',
      code: 'CK',
      timezone: 'Hawaiian Standard Time',
      utc: 'UTC-10:00',
      mobileCode: '+682',
    },
    {
      name: 'Costa Rica',
      code: 'CR',
      timezone: 'Central America Standard Time',
      utc: 'UTC-06:00',
      mobileCode: '+506',
    },
    {
      name: "Côte d'Ivoire",
      code: 'CI',
      timezone: 'Greenwich Standard Time',
      utc: 'UTC',
      mobileCode: '+225',
    },
    {
      name: 'Croatia',
      code: 'HR',
      timezone: 'Central European Standard Time',
      utc: 'UTC+01:00',
      mobileCode: '+385',
    },
    {
      name: 'Cuba',
      code: 'CU',
      timezone: 'Eastern Standard Time',
      utc: 'UTC-05:00',
      mobileCode: '+53',
    },
    {
      name: 'Curaçao',
      code: 'CW',
      timezone: 'SA Western Standard Time',
      utc: 'UTC-04:00',
      mobileCode: '+599',
    },
    {
      name: 'Cyprus',
      code: 'CY',
      timezone: 'E. Europe Standard Time',
      utc: 'UTC+02:00',
      mobileCode: '+357',
    },
    {
      name: 'Czech Republic',
      code: 'CZ',
      timezone: 'Central Europe Standard Time',
      utc: 'UTC+01:00',
      mobileCode: '+420',
    },
    {
      name: 'Democratic Republic of Timor-Leste',
      code: 'TL',
      timezone: 'Tokyo Standard Time',
      utc: 'UTC+09:00',
      mobileCode: '+670',
    },
    {
      name: 'Denmark',
      code: 'DK',
      timezone: 'Romance Standard Time',
      utc: 'UTC+01:00',
      mobileCode: '+45',
    },
    {
      name: 'Djibouti',
      code: 'DJ',
      timezone: 'E. Africa Standard Time',
      utc: 'UTC+03:00',
      mobileCode: '+253',
    },
    {
      name: 'Dominica',
      code: 'DM',
      timezone: 'SA Western Standard Time',
      utc: 'UTC-04:00',
      mobileCode: '+1-767',
    },
    {
      name: 'Dominican Republic',
      code: 'DO',
      timezone: 'SA Western Standard Time',
      utc: 'UTC-04:00',
      mobileCode: '+1-809 and 1-829',
    },
    {
      name: 'Ecuador',
      code: 'EC',
      timezone: 'SA Pacific Standard Time',
      utc: 'UTC-05:00',
      mobileCode: '+593',
    },
    {
      name: 'Egypt',
      code: 'EG',
      timezone: 'Egypt Standard Time',
      utc: 'UTC+02:00',
      mobileCode: '+20',
    },
    {
      name: 'El Salvador',
      code: 'SV',
      timezone: 'Central America Standard Time',
      utc: 'UTC-06:00',
      mobileCode: '+503',
    },
    {
      name: 'Equatorial Guinea',
      code: 'GQ',
      timezone: 'W. Central Africa Standard Time',
      utc: 'UTC+01:00',
      mobileCode: '+240',
    },
    {
      name: 'Eritrea',
      code: 'ER',
      timezone: 'E. Africa Standard Time',
      utc: 'UTC+03:00',
      mobileCode: '+291',
    },
    {
      name: 'Estonia',
      code: 'EE',
      timezone: 'FLE Standard Time',
      utc: 'UTC+02:00',
      mobileCode: '+372',
    },
    {
      name: 'Ethiopia',
      code: 'ET',
      timezone: 'E. Africa Standard Time',
      utc: 'UTC+03:00',
      mobileCode: '+251',
    },
    {
      name: 'Falkland Islands (Islas Malvinas)',
      code: 'FK',
      timezone: 'SA Eastern Standard Time',
      utc: 'UTC-03:00',
      mobileCode: '+500',
    },
    {
      name: 'Faroe Islands',
      code: 'FO',
      timezone: 'GMT Standard Time',
      utc: 'UTC',
      mobileCode: '+298',
    },
    {
      name: 'Fiji Islands',
      code: 'FJ',
      timezone: 'Fiji Standard Time',
      utc: 'UTC+12:00',
      mobileCode: '+679',
    },
    {
      name: 'Finland',
      code: 'FI',
      timezone: 'FLE Standard Time',
      utc: 'UTC+02:00',
      mobileCode: '+358',
    },
    {
      name: 'France',
      code: 'FR',
      timezone: 'Romance Standard Time',
      utc: 'UTC+01:00',
      mobileCode: '+33',
    },
    {
      name: 'French Guiana',
      code: 'GF',
      timezone: 'SA Eastern Standard Time',
      utc: 'UTC-03:00',
      mobileCode: '+594',
    },
    {
      name: 'French Polynesia',
      code: 'PF',
      timezone: 'Hawaiian Standard Time',
      utc: 'UTC-10:00',
      mobileCode: '+689',
    },
    {
      name: 'French Southern and Antarctic Lands',
      code: 'TF',
      timezone: 'West Asia Standard Time',
      utc: 'UTC+05:00',
      mobileCode: '+',
    },
    {
      name: 'Gabon',
      code: 'GA',
      timezone: 'W. Central Africa Standard Time',
      utc: 'UTC+01:00',
      mobileCode: '+241',
    },
    {
      name: 'Gambia, The',
      code: 'GM',
      timezone: 'Greenwich Standard Time',
      utc: 'UTC',
      mobileCode: '+220',
    },
    {
      name: 'Georgia',
      code: 'GE',
      timezone: 'Georgian Standard Time',
      utc: 'UTC+04:00',
      mobileCode: '+995',
    },
    {
      name: 'Germany',
      code: 'DE',
      timezone: 'W. Europe Standard Time',
      utc: 'UTC+01:00',
      mobileCode: '+49',
    },
    {
      name: 'Ghana',
      code: 'GH',
      timezone: 'Greenwich Standard Time',
      utc: 'UTC',
      mobileCode: '+233',
    },
    {
      name: 'Gibraltar',
      code: 'GI',
      timezone: 'W. Europe Standard Time',
      utc: 'UTC+01:00',
      mobileCode: '+350',
    },
    {
      name: 'Greece',
      code: 'GR',
      timezone: 'GTB Standard Time',
      utc: 'UTC+02:00',
      mobileCode: '+30',
    },
    {
      name: 'Greenland',
      code: 'GL',
      timezone: 'Greenland Standard Time',
      utc: 'UTC-03:00',
      mobileCode: '+299',
    },
    {
      name: 'Grenada',
      code: 'GD',
      timezone: 'SA Western Standard Time',
      utc: 'UTC-04:00',
      mobileCode: '+1-473',
    },
    {
      name: 'Guadeloupe',
      code: 'GP',
      timezone: 'SA Western Standard Time',
      utc: 'UTC-04:00',
      mobileCode: '+590',
    },
    {
      name: 'Guam',
      code: 'GU',
      timezone: 'West Pacific Standard Time',
      utc: 'UTC+10:00',
      mobileCode: '+1-671',
    },
    {
      name: 'Guatemala',
      code: 'GT',
      timezone: 'Central America Standard Time',
      utc: 'UTC-06:00',
      mobileCode: '+502',
    },
    {
      name: 'Guernsey',
      code: 'GG',
      timezone: 'GMT Standard Time',
      utc: 'UTC',
      mobileCode: '+44-1481',
    },
    {
      name: 'Guinea',
      code: 'GN',
      timezone: 'Greenwich Standard Time',
      utc: 'UTC',
      mobileCode: '+224',
    },
    {
      name: 'Guinea-Bissau',
      code: 'GW',
      timezone: 'Greenwich Standard Time',
      utc: 'UTC',
      mobileCode: '+245',
    },
    {
      name: 'Guyana',
      code: 'GY',
      timezone: 'SA Western Standard Time',
      utc: 'UTC-04:00',
      mobileCode: '+592',
    },
    {
      name: 'Haiti',
      code: 'HT',
      timezone: 'Eastern Standard Time',
      utc: 'UTC-05:00',
      mobileCode: '+509',
    },
    {
      name: 'Heard Island and McDonald Islands',
      code: 'HM',
      timezone: 'Mauritius Standard Time',
      utc: 'UTC+04:00',
      mobileCode: '+ ',
    },
    {
      name: 'Honduras',
      code: 'HN',
      timezone: 'Central America Standard Time',
      utc: 'UTC-06:00',
      mobileCode: '+504',
    },
    {
      name: 'Hong Kong SAR',
      code: 'HK',
      timezone: 'China Standard Time',
      utc: 'UTC+08:00',
      mobileCode: '+852',
    },
    {
      name: 'Hungary',
      code: 'HU',
      timezone: 'Central Europe Standard Time',
      utc: 'UTC+01:00',
      mobileCode: '+36',
    },
    {
      name: 'Iceland',
      code: 'IS',
      timezone: 'Greenwich Standard Time',
      utc: 'UTC',
      mobileCode: '+354',
    },
    {
      name: 'India',
      code: 'IN',
      timezone: 'India Standard Time',
      utc: 'UTC+05:30',
      mobileCode: '+91',
    },
    {
      name: 'Indonesia',
      code: 'ID',
      timezone: 'SE Asia Standard Time',
      utc: 'UTC+07:00',
      mobileCode: '+62',
    },
    {
      name: 'Iran',
      code: 'IR',
      timezone: 'Iran Standard Time',
      utc: 'UTC+03:30',
      mobileCode: '+98',
    },
    {
      name: 'Iraq',
      code: 'IQ',
      timezone: 'Arabic Standard Time',
      utc: 'UTC+03:00',
      mobileCode: '+964',
    },
    {
      name: 'Ireland',
      code: 'IE',
      timezone: 'GMT Standard Time',
      utc: 'UTC',
      mobileCode: '+353',
    },
    {
      name: 'Israel',
      code: 'IL',
      timezone: 'Israel Standard Time',
      utc: 'UTC+02:00',
      mobileCode: '+972',
    },
    {
      name: 'Italy',
      code: 'IT',
      timezone: 'W. Europe Standard Time',
      utc: 'UTC+01:00',
      mobileCode: '+39',
    },
    {
      name: 'Jamaica',
      code: 'JM',
      timezone: 'SA Pacific Standard Time',
      utc: 'UTC-05:00',
      mobileCode: '+1-876',
    },
    {
      name: 'Jan Mayen',
      code: 'SJ',
      timezone: 'W. Europe Standard Time',
      utc: 'UTC+01:00',
      mobileCode: '+47',
    },
    {
      name: 'Japan',
      code: 'JP',
      timezone: 'Tokyo Standard Time',
      utc: 'UTC+09:00',
      mobileCode: '+81',
    },
    {
      name: 'Jersey',
      code: 'JE',
      timezone: 'GMT Standard Time',
      utc: 'UTC',
      mobileCode: '+44-1534',
    },
    {
      name: 'Jordan',
      code: 'JO',
      timezone: 'Jordan Standard Time',
      utc: 'UTC+02:00',
      mobileCode: '+962',
    },
    {
      name: 'Kazakhstan',
      code: 'KZ',
      timezone: 'Central Asia Standard Time',
      utc: 'UTC+06:00',
      mobileCode: '+7',
    },
    {
      name: 'Kenya',
      code: 'KE',
      timezone: 'E. Africa Standard Time',
      utc: 'UTC+03:00',
      mobileCode: '+254',
    },
    {
      name: 'Kiribati',
      code: 'KI',
      timezone: 'UTC+12',
      utc: 'UTC+12:00',
      mobileCode: '+686',
    },
    {
      name: 'Korea',
      code: 'KR',
      timezone: 'Korea Standard Time',
      utc: 'UTC+09:00',
      mobileCode: '+82',
    },
    {
      name: 'Kosovo',
      code: 'XK',
      timezone: 'Central European Standard Time',
      utc: 'UTC+01:00',
      mobileCode: '+',
    },
    {
      name: 'Kuwait',
      code: 'KW',
      timezone: 'Arab Standard Time',
      utc: 'UTC+03:00',
      mobileCode: '+965',
    },
    {
      name: 'Kyrgyzstan',
      code: 'KG',
      timezone: 'Central Asia Standard Time',
      utc: 'UTC+06:00',
      mobileCode: '+996',
    },
    {
      name: 'Laos',
      code: 'LA',
      timezone: 'SE Asia Standard Time',
      utc: 'UTC+07:00',
      mobileCode: '+856',
    },
    {
      name: 'Latvia',
      code: 'LV',
      timezone: 'FLE Standard Time',
      utc: 'UTC+02:00',
      mobileCode: '+371',
    },
    {
      name: 'Lebanon',
      code: 'LB',
      timezone: 'Middle East Standard Time',
      utc: 'UTC+02:00',
      mobileCode: '+961',
    },
    {
      name: 'Lesotho',
      code: 'LS',
      timezone: 'South Africa Standard Time',
      utc: 'UTC+02:00',
      mobileCode: '+266',
    },
    {
      name: 'Liberia',
      code: 'LR',
      timezone: 'Greenwich Standard Time',
      utc: 'UTC',
      mobileCode: '+231',
    },
    {
      name: 'Libya',
      code: 'LY',
      timezone: 'E. Europe Standard Time',
      utc: 'UTC+02:00',
      mobileCode: '+218',
    },
    {
      name: 'Liechtenstein',
      code: 'LI',
      timezone: 'W. Europe Standard Time',
      utc: 'UTC+01:00',
      mobileCode: '+423',
    },
    {
      name: 'Lithuania',
      code: 'LT',
      timezone: 'FLE Standard Time',
      utc: 'UTC+02:00',
      mobileCode: '+370',
    },
    {
      name: 'Luxembourg',
      code: 'LU',
      timezone: 'W. Europe Standard Time',
      utc: 'UTC+01:00',
      mobileCode: '+352',
    },
    {
      name: 'Macao SAR',
      code: 'MO',
      timezone: 'China Standard Time',
      utc: 'UTC+08:00',
      mobileCode: '+853',
    },
    {
      name: 'Macedonia, Former Yugoslav Republic of',
      code: 'MK',
      timezone: 'Central European Standard Time',
      utc: 'UTC+01:00',
      mobileCode: '+389',
    },
    {
      name: 'Madagascar',
      code: 'MG',
      timezone: 'E. Africa Standard Time',
      utc: 'UTC+03:00',
      mobileCode: '+261',
    },
    {
      name: 'Malawi',
      code: 'MW',
      timezone: 'South Africa Standard Time',
      utc: 'UTC+02:00',
      mobileCode: '+265',
    },
    {
      name: 'Malaysia',
      code: 'MY',
      timezone: 'Singapore Standard Time',
      utc: 'UTC+08:00',
      mobileCode: '+60',
    },
    {
      name: 'Maldives',
      code: 'MV',
      timezone: 'West Asia Standard Time',
      utc: 'UTC+05:00',
      mobileCode: '+960',
    },
    {
      name: 'Mali',
      code: 'ML',
      timezone: 'Greenwich Standard Time',
      utc: 'UTC',
      mobileCode: '+223',
    },
    {
      name: 'Malta',
      code: 'MT',
      timezone: 'W. Europe Standard Time',
      utc: 'UTC+01:00',
      mobileCode: '+356',
    },
    {
      name: 'Man, Isle of',
      code: 'IM',
      timezone: 'GMT Standard Time',
      utc: 'UTC',
      mobileCode: '+44-1624',
    },
    {
      name: 'Marshall Islands',
      code: 'MH',
      timezone: 'UTC+12',
      utc: 'UTC+12:00',
      mobileCode: '+692',
    },
    {
      name: 'Martinique',
      code: 'MQ',
      timezone: 'SA Western Standard Time',
      utc: 'UTC-04:00',
      mobileCode: '+596',
    },
    {
      name: 'Mauritania',
      code: 'MR',
      timezone: 'Greenwich Standard Time',
      utc: 'UTC',
      mobileCode: '+222',
    },
    {
      name: 'Mauritius',
      code: 'MU',
      timezone: 'Mauritius Standard Time',
      utc: 'UTC+04:00',
      mobileCode: '+230',
    },
    {
      name: 'Mayotte',
      code: 'YT',
      timezone: 'E. Africa Standard Time',
      utc: 'UTC+03:00',
      mobileCode: '+262',
    },
    {
      name: 'Mexico',
      code: 'MX',
      timezone: 'Central Standard Time (Mexico)',
      utc: 'UTC-06:00',
      mobileCode: '+52',
    },
    {
      name: 'Micronesia',
      code: 'FM',
      timezone: 'West Pacific Standard Time',
      utc: 'UTC+10:00',
      mobileCode: '+691',
    },
    {
      name: 'Moldova',
      code: 'MD',
      timezone: 'GTB Standard Time',
      utc: 'UTC+02:00',
      mobileCode: '+373',
    },
    {
      name: 'Monaco',
      code: 'MC',
      timezone: 'W. Europe Standard Time',
      utc: 'UTC+01:00',
      mobileCode: '+377',
    },
    {
      name: 'Mongolia',
      code: 'MN',
      timezone: 'Ulaanbaatar Standard Time',
      utc: 'UTC+08:00',
      mobileCode: '+976',
    },
    {
      name: 'Montenegro',
      code: 'ME',
      timezone: 'Central European Standard Time',
      utc: 'UTC+01:00',
      mobileCode: '+382',
    },
    {
      name: 'Montserrat',
      code: 'MS',
      timezone: 'SA Western Standard Time',
      utc: 'UTC-04:00',
      mobileCode: '+1-664',
    },
    {
      name: 'Morocco',
      code: 'MA',
      timezone: 'Morocco Standard Time',
      utc: 'UTC',
      mobileCode: '+212',
    },
    {
      name: 'Mozambique',
      code: 'MZ',
      timezone: 'South Africa Standard Time',
      utc: 'UTC+02:00',
      mobileCode: '+258',
    },
    {
      name: 'Myanmar',
      code: 'MM',
      timezone: 'Myanmar Standard Time',
      utc: 'UTC+06:30',
      mobileCode: '+95',
    },
    {
      name: 'Namibia',
      code: 'NA',
      timezone: 'Namibia Standard Time',
      utc: 'UTC+01:00',
      mobileCode: '+264',
    },
    {
      name: 'Nauru',
      code: 'NR',
      timezone: 'UTC+12',
      utc: 'UTC+12:00',
      mobileCode: '+674',
    },
    {
      name: 'Nepal',
      code: 'NP',
      timezone: 'Nepal Standard Time',
      utc: 'UTC+05:45',
      mobileCode: '+977',
    },
    {
      name: 'Netherlands',
      code: 'NL',
      timezone: 'W. Europe Standard Time',
      utc: 'UTC+01:00',
      mobileCode: '+31',
    },
    {
      name: 'New Caledonia',
      code: 'NC',
      timezone: 'Central Pacific Standard Time',
      utc: 'UTC+11:00',
      mobileCode: '+687',
    },
    {
      name: 'New Zealand',
      code: 'NZ',
      timezone: 'New Zealand Standard Time',
      utc: 'UTC+12:00',
      mobileCode: '+64',
    },
    {
      name: 'Nicaragua',
      code: 'NI',
      timezone: 'Central America Standard Time',
      utc: 'UTC-06:00',
      mobileCode: '+505',
    },
    {
      name: 'Niger',
      code: 'NE',
      timezone: 'W. Central Africa Standard Time',
      utc: 'UTC+01:00',
      mobileCode: '+227',
    },
    {
      name: 'Nigeria',
      code: 'NG',
      timezone: 'W. Central Africa Standard Time',
      utc: 'UTC+01:00',
      mobileCode: '+234',
    },
    {
      name: 'Niue',
      code: 'NU',
      timezone: 'UTC-11',
      utc: 'UTC-11:00',
      mobileCode: '+683',
    },
    {
      name: 'Norfolk Island',
      code: 'NF',
      timezone: 'Central Pacific Standard Time',
      utc: 'UTC+11:00',
      mobileCode: '+672',
    },
    {
      name: 'North Korea',
      code: 'KP',
      timezone: 'Korea Standard Time',
      utc: 'UTC+09:00',
      mobileCode: '+850',
    },
    {
      name: 'Northern Mariana Islands',
      code: 'MP',
      timezone: 'West Pacific Standard Time',
      utc: 'UTC+10:00',
      mobileCode: '+1-670',
    },
    {
      name: 'Norway',
      code: 'NO',
      timezone: 'W. Europe Standard Time',
      utc: 'UTC+01:00',
      mobileCode: '+47',
    },
    {
      name: 'Oman',
      code: 'OM',
      timezone: 'Arabian Standard Time',
      utc: 'UTC+04:00',
      mobileCode: '+968',
    },
    {
      name: 'Pakistan',
      code: 'PK',
      timezone: 'Pakistan Standard Time',
      utc: 'UTC+05:00',
      mobileCode: '+92',
    },
    {
      name: 'Palau',
      code: 'PW',
      timezone: 'Tokyo Standard Time',
      utc: 'UTC+09:00',
      mobileCode: '+680',
    },
    {
      name: 'Palestinian Authority',
      code: 'PS',
      timezone: 'Egypt Standard Time',
      utc: 'UTC+02:00',
      mobileCode: '+970',
    },
    {
      name: 'Panama',
      code: 'PA',
      timezone: 'SA Pacific Standard Time',
      utc: 'UTC-05:00',
      mobileCode: '+507',
    },
    {
      name: 'Papua New Guinea',
      code: 'PG',
      timezone: 'West Pacific Standard Time',
      utc: 'UTC+10:00',
      mobileCode: '+675',
    },
    {
      name: 'Paraguay',
      code: 'PY',
      timezone: 'Paraguay Standard Time',
      utc: 'UTC-04:00',
      mobileCode: '+595',
    },
    {
      name: 'Peru',
      code: 'PE',
      timezone: 'SA Pacific Standard Time',
      utc: 'UTC-05:00',
      mobileCode: '+51',
    },
    {
      name: 'Philippines',
      code: 'PH',
      timezone: 'Singapore Standard Time',
      utc: 'UTC+08:00',
      mobileCode: '+63',
    },
    {
      name: 'Pitcairn Islands',
      code: 'PN',
      timezone: 'Pacific Standard Time',
      utc: 'UTC-08:00',
      mobileCode: '+870',
    },
    {
      name: 'Poland',
      code: 'PL',
      timezone: 'Central European Standard Time',
      utc: 'UTC+01:00',
      mobileCode: '+48',
    },
    {
      name: 'Portugal',
      code: 'PT',
      timezone: 'GMT Standard Time',
      utc: 'UTC',
      mobileCode: '+351',
    },
    {
      name: 'Puerto Rico',
      code: 'PR',
      timezone: 'SA Western Standard Time',
      utc: 'UTC-04:00',
      mobileCode: '+1-787 and 1-939',
    },
    {
      name: 'Qatar',
      code: 'QA',
      timezone: 'Arab Standard Time',
      utc: 'UTC+03:00',
      mobileCode: '+974',
    },
    {
      name: 'Reunion',
      code: 'RE',
      timezone: 'Mauritius Standard Time',
      utc: 'UTC+04:00',
      mobileCode: '+262',
    },
    {
      name: 'Romania',
      code: 'RO',
      timezone: 'GTB Standard Time',
      utc: 'UTC+02:00',
      mobileCode: '+40',
    },
    {
      name: 'Russia',
      code: 'RU',
      timezone: 'Russian Standard Time',
      utc: 'UTC+03:00',
      mobileCode: '+7',
    },
    {
      name: 'Rwanda',
      code: 'RW',
      timezone: 'South Africa Standard Time',
      utc: 'UTC+02:00',
      mobileCode: '+250',
    },
    {
      name: 'Saint Barthélemy',
      code: 'BL',
      timezone: 'SA Western Standard Time',
      utc: 'UTC-04:00',
      mobileCode: '+590',
    },
    {
      name: 'Saint Helena, Ascension and Tristan da Cunha',
      code: 'SH',
      timezone: 'Greenwich Standard Time',
      utc: 'UTC',
      mobileCode: '+290',
    },
    {
      name: 'Saint Kitts and Nevis',
      code: 'KN',
      timezone: 'SA Western Standard Time',
      utc: 'UTC-04:00',
      mobileCode: '+1-869',
    },
    {
      name: 'Saint Lucia',
      code: 'LC',
      timezone: 'SA Western Standard Time',
      utc: 'UTC-04:00',
      mobileCode: '+1-758',
    },
    {
      name: 'Saint Martin (French part)',
      code: 'MF',
      timezone: 'SA Western Standard Time',
      utc: 'UTC-04:00',
      mobileCode: '+590',
    },
    {
      name: 'Saint Pierre and Miquelon',
      code: 'PM',
      timezone: 'Greenland Standard Time',
      utc: 'UTC-03:00',
      mobileCode: '+508',
    },
    {
      name: 'Saint Vincent and the Grenadines',
      code: 'VC',
      timezone: 'SA Western Standard Time',
      utc: 'UTC-04:00',
      mobileCode: '+1-784',
    },
    {
      name: 'Samoa',
      code: 'WS',
      timezone: 'Samoa Standard Time',
      utc: 'UTC+13:00',
      mobileCode: '+685',
    },
    {
      name: 'San Marino',
      code: 'SM',
      timezone: 'W. Europe Standard Time',
      utc: 'UTC+01:00',
      mobileCode: '+378',
    },
    {
      name: 'São Tomé and Príncipe',
      code: 'ST',
      timezone: 'Greenwich Standard Time',
      utc: 'UTC',
      mobileCode: '+239',
    },
    {
      name: 'Saudi Arabia',
      code: 'SA',
      timezone: 'Arab Standard Time',
      utc: 'UTC+03:00',
      mobileCode: '+966',
    },
    {
      name: 'Senegal',
      code: 'SN',
      timezone: 'Greenwich Standard Time',
      utc: 'UTC',
      mobileCode: '+221',
    },
    {
      name: 'Serbia',
      code: 'RS',
      timezone: 'Central Europe Standard Time',
      utc: 'UTC+01:00',
      mobileCode: '+381',
    },
    {
      name: 'Seychelles',
      code: 'SC',
      timezone: 'Mauritius Standard Time',
      utc: 'UTC+04:00',
      mobileCode: '+248',
    },
    {
      name: 'Sierra Leone',
      code: 'SL',
      timezone: 'Greenwich Standard Time',
      utc: 'UTC',
      mobileCode: '+232',
    },
    {
      name: 'Singapore',
      code: 'SG',
      timezone: 'Singapore Standard Time',
      utc: 'UTC+08:00',
      mobileCode: '+65',
    },
    {
      name: 'Sint Maarten (Dutch part)',
      code: 'SX',
      timezone: 'SA Western Standard Time',
      utc: 'UTC-04:00',
      mobileCode: '+599',
    },
    {
      name: 'Slovakia',
      code: 'SK',
      timezone: 'Central Europe Standard Time',
      utc: 'UTC+01:00',
      mobileCode: '+421',
    },
    {
      name: 'Slovenia',
      code: 'SI',
      timezone: 'Central Europe Standard Time',
      utc: 'UTC+01:00',
      mobileCode: '+386',
    },
    {
      name: 'Solomon Islands',
      code: 'SB',
      timezone: 'Central Pacific Standard Time',
      utc: 'UTC+11:00',
      mobileCode: '+677',
    },
    {
      name: 'Somalia',
      code: 'SO',
      timezone: 'E. Africa Standard Time',
      utc: 'UTC+03:00',
      mobileCode: '+252',
    },
    {
      name: 'South Africa',
      code: 'ZA',
      timezone: 'South Africa Standard Time',
      utc: 'UTC+02:00',
      mobileCode: '+27',
    },
    {
      name: 'South Georgia and the South Sandwich Islands',
      code: 'GS',
      timezone: 'UTC-02',
      utc: 'UTC-02:00',
      mobileCode: '+',
    },
    {
      name: 'South Sudan',
      code: 'SS',
      timezone: 'E. Africa Standard Time',
      utc: 'UTC+03:00',
      mobileCode: '+211',
    },
    {
      name: 'Spain',
      code: 'ES',
      timezone: 'Romance Standard Time',
      utc: 'UTC+01:00',
      mobileCode: '+34',
    },
    {
      name: 'Sri Lanka',
      code: 'LK',
      timezone: 'Sri Lanka Standard Time',
      utc: 'UTC+05:30',
      mobileCode: '+94',
    },
    {
      name: 'Sudan',
      code: 'SD',
      timezone: 'E. Africa Standard Time',
      utc: 'UTC+03:00',
      mobileCode: '+249',
    },
    {
      name: 'Suriname',
      code: 'SR',
      timezone: 'SA Eastern Standard Time',
      utc: 'UTC-03:00',
      mobileCode: '+597',
    },
    {
      name: 'Svalbard',
      code: 'SJ',
      timezone: 'W. Europe Standard Time',
      utc: 'UTC+01:00',
      mobileCode: '+47',
    },
    {
      name: 'Swaziland',
      code: 'SZ',
      timezone: 'South Africa Standard Time',
      utc: 'UTC+02:00',
      mobileCode: '+268',
    },
    {
      name: 'Sweden',
      code: 'SE',
      timezone: 'W. Europe Standard Time',
      utc: 'UTC+01:00',
      mobileCode: '+46',
    },
    {
      name: 'Switzerland',
      code: 'CH',
      timezone: 'W. Europe Standard Time',
      utc: 'UTC+01:00',
      mobileCode: '+41',
    },
    {
      name: 'Syria',
      code: 'SY',
      timezone: 'Syria Standard Time',
      utc: 'UTC+02:00',
      mobileCode: '+963',
    },
    {
      name: 'Taiwan',
      code: 'TW',
      timezone: 'Taipei Standard Time',
      utc: 'UTC+08:00',
      mobileCode: '+886',
    },
    {
      name: 'Tajikistan',
      code: 'TJ',
      timezone: 'West Asia Standard Time',
      utc: 'UTC+05:00',
      mobileCode: '+992',
    },
    {
      name: 'Tanzania',
      code: 'TZ',
      timezone: 'E. Africa Standard Time',
      utc: 'UTC+03:00',
      mobileCode: '+255',
    },
    {
      name: 'Thailand',
      code: 'TH',
      timezone: 'SE Asia Standard Time',
      utc: 'UTC+07:00',
      mobileCode: '+66',
    },
    {
      name: 'Togo',
      code: 'TG',
      timezone: 'Greenwich Standard Time',
      utc: 'UTC',
      mobileCode: '+228',
    },
    {
      name: 'Tokelau',
      code: 'TK',
      timezone: 'Tonga Standard Time',
      utc: 'UTC+13:00',
      mobileCode: '+690',
    },
    {
      name: 'Tonga',
      code: 'TO',
      timezone: 'Tonga Standard Time',
      utc: 'UTC+13:00',
      mobileCode: '+676',
    },
    {
      name: 'Trinidad and Tobago',
      code: 'TT',
      timezone: 'SA Western Standard Time',
      utc: 'UTC-04:00',
      mobileCode: '+1-868',
    },
    {
      name: 'Tunisia',
      code: 'TN',
      timezone: 'W. Central Africa Standard Time',
      utc: 'UTC+01:00',
      mobileCode: '+216',
    },
    {
      name: 'Turkey',
      code: 'TR',
      timezone: 'Turkey Standard Time',
      utc: 'UTC+02:00',
      mobileCode: '+90',
    },
    {
      name: 'Turkmenistan',
      code: 'TM',
      timezone: 'West Asia Standard Time',
      utc: 'UTC+05:00',
      mobileCode: '+993',
    },
    {
      name: 'Turks and Caicos Islands',
      code: 'TC',
      timezone: 'Eastern Standard Time',
      utc: 'UTC-05:00',
      mobileCode: '+1-649',
    },
    {
      name: 'Tuvalu',
      code: 'TV',
      timezone: 'UTC+12',
      utc: 'UTC+12:00',
      mobileCode: '+688',
    },
    {
      name: 'U.S. Minor Outlying Islands',
      code: 'UM',
      timezone: 'UTC-11',
      utc: 'UTC-11:00',
      mobileCode: '+1',
    },
    {
      name: 'Uganda',
      code: 'UG',
      timezone: 'E. Africa Standard Time',
      utc: 'UTC+03:00',
      mobileCode: '+256',
    },
    {
      name: 'Ukraine',
      code: 'UA',
      timezone: 'FLE Standard Time',
      utc: 'UTC+02:00',
      mobileCode: '+380',
    },
    {
      name: 'United Arab Emirates',
      code: 'AE',
      timezone: 'Arabian Standard Time',
      utc: 'UTC+04:00',
      mobileCode: '+971',
    },
    {
      name: 'Uruguay',
      code: 'UY',
      timezone: 'Montevideo Standard Time',
      utc: 'UTC-03:00',
      mobileCode: '+598',
    },
    {
      name: 'Uzbekistan',
      code: 'UZ',
      timezone: 'West Asia Standard Time',
      utc: 'UTC+05:00',
      mobileCode: '+998',
    },
    {
      name: 'Vanuatu',
      code: 'VU',
      timezone: 'Central Pacific Standard Time',
      utc: 'UTC+11:00',
      mobileCode: '+678',
    },
    {
      name: 'Vatican City',
      code: 'VA',
      timezone: 'W. Europe Standard Time',
      utc: 'UTC+01:00',
      mobileCode: '+379',
    },
    {
      name: 'Vietnam',
      code: 'VN',
      timezone: 'SE Asia Standard Time',
      utc: 'UTC+07:00',
      mobileCode: '+84',
    },
    {
      name: 'Virgin Islands, U.S.',
      code: 'VI',
      timezone: 'SA Western Standard Time',
      utc: 'UTC-04:00',
      mobileCode: '+1-340',
    },
    {
      name: 'Virgin Islands, British',
      code: 'VG',
      timezone: 'SA Western Standard Time',
      utc: 'UTC-04:00',
      mobileCode: '+1-284',
    },
    {
      name: 'Wallis and Futuna',
      code: 'WF',
      timezone: 'UTC+12',
      utc: 'UTC+12:00',
      mobileCode: '+681',
    },
    {
      name: 'Yemen',
      code: 'YE',
      timezone: 'Arab Standard Time',
      utc: 'UTC+03:00',
      mobileCode: '+967',
    },
    {
      name: 'Zambia',
      code: 'ZM',
      timezone: 'South Africa Standard Time',
      utc: 'UTC+02:00',
      mobileCode: '+260',
    },
    {
      name: 'Zimbabwe',
      code: 'ZW',
      timezone: 'South Africa Standard Time',
      utc: 'UTC+02:00',
      mobileCode: '+263',
    },
];

//not in use
// https://github.com/sanohin/google-timezones-json/blob/master/timezones.json
export const TIMEZONE_SELECTION = {
    "Pacific/Niue": "(GMT-11:00) Niue",
    "Pacific/Pago_Pago": "(GMT-11:00) Pago Pago",
    "Pacific/Honolulu": "(GMT-10:00) Hawaii Time",
    "Pacific/Rarotonga": "(GMT-10:00) Rarotonga",
    "Pacific/Tahiti": "(GMT-10:00) Tahiti",
    "Pacific/Marquesas": "(GMT-09:30) Marquesas",
    "America/Anchorage": "(GMT-09:00) Alaska Time",
    "Pacific/Gambier": "(GMT-09:00) Gambier",
    "America/Los_Angeles": "(GMT-08:00) Pacific Time",
    "America/Tijuana": "(GMT-08:00) Pacific Time - Tijuana",
    "America/Vancouver": "(GMT-08:00) Pacific Time - Vancouver",
    "America/Whitehorse": "(GMT-08:00) Pacific Time - Whitehorse",
    "Pacific/Pitcairn": "(GMT-08:00) Pitcairn",
    "America/Dawson_Creek": "(GMT-07:00) Mountain Time - Dawson Creek",
    "America/Denver": "(GMT-07:00) Mountain Time",
    "America/Edmonton": "(GMT-07:00) Mountain Time - Edmonton",
    "America/Hermosillo": "(GMT-07:00) Mountain Time - Hermosillo",
    "America/Mazatlan": "(GMT-07:00) Mountain Time - Chihuahua, Mazatlan",
    "America/Phoenix": "(GMT-07:00) Mountain Time - Arizona",
    "America/Yellowknife": "(GMT-07:00) Mountain Time - Yellowknife",
    "America/Belize": "(GMT-06:00) Belize",
    "America/Chicago": "(GMT-06:00) Central Time",
    "America/Costa_Rica": "(GMT-06:00) Costa Rica",
    "America/El_Salvador": "(GMT-06:00) El Salvador",
    "America/Guatemala": "(GMT-06:00) Guatemala",
    "America/Managua": "(GMT-06:00) Managua",
    "America/Mexico_City": "(GMT-06:00) Central Time - Mexico City",
    "America/Regina": "(GMT-06:00) Central Time - Regina",
    "America/Tegucigalpa": "(GMT-06:00) Central Time - Tegucigalpa",
    "America/Winnipeg": "(GMT-06:00) Central Time - Winnipeg",
    "Pacific/Galapagos": "(GMT-06:00) Galapagos",
    "America/Bogota": "(GMT-05:00) Bogota",
    "America/Cancun": "(GMT-05:00) America Cancun",
    "America/Cayman": "(GMT-05:00) Cayman",
    "America/Guayaquil": "(GMT-05:00) Guayaquil",
    "America/Havana": "(GMT-05:00) Havana",
    "America/Iqaluit": "(GMT-05:00) Eastern Time - Iqaluit",
    "America/Jamaica": "(GMT-05:00) Jamaica",
    "America/Lima": "(GMT-05:00) Lima",
    "America/Nassau": "(GMT-05:00) Nassau",
    "America/New_York": "(GMT-05:00) Eastern Time",
    "America/Panama": "(GMT-05:00) Panama",
    "America/Port-au-Prince": "(GMT-05:00) Port-au-Prince",
    "America/Rio_Branco": "(GMT-05:00) Rio Branco",
    "America/Toronto": "(GMT-05:00) Eastern Time - Toronto",
    "Pacific/Easter": "(GMT-05:00) Easter Island",
    "America/Caracas": "(GMT-04:00) Caracas",
    "America/Asuncion": "(GMT-03:00) Asuncion",
    "America/Barbados": "(GMT-04:00) Barbados",
    "America/Boa_Vista": "(GMT-04:00) Boa Vista",
    "America/Campo_Grande": "(GMT-03:00) Campo Grande",
    "America/Cuiaba": "(GMT-03:00) Cuiaba",
    "America/Curacao": "(GMT-04:00) Curacao",
    "America/Grand_Turk": "(GMT-04:00) Grand Turk",
    "America/Guyana": "(GMT-04:00) Guyana",
    "America/Halifax": "(GMT-04:00) Atlantic Time - Halifax",
    "America/La_Paz": "(GMT-04:00) La Paz",
    "America/Manaus": "(GMT-04:00) Manaus",
    "America/Martinique": "(GMT-04:00) Martinique",
    "America/Port_of_Spain": "(GMT-04:00) Port of Spain",
    "America/Porto_Velho": "(GMT-04:00) Porto Velho",
    "America/Puerto_Rico": "(GMT-04:00) Puerto Rico",
    "America/Santo_Domingo": "(GMT-04:00) Santo Domingo",
    "America/Thule": "(GMT-04:00) Thule",
    "Atlantic/Bermuda": "(GMT-04:00) Bermuda",
    "America/St_Johns": "(GMT-03:30) Newfoundland Time - St. Johns",
    "America/Araguaina": "(GMT-03:00) Araguaina",
    "America/Argentina/Buenos_Aires": "(GMT-03:00) Buenos Aires",
    "America/Bahia": "(GMT-03:00) Salvador",
    "America/Belem": "(GMT-03:00) Belem",
    "America/Cayenne": "(GMT-03:00) Cayenne",
    "America/Fortaleza": "(GMT-03:00) Fortaleza",
    "America/Godthab": "(GMT-03:00) Godthab",
    "America/Maceio": "(GMT-03:00) Maceio",
    "America/Miquelon": "(GMT-03:00) Miquelon",
    "America/Montevideo": "(GMT-03:00) Montevideo",
    "America/Paramaribo": "(GMT-03:00) Paramaribo",
    "America/Recife": "(GMT-03:00) Recife",
    "America/Santiago": "(GMT-03:00) Santiago",
    "America/Sao_Paulo": "(GMT-03:00) Sao Paulo",
    "Antarctica/Palmer": "(GMT-03:00) Palmer",
    "Antarctica/Rothera": "(GMT-03:00) Rothera",
    "Atlantic/Stanley": "(GMT-03:00) Stanley",
    "America/Noronha": "(GMT-02:00) Noronha",
    "Atlantic/South_Georgia": "(GMT-02:00) South Georgia",
    "America/Scoresbysund": "(GMT-01:00) Scoresbysund",
    "Atlantic/Azores": "(GMT-01:00) Azores",
    "Atlantic/Cape_Verde": "(GMT-01:00) Cape Verde",
    "Africa/Abidjan": "(GMT+00:00) Abidjan",
    "Africa/Accra": "(GMT+00:00) Accra",
    "Africa/Bissau": "(GMT+00:00) Bissau",
    "Africa/Casablanca": "(GMT+00:00) Casablanca",
    "Africa/El_Aaiun": "(GMT+00:00) El Aaiun",
    "Africa/Monrovia": "(GMT+00:00) Monrovia",
    "America/Danmarkshavn": "(GMT+00:00) Danmarkshavn",
    "Atlantic/Canary": "(GMT+00:00) Canary Islands",
    "Atlantic/Faroe": "(GMT+00:00) Faeroe",
    "Atlantic/Reykjavik": "(GMT+00:00) Reykjavik",
    "Etc/GMT": "(GMT+00:00) GMT (no daylight saving)",
    "Europe/Dublin": "(GMT+00:00) Dublin",
    "Europe/Lisbon": "(GMT+00:00) Lisbon",
    "Europe/London": "(GMT+00:00) London",
    "Africa/Algiers": "(GMT+01:00) Algiers",
    "Africa/Ceuta": "(GMT+01:00) Ceuta",
    "Africa/Lagos": "(GMT+01:00) Lagos",
    "Africa/Ndjamena": "(GMT+01:00) Ndjamena",
    "Africa/Tunis": "(GMT+01:00) Tunis",
    "Africa/Windhoek": "(GMT+02:00) Windhoek",
    "Europe/Amsterdam": "(GMT+01:00) Amsterdam",
    "Europe/Andorra": "(GMT+01:00) Andorra",
    "Europe/Belgrade": "(GMT+01:00) Central European Time - Belgrade",
    "Europe/Berlin": "(GMT+01:00) Berlin",
    "Europe/Brussels": "(GMT+01:00) Brussels",
    "Europe/Budapest": "(GMT+01:00) Budapest",
    "Europe/Copenhagen": "(GMT+01:00) Copenhagen",
    "Europe/Gibraltar": "(GMT+01:00) Gibraltar",
    "Europe/Luxembourg": "(GMT+01:00) Luxembourg",
    "Europe/Madrid": "(GMT+01:00) Madrid",
    "Europe/Malta": "(GMT+01:00) Malta",
    "Europe/Monaco": "(GMT+01:00) Monaco",
    "Europe/Oslo": "(GMT+01:00) Oslo",
    "Europe/Paris": "(GMT+01:00) Paris",
    "Europe/Prague": "(GMT+01:00) Central European Time - Prague",
    "Europe/Rome": "(GMT+01:00) Rome",
    "Europe/Stockholm": "(GMT+01:00) Stockholm",
    "Europe/Tirane": "(GMT+01:00) Tirane",
    "Europe/Vienna": "(GMT+01:00) Vienna",
    "Europe/Warsaw": "(GMT+01:00) Warsaw",
    "Europe/Zurich": "(GMT+01:00) Zurich",
    "Africa/Cairo": "(GMT+02:00) Cairo",
    "Africa/Johannesburg": "(GMT+02:00) Johannesburg",
    "Africa/Maputo": "(GMT+02:00) Maputo",
    "Africa/Tripoli": "(GMT+02:00) Tripoli",
    "Asia/Amman": "(GMT+02:00) Amman",
    "Asia/Beirut": "(GMT+02:00) Beirut",
    "Asia/Damascus": "(GMT+02:00) Damascus",
    "Asia/Gaza": "(GMT+02:00) Gaza",
    "Asia/Jerusalem": "(GMT+02:00) Jerusalem",
    "Asia/Nicosia": "(GMT+02:00) Nicosia",
    "Europe/Athens": "(GMT+02:00) Athens",
    "Europe/Bucharest": "(GMT+02:00) Bucharest",
    "Europe/Chisinau": "(GMT+02:00) Chisinau",
    "Europe/Helsinki": "(GMT+02:00) Helsinki",
    "Europe/Istanbul": "(GMT+03:00) Istanbul",
    "Europe/Kaliningrad": "(GMT+02:00) Moscow-01 - Kaliningrad",
    "Europe/Kyiv": "(GMT+02:00) Kyiv",
    "Europe/Riga": "(GMT+02:00) Riga",
    "Europe/Sofia": "(GMT+02:00) Sofia",
    "Europe/Tallinn": "(GMT+02:00) Tallinn",
    "Europe/Vilnius": "(GMT+02:00) Vilnius",
    "Africa/Khartoum": "(GMT+03:00) Khartoum",
    "Africa/Nairobi": "(GMT+03:00) Nairobi",
    "Antarctica/Syowa": "(GMT+03:00) Syowa",
    "Asia/Baghdad": "(GMT+03:00) Baghdad",
    "Asia/Qatar": "(GMT+03:00) Qatar",
    "Asia/Riyadh": "(GMT+03:00) Riyadh",
    "Europe/Minsk": "(GMT+03:00) Minsk",
    "Europe/Moscow": "(GMT+03:00) Moscow+00 - Moscow",
    "Asia/Tehran": "(GMT+03:30) Tehran",
    "Asia/Baku": "(GMT+04:00) Baku",
    "Asia/Dubai": "(GMT+04:00) Dubai",
    "Asia/Tbilisi": "(GMT+04:00) Tbilisi",
    "Asia/Yerevan": "(GMT+04:00) Yerevan",
    "Europe/Samara": "(GMT+04:00) Moscow+01 - Samara",
    "Indian/Mahe": "(GMT+04:00) Mahe",
    "Indian/Mauritius": "(GMT+04:00) Mauritius",
    "Indian/Reunion": "(GMT+04:00) Reunion",
    "Asia/Kabul": "(GMT+04:30) Kabul",
    "Antarctica/Mawson": "(GMT+05:00) Mawson",
    "Asia/Aqtau": "(GMT+05:00) Aqtau",
    "Asia/Aqtobe": "(GMT+05:00) Aqtobe",
    "Asia/Ashgabat": "(GMT+05:00) Ashgabat",
    "Asia/Dushanbe": "(GMT+05:00) Dushanbe",
    "Asia/Karachi": "(GMT+05:00) Karachi",
    "Asia/Tashkent": "(GMT+05:00) Tashkent",
    "Asia/Yekaterinburg": "(GMT+05:00) Moscow+02 - Yekaterinburg",
    "Indian/Kerguelen": "(GMT+05:00) Kerguelen",
    "Indian/Maldives": "(GMT+05:00) Maldives",
    "Asia/Calcutta": "(GMT+05:30) India Standard Time",
    "Asia/Colombo": "(GMT+05:30) Colombo",
    "Asia/Katmandu": "(GMT+05:45) Katmandu",
    "Antarctica/Vostok": "(GMT+06:00) Vostok",
    "Asia/Almaty": "(GMT+06:00) Almaty",
    "Asia/Bishkek": "(GMT+06:00) Bishkek",
    "Asia/Dhaka": "(GMT+06:00) Dhaka",
    "Asia/Omsk": "(GMT+06:00) Moscow+03 - Omsk, Novosibirsk",
    "Asia/Thimphu": "(GMT+06:00) Thimphu",
    "Indian/Chagos": "(GMT+06:00) Chagos",
    "Asia/Rangoon": "(GMT+06:30) Rangoon",
    "Indian/Cocos": "(GMT+06:30) Cocos",
    "Antarctica/Davis": "(GMT+07:00) Davis",
    "Asia/Bangkok": "(GMT+07:00) Bangkok",
    "Asia/Hovd": "(GMT+07:00) Hovd",
    "Asia/Jakarta": "(GMT+07:00) Jakarta",
    "Asia/Krasnoyarsk": "(GMT+07:00) Moscow+04 - Krasnoyarsk",
    "Asia/Saigon": "(GMT+07:00) Hanoi",
    "Asia/Ho_Chi_Minh": "(GMT+07:00) Ho Chi Minh",
    "Indian/Christmas": "(GMT+07:00) Christmas",
    "Antarctica/Casey": "(GMT+08:00) Casey",
    "Asia/Brunei": "(GMT+08:00) Brunei",
    "Asia/Choibalsan": "(GMT+08:00) Choibalsan",
    "Asia/Hong_Kong": "(GMT+08:00) Hong Kong",
    "Asia/Irkutsk": "(GMT+08:00) Moscow+05 - Irkutsk",
    "Asia/Kuala_Lumpur": "(GMT+08:00) Kuala Lumpur",
    "Asia/Macau": "(GMT+08:00) Macau",
    "Asia/Makassar": "(GMT+08:00) Makassar",
    "Asia/Manila": "(GMT+08:00) Manila",
    "Asia/Shanghai": "(GMT+08:00) China Time - Beijing",
    "Asia/Singapore": "(GMT+08:00) Singapore",
    "Asia/Taipei": "(GMT+08:00) Taipei",
    "Asia/Ulaanbaatar": "(GMT+08:00) Ulaanbaatar",
    "Australia/Perth": "(GMT+08:00) Western Time - Perth",
    "Asia/Pyongyang": "(GMT+08:30) Pyongyang",
    "Asia/Dili": "(GMT+09:00) Dili",
    "Asia/Jayapura": "(GMT+09:00) Jayapura",
    "Asia/Seoul": "(GMT+09:00) Seoul",
    "Asia/Tokyo": "(GMT+09:00) Tokyo",
    "Asia/Yakutsk": "(GMT+09:00) Moscow+06 - Yakutsk",
    "Pacific/Palau": "(GMT+09:00) Palau",
    "Australia/Adelaide": "(GMT+10:30) Central Time - Adelaide",
    "Australia/Darwin": "(GMT+09:30) Central Time - Darwin",
    "Antarctica/DumontDUrville": "(GMT+10:00) Dumont D'Urville",
    "Asia/Magadan": "(GMT+10:00) Moscow+07 - Magadan",
    "Asia/Vladivostok": "(GMT+10:00) Moscow+07 - Vladivostok",
    "Australia/Brisbane": "(GMT+10:00) Eastern Time - Brisbane",
    "Asia/Yuzhno-Sakhalinsk": "(GMT+11:00) Moscow+08 - Yuzhno-Sakhalinsk",
    "Australia/Hobart": "(GMT+11:00) Eastern Time - Hobart",
    "Australia/Sydney": "(GMT+11:00) Eastern Time - Melbourne, Sydney",
    "Pacific/Chuuk": "(GMT+10:00) Truk",
    "Pacific/Guam": "(GMT+10:00) Guam",
    "Pacific/Port_Moresby": "(GMT+10:00) Port Moresby",
    "Pacific/Efate": "(GMT+11:00) Efate",
    "Pacific/Guadalcanal": "(GMT+11:00) Guadalcanal",
    "Pacific/Kosrae": "(GMT+11:00) Kosrae",
    "Pacific/Norfolk": "(GMT+11:00) Norfolk",
    "Pacific/Noumea": "(GMT+11:00) Noumea",
    "Pacific/Pohnpei": "(GMT+11:00) Ponape",
    "Asia/Kamchatka": "(GMT+12:00) Moscow+09 - Petropavlovsk-Kamchatskiy",
    "Pacific/Auckland": "(GMT+13:00) Auckland",
    "Pacific/Fiji": "(GMT+13:00) Fiji",
    "Pacific/Funafuti": "(GMT+12:00) Funafuti",
    "Pacific/Kwajalein": "(GMT+12:00) Kwajalein",
    "Pacific/Majuro": "(GMT+12:00) Majuro",
    "Pacific/Nauru": "(GMT+12:00) Nauru",
    "Pacific/Tarawa": "(GMT+12:00) Tarawa",
    "Pacific/Wake": "(GMT+12:00) Wake",
    "Pacific/Wallis": "(GMT+12:00) Wallis",
    "Pacific/Apia": "(GMT+14:00) Apia",
    "Pacific/Enderbury": "(GMT+13:00) Enderbury",
    "Pacific/Fakaofo": "(GMT+13:00) Fakaofo",
    "Pacific/Tongatapu": "(GMT+13:00) Tongatapu",
    "Pacific/Kiritimati": "(GMT+14:00) Kiritimati"
}




