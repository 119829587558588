import { createRouter, createWebHistory } from 'vue-router'
//import HomeView from '../views/HomeView.vue'

const routes = [

    { 
        path: "/:catchAll(.*)", 
        name: 'error404',
        component: () => import(/* webpackChunkName: "about" */ '@/views/public/404.vue'),
    },
    { 
        path: '/404/', 
        name: '404', 
        component: () => import('@/views/public/404.vue'),
    },
    { 
        path: '/500/', 
        name: '500', 
        component: () => import('@/views/public/500.vue'),
    },

    { 
        path: '/login/', 
        name: 'login', 
        component: () => import('@/views/public/login.vue'),
    },
    { 
        path: '/signup/', 
        name: 'signup', 
        component: () => import('@/views/public/signup.vue'),
    },
    { 
        path: '/forgot-password/', 
        name: 'forgot_password', 
        component: () => import('@/views/public/forgot_password.vue'),
    },
    { 
        path: '/reset-password/', 
        name: 'reset_password', 
        component: () => import('@/views/public/reset_password.vue'),
    },
    { 
        path: '/process-login/', 
        name: 'process_login', 
        component: () => import('@/views/public/process_login.vue'),
    },
    { 
        path: '/oauth/', 
        name: 'oauth_processing', 
        component: () => import('@/views/public/oauth_processing.vue'),
    },
    { 
        path: '/account-setup/', 
        name: 'account-setup', 
        component: () => import('@/views/public/account_setup.vue'),
    },
    {
        path: '/invitation/', 
        name: 'invitation', 
        // components: {
        //     outer: () => import('@/views/public/invitation.vue'),
        // }
        component: () => import('@/views/public/invitation.vue'),
    },
    { 
        path: '/otp/', 
        name: 'otp',
        // components: {
        //     outer: () => import('@/views/public/otp.vue'),
        // }
        component: () => import('@/views/public/otp.vue'),
    },
    { 
        path: '/payments/ls/', 
        name: 'payments_ls',
        component: () => import('@/views/public/payments_ls.vue'),
    },

    //
    // not in use
    { 
        path: '/exlp/:slug/:pslug/', 
        name: 'external_product_page', 
        component: () => import('@/views/public/external_product.vue'),
    },
    // { 
    //     path: '/payments-stripe/', 
    //     name: 'payments_stripe', 
    //     component: () => import('@/views/public/payments_stripe.vue'),
    // },

    //
    // loggedin state only, has child routes
    {
        path: '/',
        component: () => import('@/views/loggedin_area.vue'),
        children: [
            {
                path: '/', 
                name: 'dashboard', 
                components: {
                    inner: () => import('@/views/members/companies_loop.vue'),
                }
            },

            /*
            *
            * non-company pages
            *
            */
            {
                path: '/search/', 
                name: 'search_results', 
                components: {
                    inner: () => import('@/views/members/search_results.vue'),
                }
            },
            {
                path: '/invites/', 
                name: 'invites', 
                components: {
                    inner: () => import('@/views/members/invites.vue'),
                }
            },
            {
                path: '/notifications/', 
                name: 'notifications', 
                components: {
                    inner: () => import('@/views/members/notifications.vue'),
                }
            },
            { 
                path: '/settings/', 
                name: 'settings',
                components: {
                    inner: () => import('@/views/members/settings.vue'),
                }
            },
            { 
                path: '/payments/stripe/', 
                name: 'payments_stripe',
                components: {
                    inner: () => import('@/views/members/payments_stripe.vue'),
                }
            },

            /*
            *
            * about - company information
            *
            */
            { 
                path: '/c/:slug/', 
                name: 'about',
                components: {
                    inner: () => import('@/views/members/about.vue'),
                }
            },

            /*
            *
            * products
            *
            */
            { 
                path: '/c/:slug/products/', 
                name: 'products_loop',
                components: {
                    inner: () => import('@/views/members/products_loop.vue'),
                }
            },
            { 
                path: '/c/:slug/products/add/', 
                name: 'products_add',
                components: {
                    inner: () => import('@/views/members/products_add.vue'),
                }
            },
            { 
                path: '/c/:slug/p/:pslug/', 
                name: 'products_page',
                components: {
                    inner: () => import('@/views/members/products_page.vue'),
                }
            },
            { 
                path: '/c/:slug/p/:pslug/edit/', 
                name: 'products_edit',
                components: {
                    inner: () => import('@/views/members/products_edit.vue'),
                }
            },
            { 
                path: '/c/:slug/products/templates/', 
                name: 'products_templates',
                components: {
                    inner: () => import('@/views/members/products_templates.vue'),
                }
            },

            /*
            *
            * pipeline
            *
            */
            { 
                path: '/c/:slug/pipeline/', 
                name: 'pipeline',
                components: {
                    inner: () => import('@/views/members/pipeline.vue'),
                }
            },

            /*
            *
            * forms
            *
            */
            {
                path: '/c/:slug/forms/', 
                name: 'forms',
                components: {
                    inner: () => import('@/views/members/forms.vue'),
                }
            },
            {
                path: '/c/:slug/forms/templates/', 
                name: 'forms_templates',
                components: {
                    inner: () => import('@/views/members/forms_templates.vue'),
                }
            },
            {
                path: '/c/:slug/forms/add/', 
                name: 'forms_add_regular',
                components: {
                    inner: () => import('@/views/members/forms_add_regular.vue'),
                }
            },
            {
                path: '/c/:slug/forms/add-linked/', 
                name: 'forms_add_linked',
                components: {
                    inner: () => import('@/views/members/forms_add_linked.vue'),
                }
            },
            {
                path: '/c/:slug/fm/:fslug/', 
                name: 'forms_edit',
                components: {
                    inner: () => import('@/views/members/forms_edit_regular.vue'),
                }
            },
            {
                path: '/c/:slug/fm-linked/:fslug/', 
                name: 'forms_edit_linked',
                components: {
                    inner: () => import('@/views/members/forms_edit_linked.vue'),
                }
            },

            /*
            *
            * roles
            *
            */
            { 
                path: '/c/:slug/roles/', 
                name: 'roles',
                components: {
                    inner: () => import('@/views/members/roles.vue'),
                }
            },
            { 
                path: '/c/:slug/roles/add/', 
                name: 'roles_add',
                components: {
                    inner: () => import('@/views/members/roles_add.vue'),
                }
            },
            { 
                path: '/c/:slug/r/:rslug/', 
                name: 'roles_page',
                components: {
                    inner: () => import('@/views/members/roles_page.vue'),
                }
            },
            { 
                path: '/c/:slug/r/:rslug/edit/', 
                name: 'roles_edit',
                components: {
                    inner: () => import('@/views/members/roles_edit.vue'),
                }
            },

            /*
            *
            * notebox
            *
            */
            { 
                path: '/c/:slug/notebox/', 
                name: 'notebox',
                components: {
                    inner: () => import('@/views/members/notebox.vue'),
                }
            },
            { 
                path: '/c/:slug/notebox/add/', 
                name: 'notebox_add',
                components: {
                    inner: () => import('@/views/members/notebox_add.vue'),
                }
            },

            /*
            *
            * inquiries
            *
            */
            { 
                path: '/c/:slug/inquiries/', 
                name: 'inquiries',
                components: {
                    inner: () => import('@/views/members/inquiries_loop.vue'),
                }
            },

            /*
            *
            * consultations
            *
            */
            { 
                path: '/c/:slug/consultations/', 
                name: 'consultations',
                components: {
                    inner: () => import('@/views/members/consultations.vue'),
                }
            },
            { 
                path: '/c/:slug/consultations/add', 
                name: 'consultation_add',
                components: {
                    inner: () => import('@/views/members/consultation_add.vue'),
                }
            },
            { 
                path: '/c/:slug/co/:coslug/', 
                name: 'consultation_page',
                components: {
                    inner: () => import('@/views/members/consultation_page.vue'),
                }
            },
            { 
                path: '/c/:slug/co/:coslug/edit/', 
                name: 'consultation_edit',
                components: {
                    inner: () => import('@/views/members/consultation_edit.vue'),
                }
            },
            { 
                path: '/c/:slug/co/:coslug/edit-schedule/', 
                name: 'consultation_edit_schedule',
                components: {
                    inner: () => import('@/views/members/consultation_edit_schedule.vue'),
                }
            },
            { 
                path: '/c/:slug/co/:coslug/edit-details/', 
                name: 'consultation_edit_details',
                components: {
                    inner: () => import('@/views/members/consultation_edit_details.vue'),
                }
            },

            /*
            *
            * assignments
            *
            */
            { 
                path: '/c/:slug/assignments/', 
                name: 'assignments',
                components: {
                    inner: () => import('@/views/members/assignments.vue'),
                }
            },
            { 
                path: '/c/:slug/a/:aslug/', 
                name: 'assignments_page',
                components: {
                    inner: () => import('@/views/members/assignments_page.vue'),
                }
            },
            { 
                path: '/c/:slug/assignments/settings/', 
                name: 'assignments_settings',
                components: {
                    inner: () => import('@/views/members/assignments_settings.vue'),
                }
            },
            { 
                path: '/c/:slug/assignments/add/', 
                name: 'assignments_add',
                components: {
                    inner: () => import('@/views/members/assignments_add.vue'),
                }
            },

            /*
            *
            * clients
            *
            */
            { 
                path: '/c/:slug/clients/', 
                name: 'clients',
                components: {
                    inner: () => import('@/views/members/clients.vue'),
                }
            },
            { 
                path: '/c/:slug/clients/settings/', 
                name: 'clients_settings',
                components: {
                    inner: () => import('@/views/members/clients_settings.vue'),
                }
            },
            { 
                path: '/c/:slug/clients/add/', 
                name: 'clients_add',
                components: {
                    inner: () => import('@/views/members/clients_add.vue'),
                }
            },
            { 
                path: '/c/:slug/cl/:cslug/', 
                name: 'clients_page',
                components: {
                    inner: () => import('@/views/members/clients_page.vue'),
                }
            },

            /*
            *
            * reviews
            *
            */
            { 
                path: '/c/:slug/reviews/', 
                name: 'reviews',
                components: {
                    inner: () => import('@/views/members/reviews.vue'),
                }
            },
            { 
                path: '/c/:slug/re/:reslug/', 
                name: 'reviews_page',
                components: {
                    inner: () => import('@/views/members/reviews_page.vue'),
                }
            },
            { 
                path: '/c/:slug/reviews/add/', 
                name: 'reviews_add',
                components: {
                    inner: () => import('@/views/members/reviews_add.vue'),
                }
            },

            /*
            *
            * tickets
            *
            */
            { 
                path: '/c/:slug/tickets/', 
                name: 'tickets',
                components: {
                    inner: () => import('@/views/members/tickets.vue'),
                }
            },
            { 
                path: '/c/:slug/tk/:tcslug/', 
                name: 'tickets_page',
                components: {
                    inner: () => import('@/views/members/tickets_page.vue'),
                }
            },
            { 
                path: '/c/:slug/tk/:tcslug/edit/', 
                name: 'ticket_edit',
                components: {
                    inner: () => import('@/views/members/tickets_edit.vue'),
                }
            },
            { 
                path: '/c/:slug/tickets/add/', 
                name: 'tickets_add',
                components: {
                    inner: () => import('@/views/members/tickets_add.vue'),
                }
            },

            /*
            *
            * handbooks
            *
            */
            { 
                path: '/c/:slug/handbooks/', 
                name: 'handbooks',
                components: {
                    inner: () => import('@/views/members/handbooks.vue'),
                }
            },
            { 
                path: '/c/:slug/handbooks/add/', 
                name: 'handbooks_add',
                components: {
                    inner: () => import('@/views/members/handbooks_add.vue'),
                }
            },
            { 
                path: '/c/:slug/h/:hslug/edit/', 
                name: 'handbooks_edit',
                components: {
                    inner: () => import('@/views/members/handbooks_edit.vue'),
                }
            },
            { 
                path: '/c/:slug/h/:hslug/', 
                name: 'handbooks_page',
                components: {
                    inner: () => import('@/views/members/handbooks_page.vue'),
                }
            },
            { 
                path: '/c/:slug/handbooks/templates/', 
                name: 'handbooks_templates',
                components: {
                    inner: () => import('@/views/members/handbooks_templates.vue'),
                }
            },
            { 
                path: '/c/:slug/h/:hslug/add-page/', 
                name: 'handbookpage_add',
                components: {
                    inner: () => import('@/views/members/handbookpage_add.vue'),
                }
            },
            { 
                path: '/c/:slug/h/:hslug/p/:pslug/', 
                name: 'handbookpage_page',
                components: {
                    inner: () => import('@/views/members/handbookpage_page.vue'),
                }
            },
            { 
                path: '/c/:slug/h/:hslug/p/:pslug/edit/', 
                name: 'handbookpage_edit',
                components: {
                    inner: () => import('@/views/members/handbookpage_edit.vue'),
                }
            },

            /*
            *
            * personnel
            *
            */
            { 
                path: '/c/:slug/personnel/', 
                name: 'personnel',
                components: {
                    inner: () => import('@/views/members/personnel.vue'),
                }
            },
            { 
                path: '/c/:slug/personnel/settings/', 
                name: 'personnel_settings',
                components: {
                    inner: () => import('@/views/members/personnel_settings.vue'),
                }
            },
            { 
                path: '/c/:slug/personnel/add/', 
                name: 'personnel_add',
                components: {
                    inner: () => import('@/views/members/personnel_add.vue'),
                }
            },
            { 
                path: '/c/:slug/pe/:pslug/', 
                name: 'personnel_page',
                components: {
                    inner: () => import('@/views/members/personnel_page.vue'),
                }
            },

            /*
            *
            * announcements
            *
            */
            { 
                path: '/c/:slug/announcements/', 
                name: 'announcements',
                components: {
                    inner: () => import('@/views/members/announcements.vue'),
                }
            },
            { 
                path: '/c/:slug/announcements/add/', 
                name: 'announcements_add',
                components: {
                    inner: () => import('@/views/members/announcements_add.vue'),
                }
            },
            { 
                path: '/c/:slug/an/:aslug/', 
                name: 'announcements_page',
                components: {
                    inner: () => import('@/views/members/announcements_page.vue'),
                }
            },
            { 
                path: '/c/:slug/an/:aslug/edit/', 
                name: 'announcements_edit',
                components: {
                    inner: () => import('@/views/members/announcements_edit.vue'),
                }
            },

            /*
            *
            * leaves
            *
            */
            { 
                path: '/c/:slug/leaves/', 
                name: 'leaves',
                components: {
                    inner: () => import('@/views/members/leaves.vue'),
                }
            },
            { 
                path: '/c/:slug/leave/add/', 
                name: 'leaves_add',
                components: {
                    inner: () => import('@/views/members/leaves_add.vue'),
                }
            },
            { 
                path: '/c/:slug/l/:lslug/', 
                name: 'leaves_page',
                components: {
                    inner: () => import('@/views/members/leaves_page.vue'),
                }
            },

            /*
            *
            * memos
            *
            */
            { 
                path: '/c/:slug/memos/', 
                name: 'memos',
                components: {
                    inner: () => import('@/views/members/memos.vue'),
                }
            },
            { 
                path: '/c/:slug/memos/add/', 
                name: 'memos_add',
                components: {
                    inner: () => import('@/views/members/memos_add.vue'),
                }
            },
            { 
                path: '/c/:slug/m/:mslug/', 
                name: 'memos_page',
                components: {
                    inner: () => import('@/views/members/memos_page.vue'),
                }
            },
            { 
                path: '/c/:slug/m/:mslug/edit/', 
                name: 'memos_edit',
                components: {
                    inner: () => import('@/views/members/memos_edit.vue'),
                }
            },

            /*
            *
            * help center - not in use anymore
            *
            */
            { 
                path: '/help-center/tutorials/', 
                name: 'tutorials',
                components: {
                    inner: () => import('@/views/members/tutorials.vue'),
                }
            },
            { 
                path: '/help-center/tut/:slug/', 
                name: 'tutorials_page',
                components: {
                    inner: () => import('@/views/members/tutorials_page.vue'),
                }
            },

        ],
    },


    //
    // mobile routes -- logggedin state only, has child routes
    {
        path: '/m/',
        component: () => import('@/views/loggedin_area_mobile.vue'),
        children: [

            {
                path: '/m/menu/', 
                name: 'dashboard_mobile', 
                components: {
                    inner: () => import('@/views/members/dashboard_mobile.vue'),
                }
            },

            {
                path: '/m/companies/', 
                name: 'companies_loop_mobile', 
                components: {
                    inner: () => import('@/views/members/companies_loop_mobile.vue'),
                }
            },

            {
                path: '/m/notifications/', 
                name: 'notifications_mobile', 
                components: {
                    inner: () => import('@/views/members/notifications_mobile.vue'),
                }
            },
            {
                path: '/m/invites/', 
                name: 'invites_mobile', 
                components: {
                    inner: () => import('@/views/members/invites_mobile.vue'),
                }
            },
            { 
                path: '/m/profile/', 
                name: 'profile_mobile',
                components: {
                    inner: () => import('@/views/members/profile_mobile.vue'),
                }
            },
            { 
                path: '/m/security/', 
                name: 'security_mobile',
                components: {
                    inner: () => import('@/views/members/security_mobile.vue'),
                }
            },
            { 
                path: '/m/payments/', 
                name: 'payments_mobile',
                components: {
                    inner: () => import('@/views/members/payments_mobile.vue'),
                }
            },
            

            /*
            *
            * about - company information
            *
            */
            {
                path: '/m/c/:slug/', 
                name: 'about_mobile', 
                components: {
                    inner: () => import('@/views/members/about_mobile.vue'),
                }
            },

            /*
            *
            * products
            *
            */
            {
                path: '/m/c/:slug/products/', 
                name: 'products_mobile', 
                components: {
                    inner: () => import('@/views/members/products_mobile.vue'),
                }
            },
            {
                path: '/m/c/:slug/products/add/', 
                name: 'products_add_mobile', 
                components: {
                    inner: () => import('@/views/members/products_add_mobile.vue'),
                }
            },
            {
                path: '/m/c/:slug/p/:pslug/', 
                name: 'products_page_mobile', 
                components: {
                    inner: () => import('@/views/members/products_page_mobile.vue'),
                }
            },
            {
                path: '/m/c/:slug/p/:pslug/edit', 
                name: 'products_edit_mobile', 
                components: {
                    inner: () => import('@/views/members/products_edit_mobile.vue'),
                }
            },
            
            { 
                path: '/m/c/:slug/products/templates/', 
                name: 'products_templates_mobile',
                components: {
                    inner: () => import('@/views/members/products_templates_mobile.vue'),
                }
            },

            /*
            *
            * forms
            *
            */
            {
                path: '/m/c/:slug/forms/', 
                name: 'forms_mobile',
                components: {
                    inner: () => import('@/views/members/forms_mobile.vue'),
                }
            },

            /*
            *
            * pipeline
            *
            */
            {
                path: '/m/c/:slug/pipeline/', 
                name: 'pipeline_loop_mobile', 
                components: {
                    inner: () => import('@/views/members/pipeline_loop_mobile.vue'),
                }
            },

            /*
            *
            * roles
            *
            */
            {
                path: '/m/c/:slug/roles/', 
                name: 'roles_mobile', 
                components: {
                    inner: () => import('@/views/members/roles_mobile.vue'),
                }
            },
            {
                path: '/m/c/:slug/roles/add/', 
                name: 'roles_add_mobile', 
                components: {
                    inner: () => import('@/views/members/roles_add_mobile.vue'),
                }
            },
            { 
                path: '/m/c/:slug/r/:rslug/', 
                name: 'roles_page_mobile',
                components: {
                    inner: () => import('@/views/members/roles_page_mobile.vue'),
                }
            },
            { 
                path: '/m/c/:slug/r/:rslug/edit/', 
                name: 'roles_edit_mobile',
                components: {
                    inner: () => import('@/views/members/roles_edit_mobile.vue'),
                }
            },

            /*
            *
            * notebox
            *
            */
            { 
                path: '/m/c/:slug/notebox/', 
                name: 'notebox_mobile',
                components: {
                    inner: () => import('@/views/members/notebox_mobile.vue'),
                }
            },
            { 
                path: '/m/c/:slug/notebox/add/', 
                name: 'notebox_add_mobile',
                components: {
                    inner: () => import('@/views/members/notebox_add_mobile.vue'),
                }
            },

            /*
            *
            * integrations
            *
            */
            { 
                path: '/m/c/:slug/integrations/', 
                name: 'integrations_mobile',
                components: {
                    inner: () => import('@/views/members/integration_mobile.vue'),
                }
            },

            /*
            *
            * api
            *
            */
            { 
                path: '/m/c/:slug/api/', 
                name: 'api_mobile',
                components: {
                    inner: () => import('@/views/members/api_mobile.vue'),
                }
            },

            /*
            *
            * inquiries
            *
            */
            { 
                path: '/m/c/:slug/inquiries/', 
                name: 'inquiries_loop_mobile',
                components: {
                    inner: () => import('@/views/members/inquiries_loop_mobile.vue'),
                }
            },

            /*
            *
            * consultations
            *
            */
            { 
                path: '/m/c/:slug/consultations/', 
                name: 'consultations_mobile',
                components: {
                    inner: () => import('@/views/members/consultations_mobile.vue'),
                }
            },
            { 
                path: '/m/c/:slug/consultations/add/', 
                name: 'consultation_add_mobile',
                components: {
                    inner: () => import('@/views/members/consultation_add_mobile.vue'),
                }
            },
            { 
                path: '/m/c/:slug/co/:coslug/', 
                name: 'consultation_page_mobile',
                components: {
                    inner: () => import('@/views/members/consultation_page_mobile.vue'),
                }
            },
            { 
                path: '/m/c/:slug/co/:coslug/edit/', 
                name: 'consultation_edit_mobile',
                components: {
                    inner: () => import('@/views/members/consultation_edit_mobile.vue'),
                }
            },
            { 
                path: '/m/c/:slug/co/:coslug/edit-schedule/', 
                name: 'consultation_edit_schedule_mobile',
                components: {
                    inner: () => import('@/views/members/consultation_edit_schedule_mobile.vue'),
                }
            },
            { 
                path: '/m/c/:slug/co/:coslug/edit-details/', 
                name: 'consultation_edit_details_mobile',
                components: {
                    inner: () => import('@/views/members/consultation_edit_details_mobile.vue'),
                }
            },

            /*
            *
            * assignments
            *
            */
            { 
                path: '/m/c/:slug/assignments/', 
                name: 'assignments_mobile',
                components: {
                    inner: () => import('@/views/members/assignments_mobile.vue'),
                }
            },
            { 
                path: '/m/c/:slug/a/:aslug/', 
                name: 'assignments_page_mobile',
                components: {
                    inner: () => import('@/views/members/assignments_page_mobile.vue'),
                }
            },
            { 
                path: '/m/c/:slug/assignments/add/', 
                name: 'assignments_add_mobile',
                components: {
                    inner: () => import('@/views/members/assignments_add_mobile.vue'),
                }
            },
            { 
                path: '/m/c/:slug/a/:aslug/edit/', 
                name: 'assignments_edit_mobile',
                components: {
                    inner: () => import('@/views/members/assignments_edit_mobile.vue'),
                }
            },
            { 
                path: '/m/c/:slug/assignments/settings/', 
                name: 'assignments_settings_mobile',
                components: {
                    inner: () => import('@/views/members/assignments_settings_mobile.vue'),
                }
            },

            /*
            *
            * clients
            *
            */
            { 
                path: '/m/c/:slug/clients/', 
                name: 'clients_mobile',
                components: {
                    inner: () => import('@/views/members/clients_mobile.vue'),
                }
            },
            { 
                path: '/m/c/:slug/clients/settings/', 
                name: 'clients_settings_mobile',
                components: {
                    inner: () => import('@/views/members/clients_settings_mobile.vue'),
                }
            },
            { 
                path: '/m/c/:slug/clients/add/', 
                name: 'clients_add_mobile',
                components: {
                    inner: () => import('@/views/members/clients_add_mobile.vue'),
                }
            },
            { 
                path: '/m/c/:slug/clients/invite/', 
                name: 'clients_invite_mobile',
                components: {
                    inner: () => import('@/views/members/clients_invite_mobile.vue'),
                }
            },
            { 
                path: '/m/c/:slug/cl/:cslug/', 
                name: 'clients_page_mobile',
                components: {
                    inner: () => import('@/views/members/clients_page_mobile.vue'),
                }
            },
            { 
                path: '/m/c/:slug/cl/:cslug/edit/', 
                name: 'clients_edit_mobile',
                components: {
                    inner: () => import('@/views/members/clients_edit_mobile.vue'),
                }
            },

            /*
            *
            * reviews
            *
            */
            { 
                path: '/m/c/:slug/reviews/', 
                name: 'reviews_mobile',
                components: {
                    inner: () => import('@/views/members/reviews_mobile.vue'),
                }
            },
            { 
                path: '/m/c/:slug/re/:reslug/', 
                name: 'reviews_page_mobile',
                components: {
                    inner: () => import('@/views/members/reviews_page_mobile.vue'),
                }
            },
            { 
                path: '/m/c/:slug/reviews/add/', 
                name: 'reviews_add_mobile',
                components: {
                    inner: () => import('@/views/members/reviews_add_mobile.vue'),
                }
            },

            /*
            *
            * tickets
            *
            */
            { 
                path: '/m/c/:slug/tickets/', 
                name: 'tickets_mobile',
                components: {
                    inner: () => import('@/views/members/tickets_mobile.vue'),
                }
            },
            { 
                path: '/m/c/:slug/tk/:tcslug/', 
                name: 'tickets_page_mobile',
                components: {
                    inner: () => import('@/views/members/tickets_page_mobile.vue'),
                }
            },
            { 
                path: '/m/c/:slug/tk/:tcslug/edit/', 
                name: 'tickets_edit_mobile',
                components: {
                    inner: () => import('@/views/members/tickets_edit_mobile.vue'),
                }
            },
            { 
                path: '/m/c/:slug/tickets/add/', 
                name: 'tickets_add_mobile',
                components: {
                    inner: () => import('@/views/members/tickets_add_mobile.vue'),
                }
            },

            /*
            *
            * handbooks
            *
            */
            { 
                path: '/m/c/:slug/handbooks/', 
                name: 'handbooks_mobile',
                components: {
                    inner: () => import('@/views/members/handbooks_mobile.vue'),
                }
            },
            { 
                path: '/m/c/:slug/handbooks/add/', 
                name: 'handbooks_add_mobile',
                components: {
                    inner: () => import('@/views/members/handbooks_add_mobile.vue'),
                }
            },
            { 
                path: '/m/c/:slug/h/:hslug/edit/', 
                name: 'handbooks_edit_mobile',
                components: {
                    inner: () => import('@/views/members/handbooks_edit_mobile.vue'),
                }
            },
            { 
                path: '/m/c/:slug/h/:hslug/', 
                name: 'handbooks_page_mobile',
                components: {
                    inner: () => import('@/views/members/handbooks_page_mobile.vue'),
                }
            },
            { 
                path: '/m/c/:slug/handbooks/templates/', 
                name: 'handbooks_templates_mobile',
                components: {
                    inner: () => import('@/views/members/handbooks_templates_mobile.vue'),
                }
            },
            { 
                path: '/m/c/:slug/h/:hslug/add-page/', 
                name: 'handbookpage_add_mobile',
                components: {
                    inner: () => import('@/views/members/handbookpage_add_mobile.vue'),
                }
            },
            { 
                path: '/m/c/:slug/h/:hslug/p/:pslug/', 
                name: 'handbookpage_page_mobile',
                components: {
                    inner: () => import('@/views/members/handbookpage_page_mobile.vue'),
                }
            },
            { 
                path: '/m/c/:slug/h/:hslug/p/:pslug/edit/', 
                name: 'handbookpage_edit_mobile',
                components: {
                    inner: () => import('@/views/members/handbookpage_edit_mobile.vue'),
                }
            },

            /*
            *
            * personnel
            *
            */
            { 
                path: '/m/c/:slug/personnel/', 
                name: 'personnel_mobile',
                components: {
                    inner: () => import('@/views/members/personnel_mobile.vue'),
                }
            },
            { 
                path: '/m/c/:slug/personnel/settings/', 
                name: 'personnel_settings_mobile',
                components: {
                    inner: () => import('@/views/members/personnel_settings_mobile.vue'),
                }
            },
            { 
                path: '/m/c/:slug/personnel/invite/', 
                name: 'personnel_invite_mobile',
                components: {
                    inner: () => import('@/views/members/personnel_invite_mobile.vue'),
                }
            },
            { 
                path: '/m/c/:slug/personnel/add/', 
                name: 'personnel_add_mobile',
                components: {
                    inner: () => import('@/views/members/personnel_add_mobile.vue'),
                }
            },
            { 
                path: '/m/c/:slug/pe/:pslug/', 
                name: 'personnel_page_mobile',
                components: {
                    inner: () => import('@/views/members/personnel_page_mobile.vue'),
                }
            },
            { 
                path: '/m/c/:slug/pe/:pslug/edit/', 
                name: 'personnel_edit_mobile',
                components: {
                    inner: () => import('@/views/members/personnel_edit_mobile.vue'),
                }
            },

            /*
            *
            * leaves
            *
            */
            { 
                path: '/m/c/:slug/leaves/', 
                name: 'leaves_mobile',
                components: {
                    inner: () => import('@/views/members/leaves_mobile.vue'),
                }
            },
            { 
                path: '/m/c/:slug/leave/add/', 
                name: 'leaves_add_mobile',
                components: {
                    inner: () => import('@/views/members/leaves_add_mobile.vue'),
                }
            },
            { 
                path: '/m/c/:slug/l/:lslug/', 
                name: 'leaves_page_mobile',
                components: {
                    inner: () => import('@/views/members/leaves_page_mobile.vue'),
                }
            },

            /*
            *
            * memos
            *
            */
            { 
                path: '/m/c/:slug/memos/', 
                name: 'memos_mobile',
                components: {
                    inner: () => import('@/views/members/memos_mobile.vue'),
                }
            },
            { 
                path: '/m/c/:slug/memos/add/', 
                name: 'memos_add_mobile',
                components: {
                    inner: () => import('@/views/members/memos_add_mobile.vue'),
                }
            },
            { 
                path: '/m/c/:slug/m/:mslug/', 
                name: 'memos_page_mobile',
                components: {
                    inner: () => import('@/views/members/memos_page_mobile.vue'),
                }
            },
            { 
                path: '/m/c/:slug/m/:mslug/edit/', 
                name: 'memos_edit_mobile',
                components: {
                    inner: () => import('@/views/members/memos_edit_mobile.vue'),
                }
            },

            /*
            *
            * announcements
            *
            */
            { 
                path: '/m/c/:slug/announcements/', 
                name: 'announcements_mobile',
                components: {
                    inner: () => import('@/views/members/announcements_mobile.vue'),
                }
            },
            { 
                path: '/m/c/:slug/announcements/add/', 
                name: 'announcements_add_mobile',
                components: {
                    inner: () => import('@/views/members/announcements_add_mobile.vue'),
                }
            },
            { 
                path: '/m/c/:slug/an/:aslug/', 
                name: 'announcements_page_mobile',
                components: {
                    inner: () => import('@/views/members/announcements_page_mobile.vue'),
                }
            },
            { 
                path: '/m/c/:slug/an/:aslug/edit/', 
                name: 'announcements_edit_mobile',
                components: {
                    inner: () => import('@/views/members/announcements_edit_mobile.vue'),
                }
            },

        ],
    },


]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
