

import * as redeemer from './redeemer'
import moment from 'moment'

/*
*
* global independent functions, can function on their own
* only functions should be place here on forge.js
*
*
*/
let DBG = false
const debug_var = function(dbg,var1,var2){
    if(dbg==true){ return var1 } else { return var2 }
}

//
//
export const CONSTANTS = {
    'DEBUG_MODE': DBG,
    'APP_NAME': 'Hierarch.io',

    //
    //
    'DOMAIN': debug_var(
        DBG,
        'http://localhost:8081',
        'https://app.hierarch.io'
    ),
    'DOMAIN_API': debug_var(
        DBG,
        'http://127.0.0.1:8000',
        'https://api.hierarch.io'
    ),
    'DOMAIN_LP': debug_var(
        DBG,
        'http://localhost:8082',
        'https://lp.hierarch.io'
    ),
    'Q_FORMAT': 'format=json',

    //
    //
    'COOKIE_NAMES': {
        
        //
        //
        'xdevice_id': 'hierarch_jo99XIGWGigtcCbic3TXqu',

        //
        //
        'session': 'hierarch_cseNSlWdHTNWIbPLB0hgaW',
        'key': 'hierarch_yO1Q961n0SKJP9DxmpFX',
        'darkmode': 'hierarch_ohK2h4b8xGSQlu3UQwKb',

        //
        //
        'localstorage': 'hierarch_paIroGa6KoCYmmO2GU5O',
        
    },

    //
    //
    'GOOGLE_AUTH_LINK': 'https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=' + debug_var(DBG,'http://localhost:8081','https://app.hierarch.io') + '/process-login/&prompt=consent&response_type=code&client_id=141572530537-siv4hffdu8tjmb44v65tuansacovu3t6.apps.googleusercontent.com&scope=openid%20email%20profile&access_type=offline',
    
    //
    //
    'TEXT_EDITOR_MODE': 'tinymce', // tinymce / textarea / summernote
    'TINYMCE_INIT':{
        menubar:false,                       
        toolbar_mode: 'wrap',
        toolbar: 'forecolor backcolor | h1 h2 h3 |  fontfamily fontsize | indent outdent | bold italic blockquote strikethrough numlist bullist | alignleft aligncenter alignright alignjustify alignnone | link image table | code',

        plugins: 'lists link image table code help wordcount',
        
        //
        //
        skin: 'oxide-dark',
        content_css: 'dark',

        //
        //
        typography_default_lang: 'en-US',
        typography_langs: [
            'en-US',
            'es',
            'de'
        ],

    },

    //
    //
    'PAYMENT_PROVIDER': 'lemonsqueezy',

    //
    //
    'MAP_PROVIDER': 'google',
    
    //
    //
    'CALENDLY_TIMEZONES': [
        'Africa/Abidjan',
        'Africa/Accra',
        'Africa/Addis_Ababa',
        'Africa/Algiers',
        'Africa/Asmara',
        'Africa/Asmera',
        'Africa/Bamako',
        'Africa/Bangui',
        'Africa/Banjul',
        'Africa/Bissau',
        'Africa/Blantyre',
        'Africa/Brazzaville',
        'Africa/Bujumbura',
        'Africa/Cairo',
        'Africa/Casablanca',
        'Africa/Ceuta',
        'Africa/Conakry',
        'Africa/Dakar',
        'Africa/Dar_es_Salaam',
        'Africa/Djibouti',
        'Africa/Douala',
        'Africa/El_Aaiun',
        'Africa/Freetown',
        'Africa/Gaborone',
        'Africa/Harare',
        'Africa/Johannesburg',
        'Africa/Juba',
        'Africa/Kampala',
        'Africa/Khartoum',
        'Africa/Kigali',
        'Africa/Kinshasa',
        'Africa/Lagos',
        'Africa/Libreville',
        'Africa/Lome',
        'Africa/Luanda',
        'Africa/Lubumbashi',
        'Africa/Lusaka',
        'Africa/Malabo',
        'Africa/Maputo',
        'Africa/Maseru',
        'Africa/Mbabane',
        'Africa/Mogadishu',
        'Africa/Monrovia',
        'Africa/Nairobi',
        'Africa/Ndjamena',
        'Africa/Niamey',
        'Africa/Nouakchott',
        'Africa/Ouagadougou',
        'Africa/Porto-Novo',
        'Africa/Sao_Tome',
        'Africa/Timbuktu',
        'Africa/Tripoli',
        'Africa/Tunis',
        'Africa/Windhoek',
        'America/Adak',
        'America/Anchorage',
        'America/Anguilla',
        'America/Antigua',
        'America/Araguaina',
        'America/Argentina/Buenos_Aires',
        'America/Argentina/Catamarca',
        'America/Argentina/ComodRivadavia',
        'America/Argentina/Cordoba',
        'America/Argentina/Jujuy',
        'America/Argentina/La_Rioja',
        'America/Argentina/Mendoza',
        'America/Argentina/Rio_Gallegos',
        'America/Argentina/Salta',
        'America/Argentina/San_Juan',
        'America/Argentina/San_Luis',
        'America/Argentina/Tucuman',
        'America/Argentina/Ushuaia',
        'America/Aruba',
        'America/Asuncion',
        'America/Atikokan',
        'America/Atka',
        'America/Bahia',
        'America/Bahia_Banderas',
        'America/Barbados',
        'America/Belem',
        'America/Belize',
        'America/Blanc-Sablon',
        'America/Boa_Vista',
        'America/Bogota',
        'America/Boise',
        'America/Buenos_Aires',
        'America/Cambridge_Bay',
        'America/Campo_Grande',
        'America/Cancun',
        'America/Caracas',
        'America/Catamarca',
        'America/Cayenne',
        'America/Cayman',
        'America/Chicago',
        'America/Chihuahua',
        'America/Ciudad_Juarez',
        'America/Coral_Harbour',
        'America/Cordoba',
        'America/Costa_Rica',
        'America/Creston',
        'America/Cuiaba',
        'America/Curacao',
        'America/Danmarkshavn',
        'America/Dawson',
        'America/Dawson_Creek',
        'America/Denver',
        'America/Detroit',
        'America/Dominica',
        'America/Edmonton',
        'America/Eirunepe',
        'America/El_Salvador',
        'America/Ensenada',
        'America/Fort_Nelson',
        'America/Fort_Wayne',
        'America/Fortaleza',
        'America/Glace_Bay',
        'America/Godthab',
        'America/Goose_Bay',
        'America/Grand_Turk',
        'America/Grenada',
        'America/Guadeloupe',
        'America/Guatemala',
        'America/Guayaquil',
        'America/Guyana',
        'America/Halifax',
        'America/Havana',
        'America/Hermosillo',
        'America/Indiana/Indianapolis',
        'America/Indiana/Knox',
        'America/Indiana/Marengo',
        'America/Indiana/Petersburg',
        'America/Indiana/Tell_City',
        'America/Indiana/Vevay',
        'America/Indiana/Vincennes',
        'America/Indiana/Winamac',
        'America/Indianapolis',
        'America/Inuvik',
        'America/Iqaluit',
        'America/Jamaica',
        'America/Jujuy',
        'America/Juneau',
        'America/Kentucky/Louisville',
        'America/Kentucky/Monticello',
        'America/Knox_IN',
        'America/Kralendijk',
        'America/La_Paz',
        'America/Lima',
        'America/Los_Angeles',
        'America/Louisville',
        'America/Lower_Princes',
        'America/Maceio',
        'America/Managua',
        'America/Manaus',
        'America/Marigot',
        'America/Martinique',
        'America/Matamoros',
        'America/Mazatlan',
        'America/Mendoza',
        'America/Menominee',
        'America/Merida',
        'America/Metlakatla',
        'America/Mexico_City',
        'America/Miquelon',
        'America/Moncton',
        'America/Monterrey',
        'America/Montevideo',
        'America/Montreal',
        'America/Montserrat',
        'America/Nassau',
        'America/New_York',
        'America/Nipigon',
        'America/Nome',
        'America/Noronha',
        'America/North_Dakota/Beulah',
        'America/North_Dakota/Center',
        'America/North_Dakota/New_Salem',
        'America/Nuuk',
        'America/Ojinaga',
        'America/Panama',
        'America/Pangnirtung',
        'America/Paramaribo',
        'America/Phoenix',
        'America/Port-au-Prince',
        'America/Port_of_Spain',
        'America/Porto_Acre',
        'America/Porto_Velho',
        'America/Puerto_Rico',
        'America/Punta_Arenas',
        'America/Rainy_River',
        'America/Rankin_Inlet',
        'America/Recife',
        'America/Regina',
        'America/Resolute',
        'America/Rio_Branco',
        'America/Rosario',
        'America/Santa_Isabel',
        'America/Santarem',
        'America/Santiago',
        'America/Santo_Domingo',
        'America/Sao_Paulo',
        'America/Scoresbysund',
        'America/Shiprock',
        'America/Sitka',
        'America/St_Barthelemy',
        'America/St_Johns',
        'America/St_Kitts',
        'America/St_Lucia',
        'America/St_Thomas',
        'America/St_Vincent',
        'America/Swift_Current',
        'America/Tegucigalpa',
        'America/Thule',
        'America/Thunder_Bay',
        'America/Tijuana',
        'America/Toronto',
        'America/Tortola',
        'America/Vancouver',
        'America/Virgin',
        'America/Whitehorse',
        'America/Winnipeg',
        'America/Yakutat',
        'America/Yellowknife',
        'Antarctica/Casey',
        'Antarctica/Davis',
        'Antarctica/DumontDUrville',
        'Antarctica/Macquarie',
        'Antarctica/Mawson',
        'Antarctica/McMurdo',
        'Antarctica/Palmer',
        'Antarctica/Rothera',
        'Antarctica/South_Pole',
        'Antarctica/Syowa',
        'Antarctica/Troll',
        'Antarctica/Vostok',
        'Arctic/Longyearbyen',
        'Asia/Aden',
        'Asia/Almaty',
        'Asia/Amman',
        'Asia/Anadyr',
        'Asia/Aqtau',
        'Asia/Aqtobe',
        'Asia/Ashgabat',
        'Asia/Ashkhabad',
        'Asia/Atyrau',
        'Asia/Baghdad',
        'Asia/Bahrain',
        'Asia/Baku',
        'Asia/Bangkok',
        'Asia/Barnaul',
        'Asia/Beirut',
        'Asia/Bishkek',
        'Asia/Brunei',
        'Asia/Calcutta',
        'Asia/Chita',
        'Asia/Choibalsan',
        'Asia/Chongqing',
        'Asia/Chungking',
        'Asia/Colombo',
        'Asia/Dacca',
        'Asia/Damascus',
        'Asia/Dhaka',
        'Asia/Dili',
        'Asia/Dubai',
        'Asia/Dushanbe',
        'Asia/Famagusta',
        'Asia/Gaza',
        'Asia/Harbin',
        'Asia/Hebron',
        'Asia/Ho_Chi_Minh',
        'Asia/Hong_Kong',
        'Asia/Hovd',
        'Asia/Irkutsk',
        'Asia/Istanbul',
        'Asia/Jakarta',
        'Asia/Jayapura',
        'Asia/Jerusalem',
        'Asia/Kabul',
        'Asia/Kamchatka',
        'Asia/Karachi',
        'Asia/Kashgar',
        'Asia/Kathmandu',
        'Asia/Katmandu',
        'Asia/Khandyga',
        'Asia/Kolkata',
        'Asia/Krasnoyarsk',
        'Asia/Kuala_Lumpur',
        'Asia/Kuching',
        'Asia/Kuwait',
        'Asia/Macao',
        'Asia/Macau',
        'Asia/Magadan',
        'Asia/Makassar',
        'Asia/Manila',
        'Asia/Muscat',
        'Asia/Nicosia',
        'Asia/Novokuznetsk',
        'Asia/Novosibirsk',
        'Asia/Omsk',
        'Asia/Oral',
        'Asia/Phnom_Penh',
        'Asia/Pontianak',
        'Asia/Pyongyang',
        'Asia/Qatar',
        'Asia/Qostanay',
        'Asia/Qyzylorda',
        'Asia/Rangoon',
        'Asia/Riyadh',
        'Asia/Saigon',
        'Asia/Sakhalin',
        'Asia/Samarkand',
        'Asia/Seoul',
        'Asia/Shanghai',
        'Asia/Singapore',
        'Asia/Srednekolymsk',
        'Asia/Taipei',
        'Asia/Tashkent',
        'Asia/Tbilisi',
        'Asia/Tehran',
        'Asia/Tel_Aviv',
        'Asia/Thimbu',
        'Asia/Thimphu',
        'Asia/Tokyo',
        'Asia/Tomsk',
        'Asia/Ujung_Pandang',
        'Asia/Ulaanbaatar',
        'Asia/Ulan_Bator',
        'Asia/Urumqi',
        'Asia/Ust-Nera',
        'Asia/Vientiane',
        'Asia/Vladivostok',
        'Asia/Yakutsk',
        'Asia/Yangon',
        'Asia/Yekaterinburg',
        'Asia/Yerevan',
        'Atlantic/Azores',
        'Atlantic/Bermuda',
        'Atlantic/Canary',
        'Atlantic/Cape_Verde',
        'Atlantic/Faeroe',
        'Atlantic/Faroe',
        'Atlantic/Jan_Mayen',
        'Atlantic/Madeira',
        'Atlantic/Reykjavik',
        'Atlantic/South_Georgia',
        'Atlantic/St_Helena',
        'Atlantic/Stanley',
        'Australia/ACT',
        'Australia/Adelaide',
        'Australia/Brisbane',
        'Australia/Broken_Hill',
        'Australia/Canberra',
        'Australia/Currie',
        'Australia/Darwin',
        'Australia/Eucla',
        'Australia/Hobart',
        'Australia/LHI',
        'Australia/Lindeman',
        'Australia/Lord_Howe',
        'Australia/Melbourne',
        'Australia/NSW',
        'Australia/North',
        'Australia/Perth',
        'Australia/Queensland',
        'Australia/South',
        'Australia/Sydney',
        'Australia/Tasmania',
        'Australia/Victoria',
        'Australia/West',
        'Australia/Yancowinna',
        'Brazil/Acre',
        'Brazil/DeNoronha',
        'Brazil/East',
        'Brazil/West',
        'CET',
        'CST6CDT',
        'Canada/Atlantic',
        'Canada/Central',
        'Canada/Eastern',
        'Canada/Mountain',
        'Canada/Newfoundland',
        'Canada/Pacific',
        'Canada/Saskatchewan',
        'Canada/Yukon',
        'Chile/Continental',
        'Chile/EasterIsland',
        'Cuba',
        'EET',
        'EST',
        'EST5EDT',
        'Egypt',
        'Eire',
        'Etc/GMT',
        'Etc/GMT+0',
        'Etc/GMT+1',
        'Etc/GMT+10',
        'Etc/GMT+11',
        'Etc/GMT+12',
        'Etc/GMT+2',
        'Etc/GMT+3',
        'Etc/GMT+4',
        'Etc/GMT+5',
        'Etc/GMT+6',
        'Etc/GMT+7',
        'Etc/GMT+8',
        'Etc/GMT+9',
        'Etc/GMT-0',
        'Etc/GMT-1',
        'Etc/GMT-10',
        'Etc/GMT-11',
        'Etc/GMT-12',
        'Etc/GMT-13',
        'Etc/GMT-14',
        'Etc/GMT-2',
        'Etc/GMT-3',
        'Etc/GMT-4',
        'Etc/GMT-5',
        'Etc/GMT-6',
        'Etc/GMT-7',
        'Etc/GMT-8',
        'Etc/GMT-9',
        'Etc/GMT0',
        'Etc/Greenwich',
        'Etc/UCT',
        'Etc/UTC',
        'Etc/Universal',
        'Etc/Zulu',
        'Europe/Amsterdam',
        'Europe/Andorra',
        'Europe/Astrakhan',
        'Europe/Athens',
        'Europe/Belfast',
        'Europe/Belgrade',
        'Europe/Berlin',
        'Europe/Bratislava',
        'Europe/Brussels',
        'Europe/Bucharest',
        'Europe/Budapest',
        'Europe/Busingen',
        'Europe/Chisinau',
        'Europe/Copenhagen',
        'Europe/Dublin',
        'Europe/Gibraltar',
        'Europe/Guernsey',
        'Europe/Helsinki',
        'Europe/Isle_of_Man',
        'Europe/Istanbul',
        'Europe/Jersey',
        'Europe/Kaliningrad',
        'Europe/Kiev',
        'Europe/Kirov',
        'Europe/Kyiv',
        'Europe/Lisbon',
        'Europe/Ljubljana',
        'Europe/London',
        'Europe/Luxembourg',
        'Europe/Madrid',
        'Europe/Malta',
        'Europe/Mariehamn',
        'Europe/Minsk',
        'Europe/Monaco',
        'Europe/Moscow',
        'Europe/Nicosia',
        'Europe/Oslo',
        'Europe/Paris',
        'Europe/Podgorica',
        'Europe/Prague',
        'Europe/Riga',
        'Europe/Rome',
        'Europe/Samara',
        'Europe/San_Marino',
        'Europe/Sarajevo',
        'Europe/Saratov',
        'Europe/Simferopol',
        'Europe/Skopje',
        'Europe/Sofia',
        'Europe/Stockholm',
        'Europe/Tallinn',
        'Europe/Tirane',
        'Europe/Tiraspol',
        'Europe/Ulyanovsk',
        'Europe/Uzhgorod',
        'Europe/Vaduz',
        'Europe/Vatican',
        'Europe/Vienna',
        'Europe/Vilnius',
        'Europe/Volgograd',
        'Europe/Warsaw',
        'Europe/Zagreb',
        'Europe/Zaporozhye',
        'Europe/Zurich',
        'Factory',
        'GB',
        'GB-Eire',
        'GMT',
        'GMT+0',
        'GMT-0',
        'GMT0',
        'Greenwich',
        'HST',
        'Hongkong',
        'Iceland',
        'Indian/Antananarivo',
        'Indian/Chagos',
        'Indian/Christmas',
        'Indian/Cocos',
        'Indian/Comoro',
        'Indian/Kerguelen',
        'Indian/Mahe',
        'Indian/Maldives',
        'Indian/Mauritius',
        'Indian/Mayotte',
        'Indian/Reunion',
        'Iran',
        'Israel',
        'Jamaica',
        'Japan',
        'Kwajalein',
        'Libya',
        'MET',
        'MST',
        'MST7MDT',
        'Mexico/BajaNorte',
        'Mexico/BajaSur',
        'Mexico/General',
        'NZ',
        'NZ-CHAT',
        'Navajo',
        'PRC',
        'PST8PDT',
        'Pacific/Apia',
        'Pacific/Auckland',
        'Pacific/Bougainville',
        'Pacific/Chatham',
        'Pacific/Chuuk',
        'Pacific/Easter',
        'Pacific/Efate',
        'Pacific/Enderbury',
        'Pacific/Fakaofo',
        'Pacific/Fiji',
        'Pacific/Funafuti',
        'Pacific/Galapagos',
        'Pacific/Gambier',
        'Pacific/Guadalcanal',
        'Pacific/Guam',
        'Pacific/Honolulu',
        'Pacific/Johnston',
        'Pacific/Kanton',
        'Pacific/Kiritimati',
        'Pacific/Kosrae',
        'Pacific/Kwajalein',
        'Pacific/Majuro',
        'Pacific/Marquesas',
        'Pacific/Midway',
        'Pacific/Nauru',
        'Pacific/Niue',
        'Pacific/Norfolk',
        'Pacific/Noumea',
        'Pacific/Pago_Pago',
        'Pacific/Palau',
        'Pacific/Pitcairn',
        'Pacific/Pohnpei',
        'Pacific/Ponape',
        'Pacific/Port_Moresby',
        'Pacific/Rarotonga',
        'Pacific/Saipan',
        'Pacific/Samoa',
        'Pacific/Tahiti',
        'Pacific/Tarawa',
        'Pacific/Tongatapu',
        'Pacific/Truk',
        'Pacific/Wake',
        'Pacific/Wallis',
        'Pacific/Yap',
        'Poland',
        'Portugal',
        'ROC',
        'ROK',
        'Singapore',
        'Turkey',
        'UCT',
        'US/Alaska',
        'US/Aleutian',
        'US/Arizona',
        'US/Central',
        'US/East-Indiana',
        'US/Eastern',
        'US/Hawaii',
        'US/Indiana-Starke',
        'US/Michigan',
        'US/Mountain',
        'US/Pacific',
        'US/Samoa',
        'UTC',
        'Universal',
        'W-SU',
        'WET',
        'Zulu'
    ],
}

//
//
export const show_debug = function(v){
    if(CONSTANTS['DEBUG_MODE']==true){
        console.log(v)
    }
}

//
//
export const COMPLETE_NAME = function(f,l){
    return f + ' ' + l
}

//
//
export const REDIRECT_TO = function(complete_uri){
    //window.location.replace(DOMAIN + BASE_URL + p)
    location.href = complete_uri
}

//
// this is a work in progress -- not in use anymore
// 
// kwargs=dict
export const PUSH_URL = function(kwargs){

    //
    //
    let _return = ''
    let _c = ''
    let _s = ''

    //
    //
    if('c' in kwargs){

        //
        //
        if(kwargs['c']==1){

            //
            //
            _c = '/c/' + kwargs['url']
        }
    }

    //
    //
    _s = kwargs['section']
    
    //
    //
    switch (_s) {

        case 'about':
            
            break;
    
        default:
            break;
    }


    //
    //
    _return = _c + _s

    //
    //
    return _return

}

//
// not important right now - not in use anymore
export const GET_C_URL = function(user_state){

    //
    //
    if(user_state==1){
        return '/c/'
    }
    else if(user_state==2){
        return '/cl/'
    }
    else if(user_state==3){
        return '/cp/'
    }

}

//
//
export const change_title_tag = function(v){
    document.title = v + ' | ' + CONSTANTS['APP_NAME']
}

//
//
export const GET_RANDOMSTRING = function(length) {
    var result           = ''
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    var charactersLength = characters.length
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
   return result
}

//
//
export const show_datetime = function(dt) {
    return moment(String(dt)).format('MMM DD, YYYY - hh:mm a')
}

//
//
export const show_datetime_ISO = function(dt) {
    return moment(dt).format("MM-DD-YYYY HH:mm a")
}

//
//
export const show_date = function(dt) {
    return moment(String(dt)).format('MMM DD, YYYY')
}

//
//
export const show_date_ISO = function(dt) {
    //return moment(String(dt)).format('MM-DD-YYYY')
    return dt.split('T')[0]
}

//
//
export const show_time = function(dt) {
    return moment(String(dt)).format('hh:mm a')
}

//
//
 export const translate_month = function(v){

    //
    //
    let r = {
        '1': 'January',
        '2': 'February',
        '3': 'March',
        '4': 'April',
        '5': 'May',
        '6': 'June',
        '7': 'July',
        '8': 'August',
        '9': 'September',
        '10': 'October',
        '11': 'November',
        '12': 'December',
        '01': 'January',
        '02': 'February',
        '03': 'March',
        '04': 'April',
        '05': 'May',
        '06': 'June',
        '07': 'July',
        '08': 'August',
        '09': 'September',
    }

    //
    //
    return r[String(v)]
}

//
//
export const IS_EMPTY = function(value){
    if(value == null || value == undefined){
        return true
    }
    else {
        return false
    }
}

//
//
export const RESIZE_PHOTO = function(photo,size){

    if(photo!=null||photo==''||photo=='#'){
        return photo.replace('image/upload/','image/upload/w_' + size + ',h_' + size + '/')
    }
    else {
        return photo
    }
}

//
// this is critical
export const str_json = function(str){
    return JSON.parse(str.replace(/'/g, '"'))
}

//
//
export const is_mobile_browser = function(){

    //
    //
    if(document.documentElement.clientWidth > 992){
        return false
    }
    else {
        return true
    }

}

//
//
export const show_sidebar = function(){

    //
    //
    try {
        document.getElementById("id_sidebar").classList.add("close_icon")
    } catch (error) {
        show_debug(error)
    }

}

//
//
export const hide_sidebar = function(){

    //
    //
    try {
        document.getElementById("id_sidebar").classList.remove("close_icon")
    } catch (error) {
        show_debug(error)
    }

}

// ==================================================================
// random geometric background image generation
// ==================================================================

//
//
var GeoPattern = require('geopattern');
export const get_geopattern = function(){
    return GeoPattern
}

//
//
export const get_pattern_url = function(text){
    let pattern = GeoPattern.generate(text).toDataUrl()
    //return pattern.toDataUrl()
    return pattern
}

//
//
export const CHANGE_BACKGROUND_IMAGE = function(the_id,text) {
    document.getElementById(the_id).style.backgroundImage = 'url(buttons/' + get_pattern_url(text) + '.png)';
}

//
//
export const stripHtml = function(html) {
   let tmp = document.createElement("DIV");
   tmp.innerHTML = html;
   return tmp.textContent || tmp.innerText || "";
}

// ==================================================================
// localstorage, sessionstorage, cookies, etc.
// ==================================================================

/*
* set_cookie('session', value, 30) - to set
* set_cookie('session', value, -1) - to delete
*
*
*/

//
//
export const set_cookie = function(cname, cvalue, expiry_days) {
    const d = new Date();
    d.setTime(d.getTime() + (expiry_days * 24 * 60 * 60 * 1000));
    let expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

//
//
export const get_cookie = function(cname){

    // returns value of cookie['']

    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1)
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length)
        }
    }
    return ""
}

//
//
export const if_cookie_exists = function(cname){

    // returns true or false

    let the_cookie = get_cookie(cname);
    if (the_cookie != "") {
        return true
    } else {
        return false
    }
}

//
//
export const get_key = function(){
    return localStorage.getItem("key")
}

//
//
// export const save_key = function(key){
//     localStorage.setItem('key',key)
//     localStorage.getItem('key')
// }

//
//
export const if_key_exists = function(){

    // returns true or false

    if(localStorage.getItem("key") == null){
        return false
    }
    else {
        return true
    }
}

// ==================================================================
// api keys
// ==================================================================

//
//
export const get_tinymce_api = function(key){

    //
    //
    var r = ''
    console.log('get_tinymce_api')
    //
    //
    redeemer.redeem_api({
        'key': key,
        'type':'GET',
        'url':'/session/tinymce/'
    })
    .then(response => {
        show_debug(response)
        r = response.data
    })
    .catch(error => {
        show_debug(error)
        r = false
    })

    //
    //
    console.log(r)
    return r

}

// ==================================================================
// feature visibility checker
// ==================================================================

//
//
export const page_visibility = function(kwargs){

    //
    //
    let r = false
    let v = kwargs
    let pa = v['page']
    let l = v['level']
    let pr = v['privilege']
    let a = v['action']
    let all_pages = {
        'about': {
            1: true,
            2: true,
            3: true,
        },
        'products-loop': {
            1: true,
            2: true,
            3: 'privilege',
        },
        'products-page': {
            1: true,
            2: true,
            3: 'privilege',
        },
        'products-add': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'products-edit': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'pipelines-loop': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'forms-loop': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'forms-page': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'forms-add': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'forms-edit': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'roles-loop': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'roles-page': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'roles-edit': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'roles-add': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'notebox-loop': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'notebox-add': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'inquiries-loop': {
            1: true,
            2: true,
            3: 'privilege',
        },
        'inquiries-page': {
            1: true,
            2: true,
            3: 'privilege',
        },
        'consultations-loop': {
            1: true,
            2: true,
            3: 'privilege',
        },
        'consultations-page': {
            1: true,
            2: true,
            3: 'privilege',
        },
        'consultations-add': {
            1: true,
            2: true,
            3: 'privilege',
        },
        'consultations-edit': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'assignments-loop': {
            1: true,
            2: true,
            3: 'privilege',
        },
        'assignments-page': {
            1: true,
            2: true,
            3: 'privilege',
        },
        'assignments-add': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'assignments-edit': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'assignments-settings': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'clients-loop': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'clients-page': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'clients-add': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'clients-edit': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'clients-settings': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'reviews-loop': {
            1: true,
            2: true,
            3: 'privilege',
        },
        'reviews-page': {
            1: true,
            2: true,
            3: 'privilege',
        },
        'reviews-add': {
            1: false,
            2: true,
            3: false,
        },
        'reviews-edit': {
            1: false,
            2: true,
            3: false,
        },
        'tickets-loop': {
            1: true,
            2: true,
            3: 'privilege',
        },
        'tickets-page': {
            1: true,
            2: true,
            3: 'privilege',
        },
        'tickets-add': {
            1: false,
            2: true,
            3: false,
        },
        'tickets-edit': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'handbooks-loop': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'handbooks-page': {
            1: true,
            2: true,
            3: 'privilege',
        },
        'handbooks-add': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'handbooks-edit': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'personnel-loop': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'personnel-page': {
            1: true,
            2: true,
            3: 'privilege',
        },
        'personnel-add': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'personnel-edit': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'personnel-settings': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'announcements-loop': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'announcements-page': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'announcements-add': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'announcements-edit': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'leaves-loop': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'leaves-page': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'leaves-add': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'leaves-edit': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'memos-loop': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'memos-page': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'memos-add': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'memos-edit': {
            1: true,
            2: false,
            3: 'privilege',
        },
    }

    //
    //
    if(all_pages[ pa ][ l ] == 'privilege') {
        if( pr[a] == true ) {
            r = true
        }
        else {
            r = false
        }
    }
    else {
        r = all_pages[ pa ][ l ]
    }

    //
    //
    show_debug('can view page: ' + r + ' - ' + pa)

    //
    //
    return r
    
}

//
// all clickable things
export const feature_visibility = function(kwargs){

    //
    //
    let r = false
    let v = kwargs
    let l = v['level']
    let a = v['action']
    let p = v['privilege']
    let all_features = {
        'can_view_company': {
            1: true,
            2: true,
            3: 'privilege',
        },
        'can_view_allrole': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_view_role': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_create_role': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_edit_role': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_delete_role': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_view_allproduct': {
            1: true,
            2: true,
            3: 'privilege',
        },
        'can_view_product': {
            1: true,
            2: true,
            3: 'privilege',
        },
        'can_create_product': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_edit_product': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_delete_product': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_view_allinquiry': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_view_inquiry': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_create_inquiry': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_edit_inquiry': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_delete_inquiry': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_view_allpipeline': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_create_pipeline': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_edit_pipeline': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_delete_pipeline': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_view_allform': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_view_form': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_create_form': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_edit_form': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_delete_form': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_create_notebox': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_edit_notebox': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_delete_notebox': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_view_allconsultation': {
            1: true,
            2: true,
            3: 'privilege',
        },
        'can_create_consultation': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_edit_consultation': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_delete_consultation': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_view_allassignment': {
            1: true,
            2: true,
            3: 'privilege',
        },
        'can_create_assignment': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_edit_assignment': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_delete_assignment': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_view_allclient': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_create_client': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_edit_client': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_delete_client': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_view_allreview': {
            1: true,
            2: true,
            3: 'privilege',
        },
        'can_view_review': {
            1: true,
            2: true,
            3: 'privilege',
        },
        'can_create_review': {
            1: false,
            2: true,
            3: false,
        },
        'can_edit_review': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_delete_review': {
            1: false,
            2: false,
            3: false,
        },
        'can_view_allticket': {
            1: true,
            2: true,
            3: 'privilege',
        },
        'can_view_ticket': {
            1: true,
            2: true,
            3: 'privilege',
        },
        'can_create_ticket': {
            1: false,
            2: true,
            3: false,
        },
        'can_edit_ticket': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_delete_ticket': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_view_allhandbook': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_view_handbook': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_create_handbook': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_edit_handbook': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_delete_handbook': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_view_allpersonnel': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_view_personnel': {
            1: true,
            2: true,
            3: 'privilege',
        },
        'can_create_personnel': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_edit_personnel': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_delete_personnel': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_view_allannouncement': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_create_announcement': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_edit_announcement': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_delete_announcement': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_view_allleave': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_create_leave': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_edit_leave': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_delete_leave': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_view_allmemo': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_create_memo': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_edit_memo': {
            1: true,
            2: false,
            3: 'privilege',
        },
        'can_delete_memo': {
            1: true,
            2: false,
            3: 'privilege',
        },
    }

    //
    //
    if(all_features[ a ][ l ] == 'privilege') {

        //
        //
        if( p[a] == true ) {
            r = true
        }
        else {
            r = false
        }
    }
    else {

        //
        //
        r = all_features[ a ][ l ]
    }

    //
    //
    return r

}

//
//
export const client_visibility = function(level,page_feature){

    //
    //
    if(level == 2){

        //
        //
        let r = false

        //
        //
        let v = [
            'about',
            'products-loop',
            'products-page',
            'consultations-loop',
            'consultations-page',
            'assignments-loop',
            'assignments-page',
            'reviews-loop',
            'reviews-page',
            'reviews-add',
            'tickets-loop',
            'tickets-add',
            'tickets-page',
            'handbooks-page',
            'handbookspage-page',
            'personnel-page'
        ]

        //
        //
        if(page_feature in v){
            r = true
        }

        //
        //
        return r

    }

}

//
//
export const company_visibility = function(level,privilege,privilege_check){

    //
    //
    let r = false

    //
    //
    if(level == 1){
        return true
    }

    //
    //
    if(level == 3){
        
        //
        //
        if(privilege[privilege_check] == true) {
            r = true
        }
    }


    //
    //
    return r

}

//
// list of integrations available at the moment
export const integrations_available = function(){
    
    //
    //
    return [
        {
            'img': '/slack_80x80.png',
            'name': 'Slack',
            'description': 'Streamline team communication. Create channels, share files, and chat in real-time for seamless collaboration.',
            'connected': false,
        },
        {
            'img': '/discord_80x80.png',
            'name': 'Discord',
            'description': 'Unite communities with voice, video, and text chat. Custom servers, gaming integration.',
            'connected': false,
        },
        {
            'img': '/zapier_63x63.png',
            'name': 'Zapier',
            'description': 'Automate tasks effortlessly with integrations across apps. Simplify workflows and boost your productivity.',
            'connected': false,
        },
        {
            'img': '/integromat_make_80x80.png',
            'name': 'Integromat/Make.com',
            'description': 'Streamline workflows with seamless app integrations. Automate tasks for increased efficiency.',
            'connected': false,
        },
        {
            'img': '/hubspot_80x80.png',
            'name': 'Hubspot',
            'description': 'Elevate your marketing, sales, and customer service efforts. Drive growth with powerful CRM tools and analytics.',
            'connected': false,
        },
        {
            'img': '/asana_80x80.png',
            'name': 'Asana',
            'description': 'Simplify project management. Organize tasks, collaborate with teams, and track progress effortlessly.',
            'connected': false,
        },
        {
            'img': '/trello_80x80.png',
            'name': 'Trello',
            'description': 'Visual project management made easy. Organize tasks into boards, collaborate with teams, and stay on track.',
            'connected': false,
        },
        
        {
            'img': '/monday_80x80.png',
            'name': 'Monday.com',
            'description': 'Revolutionize project management. Visualize workflows, collaborate with teams, and track progress in real-time.',
            'connected': false,
        },
        {
            'img': '/notion_80x80.png',
            'name': 'Notion',
            'description': 'Customize workflows, collaborate in real-time, and organize information efficiently.',
            'connected': false,
        },
        {
            'img': '/airtable_80x80.png',
            'name': 'Airtable',
            'description': 'Flexible database management. Organize, collaborate, and customize workflows effortlessly.',
            'connected': false,
        },
        //{
        //    'img': '/zendesk_80x80.png',
        //    'name': 'Zendesk',
        //    'description': 'Elevate customer support. Streamline interactions, manage tickets, and deliver exceptional service seamlessly.',
        //    'connected': false,
        //},
        // {
        //     'img': '/freshdesk_80x80.png',
        //     'name': 'Freshdesk',
        //     'description': 'Streamline customer support. Manage tickets, engage customers, and deliver exceptional service effortlessly.',
        //     'connected': false,
        // },
        {
            'img': '/mailchimp_80x80.png',
            'name': 'Mailchimp',
            'description': 'Simplify email marketing. Design, send, and track campaigns effortlessly for effective audience engagement.',
            'connected': false,
        },
        {
            'img': '/activecampaign_80x80.png',
            'name': 'ActiveCampaign',
            'description': 'Power your marketing automation. Engage customers, segment audiences, and drive conversions with ease.',
            'connected': false,
        },
        {
            'img': '/brevo_80x80.png',
            'name': 'Brevo',
            'description': 'Streamline email and SMS marketing. Engage customers, automate campaigns, and track results effectively.',
            'connected': false,
        },
        // {
        //     'img': '/klaviyo_80x80.png',
        //     'name': 'Klaviyo',
        //     'description': 'Supercharge your email marketing. Personalize campaigns, analyze customer insights, and drive sales.',
        //     'connected': false,
        // },
        // {
        //     'img': '/google_drive_80x80.png',
        //     'name': 'Google Drive',
        //     'description': 'Securely store and share files. Collaborate in real-time, access documents from anywhere.',
        //     'connected': false,
        // },
        // {
        //     'img': '/google_calendar_80x80.png',
        //     'name': 'Google Calendar',
        //     'description': 'Organize your schedule effortlessly. Plan events, set reminders, and share calendars seamlessly with others.',
        //     'connected': false,
        // },
        // {
        //     'img': '/calendly_80x80.png',
        //     'name': 'Calendly',
        //     'description': 'Simplify scheduling. Share availability, book appointments, and streamline meeting coordination effortlessly.',
        //     'connected': false,
        // },
        // {
        //     'img': '/stripe_80x80.png',
        //     'name': 'Stripe',
        //     'description': 'Empower online payments. Seamlessly accept and manage transactions, securely process payments.',
        //     'connected': false,
        // },
        // {
        //     'img': '/paypal_80x80.png',
        //     'name': 'Paypal',
        //     'description': 'Simplify online transactions. Send and receive payments securely, manage invoices, and shop with confidence.',
        //     'connected': false,
        // },
        // {
        //     'img': '/xero_80x80.png',
        //     'name': 'Xero',
        //     'description': 'Revolutionize accounting. Streamline invoicing, track expenses, and manage finances with ease for small businesses.',
        //     'connected': false,
        // },
        //{
        //    'img': '/quickbooks_80x80.png',
        //    'name': 'Quickbooks',
        //    'description': 'Empower small business finance. Streamline accounting, track expenses, and manage invoices seamlessly.',
        //    'connected': false,
        //},
        // {
        //     'img': '/zoom_80x80.png',
        //     'name': 'Zoom',
        //     'description': 'Transform virtual meetings. Host video conferences, collaborate in real-time, and connect with ease from anywhere.',
        //     'connected': false,
        // },
        // {
        //     'img': '/rippling_80x80.png',
        //     'name': 'Rippling',
        //     'description': 'Simplify HR and IT management. Employee onboarding, manage payroll, and streamline IT operations effortlessly.',
        //     'connected': false,
        // },
    ]
    
}

// ==================================================================
// table, kanbban, list view checker
// ==================================================================

//
// not in use
export const get_view = function(key){
    if(key == 'assignments' || key == 'assignment'){
        return localStorage.getItem("av")
    }
    else if(key == 'clients' || key == 'client'){
        return localStorage.getItem("cv")
    }
    else if(key == 'personnels' || key == 'personnel'){
        return localStorage.getItem("pv")
    }
    else if(key == 'leaves' || key == 'leave'){
        return localStorage.getItem("lv")
    }
}

//
// not inuse
export const set_view = function(key,new_value){

    //
    //
    if(key == 'assignments' || key == 'assignment'){
        localStorage.setItem('av',new_value)
        localStorage.getItem('av')
    }
    else if(key == 'clients' || key == 'client'){
        localStorage.setItem('cv',new_value)
        localStorage.getItem('cv')
    }
    else if(key == 'personnels' || key == 'personnel'){
        localStorage.setItem('pv',new_value)
        localStorage.getItem('pv')
    }
    else if(key == 'leaves' || key == 'leave'){
        localStorage.setItem('lv',new_value)
        localStorage.getItem('lv')
    }

}

//
// uses redeemer
export const validate_views = function(slug){

    //
    //
    if(localStorage.getItem("av") == null || 
    localStorage.getItem("cv") == null || 
    localStorage.getItem("pv") == null || 
    localStorage.getItem("lv") == null){
        
        //
        //
        redeemer.redeem_api({
            'key': get_key(),
            'type': 'GET',
            'url': '/company/get-views/' + slug + '/',
        })
        .then(response=>{
            show_debug(response)

            //
            //
            if(response.data.result != 'unknown error occured'){

                //
                //
                set_view('assignment',response.data['av'])
                set_view('client',response.data['cv'])
                set_view('personnel',response.data['pv'])
                set_view('leaves',response.data['lv'])
            }
        })
        .catch(error=>{

            //
            //
            show_debug(error)
            console.log(error)

            //
            //
            set_view('assignment','kanban')
            set_view('client','kanban')
            set_view('personnel','kanban')
            set_view('leaves','table')
        })

        return true

    }
    else {

        return true

    }
}

// ==================================================================
// login checker
// ==================================================================

//
//
export const login_session = function(sessionid,api_key,caucusid){

    //
    //
    show_debug('login_session')

    //
    //
    set_cookie('session',sessionid,90)
    set_cookie('caucus',caucusid,90)

    //
    //
    localStorage.setItem('key',api_key)
    localStorage.getItem('key')

    manage_state('slug','')
    manage_state('a',0)
    encoded_manage_state('p',{'values':'empty'})

}

//
//
export const destroy_auth = function(){

    //
    //
    show_debug('destroy_auth')

    //
    //
    set_cookie('session',false,-1)
    set_cookie('caucus',false,-1)

    //
    //
    sessionStorage.clear()
    localStorage.clear()
}

//
//
export const logout_session = function(uri_query=false) {

    //
    //
    show_debug('logout_session')

    //
    //
    destroy_auth()

    //
    //
    if(uri_query == false){

        //
        //
        REDIRECT_TO('/login/')
    }
    else {

        //
        //
        REDIRECT_TO('/login/?' + uri_query)
    }
}

//
//
export const loggedin_area = function(){

    //
    //
    show_debug('loggedin_area')

    //
    //
    if(if_cookie_exists('session') == false){

        //
        //
        show_debug('cookie does not exist')
        //logout_session()
        return false
    }

    //
    //
    if(if_cookie_exists('caucus') == false || if_cookie_exists('caucus') == 0){
        
        //
        //
        show_debug('cookie2 does not exist')
        //logout_session()
        return false
    }
    else {
        // check database
    }

    //
    //
    if(if_key_exists() == false){

        //
        //
        show_debug('key does not exist')
        get_api_from_db()
        return false
    }
    else {

        //
        //
        show_debug('key exist')
        if_login_matched()
        return true
    }

}

// ==================================================================
// session operators
// ==================================================================

/*
* login_session(get_session(),get_key())
*
*
*
*/

//
//
export const format_cloudflare = function(response) {

    //
    //
    //show_debug('format_cloudflare')

    //
    //
    let datax = response.data.trim().split('\n').reduce(function(obj, pair) {
        pair = pair.split('=');
        return obj[pair[0]] = pair[1], obj;
    }, {})
    show_debug(datax)
    let r = {
        'ipaddress': datax.ip,
        'useragent': datax.uag,
        'country_code': datax.loc,
    }

    return r
}

//
//
export const get_api_from_db = function(){

    //
    //
    show_debug('get_api_from_db')
    let session = get_cookie('session')

    //
    //
    redeemer.redeem_ipaddress()
    .then(response => {
        
        //
        //
        show_debug(response)

        //
        //
        let cf = format_cloudflare(response)

        //
        //
        redeemer.redeem_api({
            'type': 'POST',
            'url': '/session/key/',
            'data': {
                session: session,
                ipaddress: cf['ipaddress'],
                useragent: cf['useragent'],
                country_code: cf['country_code'],
            }
        })
        .then(response => {

            //
            //
            show_debug(response)

            //
            //
            if(response.data == false){

                //
                //
                logout_session()
            }
            else {

                //
                //
                login_session(
                    session,
                    response.data.key
                )
            }
        })
        .catch(error => {

            //
            //
            show_debug(error)
            logout_session('error=invalid-session-apikey')
        })
    })
    .catch(error => {

        //
        //
        //show_debug(error)
        console.log(error)
        logout_session('error=invalid-session-cf')

    })

}

//
//
export const if_login_matched = function(){

    //
    //
    show_debug('if_login_matched()')
    let session = get_cookie('session')
    let _key = get_key()

    //
    //
    redeemer.redeem_ipaddress()
    .then(response => {

        //
        //
        show_debug(response)
        show_debug('data from cloudflare')
        let cf = format_cloudflare(response)

        //
        //
        show_debug('session - ' + session)
        show_debug('ipaddress - ' + cf['ipaddress'])
        show_debug('useragent - ' + cf['useragent'])
        show_debug('country_code - ' + cf['country_code'])
        show_debug('_key - ' + _key)

        //
        //
        redeemer.redeem_api({
            'type': 'POST',
            'url': '/session/verify/',
            'data': {
                session: session,
                ipaddress: cf['ipaddress'],
                useragent: cf['useragent'],
                country_code: cf['country_code'],
                key: _key,
            }
        })
        .then(response => {

            //
            //
            show_debug(response.data)
            show_debug('/session/verify/ - result')

            //
            //
            if(response.data == true){

                //
                //
                if_has_profile(_key)
                show_debug('login matched')
            }
            else {

                //
                //
                //logout_session()
            }
        })
        .catch(error => {

            //
            //
            show_debug(error)
            show_debug('if_login_matched() - error')
            logout_session('error=invalid-session-verification')
        })

    })
    .catch(error => {

        //
        //
        show_debug(error)
        logout_session('error=invalid-session-cf')
    })
}

//
//
export const if_has_profile = function(key){

    //
    //
    show_debug('if_has_profile')

    //
    //
    redeemer.redeem_api({
        'type': 'GET',
        'url': '/session/profile/',
        'param': [
            {
                'key': key,
                'value':'loggedin_area',
            },
        ],
    })
    .then(response => {

        //
        //
        show_debug(response)
        //setup_state()
        // do nothing, since everything is verified at this point
    })
    .catch(error => {

        //
        //
        show_debug(error)
        logout_session('error=invalid-profile-verification')
    })

}

// ==================================================================
// state management
// ==================================================================

//
//
export const retrieve_access = function(slug){

    //
    //
    show_debug('retrieve_access')

    //
    //
    //return redeem_company_access(get_key(),slug)
    return redeemer.redeem_api({
        'type': 'GET',
        'url': '/company/access/' + slug + '/',
    })
    .then(response=>{

        //
        //
        show_debug('retrieve_access')
        show_debug(response)

        //
        // save data to sessionstorage
        manage_state('a',response.data.access)

        //
        //
        return response.data
    })
    .catch(error=>{

        //
        //
        show_debug(error)
    })
    
}

//
// save value to localstorage
export const manage_state = function(k,v){

    //
    //
    window.localStorage.setItem(k, v)
    window.localStorage.getItem(k)
}

//
// save value to localstorage
export const encoded_manage_state = function(k,v){

    //
    //
    //window.localStorage.setItem(k, v)
    // window.localStorage.setItem(k, JSON.stringify(v));
    // //window.localStorage.getItem(k)
    // JSON.parse(window.localStorage.getItem(k))

    //
    //
    try {
        window.localStorage.setItem(k, JSON.stringify(v));
    } catch (error) {
        window.localStorage.setItem(k, {});
    }
    JSON.parse(window.localStorage.getItem(k))


}

//
//
export const verify_state = function(k){

    //
    //
    show_debug('verify_state')

    //
    //
    if( view_state(k)==null || view_state(k)==0 ){
        return false
    }
    else{
        return true
    }
}

//
//
// export const setup_state = function(){

//     //
//     //
//     show_debug('setup_state')
//     manage_state("d", 0) // 0,1 - light/dark mode
//     manage_state("u", 0) // company url
//     manage_state("a", 0) // 0,1,2,3
//     manage_state("r", 0) // role privilege {}
// }

//
//
export const view_state = function(k){

    //
    //
    return window.localStorage.getItem(k)
}

//
//
export const decoded_view_state = function(k){

    //
    //
    //return window.localStorage.getItem(k)
    return JSON.parse(window.localStorage.getItem(k))
}

//
//
export const set_darkmode = function(){

    //
    //
    manage_state('d',1)
}

//
//
export const set_lightmode = function(){

    //
    //
    manage_state('d',0)
}

// ==================================================================
// kanban organizer, drag n drop
// ==================================================================

//
//
export const kanban_organizer = function(section,pipelines,loop_data){

    //
    //
    let board_column = kanban_set_columns(pipelines)

    //
    //
    let board_data = kanban_populate_column(section,board_column,loop_data)

    //
    //
    return board_data

}

//
//
export const kanban_set_columns = function(pipeline_stages){

    //
    //
    let r = []

    //
    //
    for (let i = 0; i < pipeline_stages.length; i++) {

        //
        //
        r.push({
            'id': pipeline_stages[i]['id'],
            'name': pipeline_stages[i]['name'],
            'loop': []
        })
        
    }

    //
    //
    return r

}

//
//
export const kanban_populate_column = function(section,board_columns,loop_data){

    //
    //
    let bc = board_columns
    let ld = loop_data
    let r = board_columns

    //
    //
    for (let i = 0; i < bc.length; i++) {

        //
        //
        for (let i2 = 0; i2 < ld.length; i2++) {
            
            //
            //
            if( bc[i]['id'] == ld[i2]['pipeline__id'] ){

                //
                //
                bc[i]['loop'].push(
                    kanban_card_item(section,ld[i2]) 
                )

            }

        }

    }

    //
    //
    return r

}

//
//
export const kanban_card_item = function(section,_data){

    //
    //
    let r = {}

    //
    //
    if( section == 'assignment' ){
        r = {
            'id': _data['id'],
            'title': _data['title'],
            'subtitle': _data['product__name'],
            'datetime': _data['datetime'],
        }
    }
    else if( section == 'client' ){
        r = {
            'id': _data['id'],
            'title': _data['first_name'] + ' ' + _data['last_name'],
            'subtitle': _data['country'],
            'datetime': _data['datetime'],
        }
    }
    else if( section == 'personnel' ){
        r = {
            'id': _data['id'],
            'title': _data['first_name'] + ' ' + _data['last_name'],
            'subtitle': _data['country'],
            'datetime': _data['datetime'],
        }
    }

    //
    //
    return r

}

//
//
export const kanban_column_add = function(section, board_data, col_index, prev_col_index, dragged_index){

    //
    //
    board_data[col_index]['loop'].unshift( board_data[prev_col_index]['loop'][dragged_index] )

    //
    //
    return board_data

}

//
//
export const kanban_column_remove = function(section, board_data, prev_col_index, dragged_index){
    
    //
    //
    let prev_col = board_data[prev_col_index]
    console.log(prev_col)
    prev_col['loop'].splice(dragged_index,1)
    board_data[prev_col_index] = prev_col

    //
    //
    return board_data

}

//
// evt - $event
export const kanban_drag = function(evt, card_item, card_index, prev_col_id, prev_col_index){

    //
    //
    show_debug('--------- drag ----------')
    show_debug('item id - ' + card_item.id)
    show_debug('item index - ' + card_index) 
    show_debug('prev item id - ' + prev_col_id)
    show_debug('prev item index - ' + prev_col_index)
    show_debug('--------- end drag ----------')

    //
    //
    evt.dataTransfer.dropEffect = 'move'
    evt.dataTransfer.effectAllowed = 'move'
    evt.dataTransfer.setData('dragged_item', card_item)
    evt.dataTransfer.setData('dragged_id', card_item.id)
    evt.dataTransfer.setData('dragged_index', card_index)
    evt.dataTransfer.setData('prev_col_id', prev_col_id)
    evt.dataTransfer.setData('prev_col_index', prev_col_index)

}

//
// evt - $event
export const kanban_drop = function(evt, section, board_data, col_loop, col_id, col_index){

    //
    //
    let dragged_item        = evt.dataTransfer.getData('dragged_item')
    let dragged_id          = evt.dataTransfer.getData('dragged_id')
    let dragged_index       = evt.dataTransfer.getData('dragged_index') // returns "[Object Object]" (str)
    let prev_col_id         = evt.dataTransfer.getData('prev_col_id')
    let prev_col_index      = evt.dataTransfer.getData('prev_col_index')
    //let dragged_item        = this.get_item(dragged_id,prev_board_id)
    //let r                   = board_data

    //
    //
    show_debug('--------- drop ----------')
    show_debug('drop stage id - ' + col_id)
    show_debug('prev board index - ' + prev_col_index)
    show_debug('drop stage index - ' + col_index)
    show_debug('dragged_item - ' + dragged_item)
    show_debug('dragged_index - ' + dragged_index)
    show_debug('--------- end drop ----------')

    //
    //
    if(prev_col_index != col_index){
    
        //
        // add dragged item to new stage
        board_data = kanban_column_add(section, board_data, col_index, prev_col_index, dragged_index)
        
        //
        // remove item to old stage
        board_data = kanban_column_remove(section, board_data, prev_col_index, dragged_index)

    }

    //
    // return dragged_item new value
    return {
        'new_board_data'    : board_data,
        'item_id'           : Number(dragged_id),
        'item_index'        : Number(dragged_index),
        'new_column_id'     : Number(board_data[col_index]['id']),
        'new_column_index'  : Number(col_index),
        'prev_column_id'    : Number(prev_col_id),
        'prev_column_idex'  : Number(prev_col_index),
    }

}

























